import { type Action } from 'redux';
import {
  ActionsObservable,
  combineEpics,
  ofType,
  StateObservable,
} from 'redux-observable';
import { EMPTY, noop, of, Subject, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  delay,
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';

import {
  checkHeartbeatPublic,
  connectPublicSocket,
  connectSelectedProductChannelPublic,
  // disableHeartbeatPublic,
  disconnectPublicSocket,
  enableHeartbeatPublic,
  publicSocketConnected,
} from 'actions/socket';
import { TAB_INACTIVE, TAB_REACTIVE } from 'actionTypes/other';
import {
  CONNECT_PUBLIC_SOCKET,
  DISABLE_HEARTBEAT_PUBLIC,
  DISCONNECT_PUBLIC_SOCKET,
  ENABLE_HEARTBEAT_PUBLIC,
  CHECK_HEARTBEAT_PUBLIC,
  PUBLIC_SOCKET_CONNECTED,
} from 'actionTypes/socket';
import {
  PUBLIC_SOCKET_URL,
  SOCKET_CONNECTION_TIMEOUT,
  SOCKET_RECONNECTION_DELAY,
  TAB_INACTIVE_DELAY,
} from 'constants/constants';
import { PUBLIC_SOCKET_OUTGOING_MESSAGE_TYPES } from 'constants/publicSocket';
import { publicHeartbeatTSSelector } from 'selectors/priceSelectors';
import { publicSocketActiveSelector } from 'selectors/socketSelectors';
import IState from 'types/Istore';
import { updatePublicHeartbeatTS } from 'variableStore/actions';

// import type { SocketMessage } from 'types/publicSocket';

let onOpenSubject = new Subject();
let onCloseSubject = new Subject();

const createWebSocketSubject = () => {
  onOpenSubject = new Subject();
  onCloseSubject = new Subject();
  // this works dont play with this
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  publicWsSubject = webSocket({
    url: PUBLIC_SOCKET_URL,
    openObserver: onOpenSubject,
    closeObserver: onCloseSubject,
  });
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return publicWsSubject;
};

// eslint-disable-next-line prefer-const, import/no-mutable-exports, no-var
export var publicWsSubject = createWebSocketSubject();

/**
 * Connect to public socket on CONNECT_PUBLIC_SOCKET action
 * If the socket is already active, do nothing
 */
const connectToPublicSocketEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(CONNECT_PUBLIC_SOCKET),
    filter(() => {
      const socketActive = publicSocketActiveSelector(state$.value);
      return socketActive === false || socketActive === null;
    }),
    switchMap(() =>
      createWebSocketSubject().pipe(
        map(() => {}),
        catchError(e => {
          console.error('Public Socket Connection Error', e, e.type);
          return EMPTY;
        })
      )
    )
  );

export const publicSocketConnectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(CONNECT_PUBLIC_SOCKET),
    filter(() => {
      const socketActive = publicSocketActiveSelector(state$.value);
      return socketActive === false || socketActive === null;
    }),
    switchMap(() =>
      onOpenSubject.pipe(
        map(() => {
          return { type: PUBLIC_SOCKET_CONNECTED, payload: true };
        })
      )
    ),
    catchError(err => {
      console.error('DEBUG disconnection epic', err);
      return EMPTY;
    })
  );

/**
 * Verify public socket connection after a delay (SOCKET_RECONNECTION_DELAY)
 * If the socket is not active, retry connection, keeps retrying until PUBLIC_SOCKET_CONNECTED
 */
const verifyPublicSocketConnectionEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(CONNECT_PUBLIC_SOCKET),
    switchMap(() => {
      return timer(SOCKET_RECONNECTION_DELAY).pipe(
        map(() => {
          const socketActive = publicSocketActiveSelector(state$.value);
          if (socketActive) {
            return noop;
          }

          return disconnectPublicSocket({ retry: true });
        }),
        takeUntil(action$.ofType(ENABLE_HEARTBEAT_PUBLIC, DISCONNECT_PUBLIC_SOCKET))
      );
    })
  );

/**
 * Once PUBLIC_SOCKET_CONNECTED subscribe to required channels
 */
const initialisePublicSocketSubscriptionEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(PUBLIC_SOCKET_CONNECTED),
    map(() => {
      const socketActive = publicSocketActiveSelector(state$.value);
      return socketActive;
    }),
    distinctUntilChanged(),
    filter(value => value === true),
    mergeMap(() => {
      return of(
        enableHeartbeatPublic(),
        connectSelectedProductChannelPublic(),
        checkHeartbeatPublic()
      );
      // return EMPTY;
    }),
    catchError(err => {
      console.error('DEBUG initialisePublicSocketSubscriptionEpic', err);
      return EMPTY;
    })
  );

const createHeartbeatChannel = () =>
  publicWsSubject.multiplex(
    () => ({
      type: PUBLIC_SOCKET_OUTGOING_MESSAGE_TYPES.ENABLE_HEARTBEAT,
    }),
    () => ({
      type: PUBLIC_SOCKET_OUTGOING_MESSAGE_TYPES.DISABLE_HEARTBEAT,
    }),
    () => true
  );

/**
 * Enables heartbeat
 * If no message is received on heartbeat channel before SOCKET_CONNECTION_TIMEOUT, retry connection
 */
const heartbeatEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(ENABLE_HEARTBEAT_PUBLIC),
    mergeMap(() =>
      createHeartbeatChannel().pipe(
        takeUntil(action$.ofType(DISABLE_HEARTBEAT_PUBLIC, DISCONNECT_PUBLIC_SOCKET)),
        catchError(err => {
          console.error('DEBUG public socket heartbeat epic', err);
          return EMPTY;
        })
      )
    ),
    map(updatePublicHeartbeatTS),
    catchError(error => {
      console.error('Critical error in public heartbeat epic:', error);
      return EMPTY;
    })
  );

// Heartbeat check.
export const publicHeartbeatCheck = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(CHECK_HEARTBEAT_PUBLIC),
    delay(SOCKET_RECONNECTION_DELAY),
    map(() => publicHeartbeatTSSelector()),
    map((hb: number) => {
      return new Date().getTime() - hb <= SOCKET_CONNECTION_TIMEOUT;
    }),
    mergeMap(isOnTime => {
      if (!isOnTime && publicSocketActiveSelector(state$.value)) {
        return of(publicSocketConnected(false), disconnectPublicSocket({ retry: true }));
      }
      return of(checkHeartbeatPublic());
    })
  );

const closePublicSocketConnectionEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DISCONNECT_PUBLIC_SOCKET),
    mergeMap(action => {
      onCloseSubject.complete();
      publicWsSubject.complete();
      if (action.payload.retry === true) {
        return of(publicSocketConnected(false), connectPublicSocket());
      }
      return of(publicSocketConnected(false));
    }),
    catchError(err => {
      console.error('DEBUG close socket connection', err);
      return EMPTY;
    })
  );

const disconnectPublicSocketOnTabInactive = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(TAB_INACTIVE),
    switchMap(() =>
      timer(TAB_INACTIVE_DELAY).pipe(
        map(() => {
          return disconnectPublicSocket({ retry: false });
        }),
        takeUntil(action$.ofType(DISCONNECT_PUBLIC_SOCKET, TAB_REACTIVE))
      )
    )
  );

const reconnectPublicSocketOnTabReactive = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<IState>
) =>
  action$.pipe(
    ofType(TAB_REACTIVE),
    map(() => publicSocketActiveSelector(state$.value)),
    filter(val => {
      return val === false || val === null;
    }),
    mergeMap(() => of(connectPublicSocket()))
  );

export default combineEpics(
  initialisePublicSocketSubscriptionEpic,
  connectToPublicSocketEpic,
  publicSocketConnectedEpic,
  verifyPublicSocketConnectionEpic,
  heartbeatEpic,
  closePublicSocketConnectionEpic,
  disconnectPublicSocketOnTabInactive,
  reconnectPublicSocketOnTabReactive,
  publicHeartbeatCheck
);
