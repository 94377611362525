/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { captureMessage } from '@sentry/browser';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';

import { postMessageToMobileApp } from 'components/app/helpers';
import { IS_INDIAN_EXCHANGE, IS_RN_WEBVIEW, isProd } from 'constants/constants';
import { TIME_IN_MS } from 'constants/dateTime';
import { ENABLE_MIXPANEL_SESSION_REPLAY } from 'constants/featureFlag';
import { userSelector } from 'selectors/otherSelectors';
import StoreProxy from 'storeProxy';

let mixpanel = null;

/**
 * @description DONT CHANGE THIS VALUE
 * Reason: We trigger recording sessions based on our requirement.
 * 0 is used when we trigger the sessions manually
 */
const SESSIONS_REPLAY_RECORD_PERCENTAGE = 0;

export const MIXPANEL_SESSION_REPLAY_CLASS = 'mixpanel-session-replay-mask';

const getDeviceType = () => {
  if (isMobile) {
    return 'Mobile';
  }
  if (isTablet || isIPad13) {
    return 'Tab';
  }
  return 'Desktop';
};

const shouldUserBeTracked = () => {
  const doNotTrackCampaigns = {
    ev_dbo_500: true,
  };

  if (window && window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    const currentCampaignURL = searchParams.get('utm_campaign');

    if (currentCampaignURL in doNotTrackCampaigns) {
      mixpanel.opt_out_tracking({
        delete_user: true,
      });
    }
  }
};

const loadMixpanel = async () => {
  if (mixpanel) return mixpanel;

  const mixpanelModule = await import('mixpanel-browser');
  mixpanel = mixpanelModule.default;

  let sessionReplayProperties = {};
  if (ENABLE_MIXPANEL_SESSION_REPLAY) {
    sessionReplayProperties = {
      record_sessions_percent: SESSIONS_REPLAY_RECORD_PERCENTAGE,
      record_mask_text_class: MIXPANEL_SESSION_REPLAY_CLASS,
      record_mask_text_selector: '',
      record_max_ms: 10 * TIME_IN_MS.ONE_MINUTE,
    };
  }

  mixpanel.init(process.env.REACT_APP_MIX_PANEL_ID, {
    debug: !isProd,
    ignore_dnt: true,
    loaded: () => {
      shouldUserBeTracked();
    },
    ...sessionReplayProperties,
  });

  return mixpanel;
};

export const mixpanelStartRecording = () => {
  if (!ENABLE_MIXPANEL_SESSION_REPLAY) return;
  mixpanel?.start_session_recording();
};

export const mixpanelStopRecording = () => {
  if (!ENABLE_MIXPANEL_SESSION_REPLAY) return;
  mixpanel?.stop_session_recording();
};

export const getURLQueryParams = () => {
  const urlQuery = window.location.search;
  const utmParams = {};
  const queryParams = urlQuery ? urlQuery.substring(1).split('&') : [];

  queryParams.forEach(item => {
    item = item.split('=');
    utmParams[item[0]] = item[1];
  });
  return utmParams;
};

export const identifyUser = async user_id => {
  const mp = await loadMixpanel();
  if (mp.has_opted_out_tracking()) {
    mp.opt_in_tracking();
  }

  postMessageToMobileApp('APPSFLYER_SET_CUSTOMER_USER_ID', {
    id: user_id,
  });

  mp.identify(user_id);
};

export const mixPanelReset = async () => {
  try {
    const mp = await loadMixpanel();
    mp.reset();
  } catch (error) {
    captureMessage('Mixpanel reset failed');
  }
};

export const getDistinctId = async () => {
  const mp = await loadMixpanel();
  return mp.get_distinct_id();
};

/**
 * @deprecated Use mixPanelReset directly instead
 */
export const reset = mixPanelReset;

export const setUserProperty = async userProps => {
  const mp = await loadMixpanel();
  mp.people.set(userProps);
};

export const trackMixpanelEvent = async (title, params = {}) => {
  const mp = await loadMixpanel();
  const user = StoreProxy.selector(userSelector);
  let isUserLoggedIn = false;
  let userProperties = {};

  if (user?.id) {
    isUserLoggedIn = true;
    userProperties = {
      user_id: user.id,
      email: user.email,
      country: user.country,
    };
  }
  const finalParams = {
    is_mobile_app: Boolean(IS_RN_WEBVIEW),
    is_loggedin: isUserLoggedIn,
    project: IS_INDIAN_EXCHANGE ? 'Delta Exchange India' : 'Delta Exchange Global',
    ...userProperties, // This spreads the user properties if the user is logged in.
  };
  // Efficiently adding params to finalParams
  Object.keys(params).forEach(key => {
    finalParams[key] = params[key];
  });
  setTimeout(() => {
    mp.track(title, finalParams);
  }, 0);
};

export const trackMixpanelEventPageView = async params => {
  const mp = await loadMixpanel();
  const urlParams = getURLQueryParams();
  mp.track('Page Viewed', {
    ...params,
    utm_campaign: urlParams.utm_campaign,
    utm_source: urlParams.utm_source,
    device: getDeviceType(),
  });
};

export const refreshCacheAndReload = async calledFrom => {
  await trackMixpanelEvent('Delete cache and reload', {});
  console.info('DEBUG refreshCacheAndReload utils', calledFrom);
  try {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
      console.info('Cache cleared', calledFrom);
    }
  } catch (e) {
    console.error('Failed to clear cache', calledFrom, e);
    await trackMixpanelEvent('Delete cache and reload', {});
  } finally {
    window.location.href = window.location.href.replace(/#.*$/, '');
  }
};
