/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^draft"] }] */
import { produce } from 'immer';

import ACCOUNT_ACTION_TYPES, { DepositsActionTypeIndia } from 'actionTypes/account';
import { API_STATUS } from 'constants/enums';
import type { UpiFlowState } from 'types/upiFlow';

const INITIAL_STATE: UpiFlowState = {
  upiListApiStatus: API_STATUS.INITIAL,
  upiIdList: [],
  selectedUpiId: null,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.GET_WHITELISTED_UPI_IDS].LOAD: {
      draft.upiListApiStatus = API_STATUS.LOADING;
      break;
    }
    case ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.GET_WHITELISTED_UPI_IDS].SUCCESS: {
      draft.upiListApiStatus = API_STATUS.SUCCESS;
      const list = action.result.result.map(
        ({
          partner_status: partnerStatus,
          upi_id: upiId,
          last_used_for_payment: lastUsedForPayment,
        }) => ({ partnerStatus, upiId, lastUsedForPayment })
      );
      draft.upiIdList = list;
      draft.selectedUpiId =
        list.find(({ lastUsedForPayment }) => lastUsedForPayment)?.upiId ||
        list.find(({ partnerStatus }) => partnerStatus === 'completed')?.upiId ||
        null;
      break;
    }
    case ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.GET_WHITELISTED_UPI_IDS].FAIL: {
      draft.upiListApiStatus = API_STATUS.ERROR;
      break;
    }
    case ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.SET_ACTIVE_UPI_ID]: {
      draft.selectedUpiId = action.payload;
      break;
    }
    default:
      break;
  }
}, INITIAL_STATE);

export default reducer;
