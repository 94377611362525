/* eslint-disable camelcase */
import { ContractType, Product } from 'types/IProducts';

import {
  Payload,
  SIDE,
  calcPrecision,
  convertExponentialToDecimal,
  getOverlayPopoverCalculation,
  roundByPrecision,
  roundPriceLevel,
} from './utils';

interface ComputeOrderbookInterface {
  book: Array<[string, number, number]>;
  selectedPriceClubbingValue: number;
  side: string;
  hideDepth: boolean;
  selectedProduct: Product;
  symbol: string;
}

const calcBookWithDepth = (sortedBook, totalDepth, selectedProduct, symbol) => {
  const bookWithDepth: Required<Payload>[] = [];
  sortedBook.forEach((order, idx) => {
    const depth = (bookWithDepth[idx - 1]?.depth || 0) + order.size;
    const { average, underlyingNotionalSum, settlingNotionalSum, quotingNotionalSum } =
      getOverlayPopoverCalculation(sortedBook.slice(0, idx + 1), selectedProduct)();
    const obj = {
      price: convertExponentialToDecimal(order.price),
      size: order.size,
      depth,
      depthPercentage: (depth / totalDepth) * 100,
      average,
      underlyingNotionalSum,
      settlingNotionalSum,
      quotingNotionalSum,
      symbol,
    };
    bookWithDepth.push(obj);
  });

  return bookWithDepth.sort((a, b) => {
    return Number(b.price) - Number(a.price);
  });
};

const computeOrderbook = ({
  book = [],
  selectedPriceClubbingValue,
  side,
  hideDepth,
  selectedProduct,
  symbol,
}: ComputeOrderbookInterface) => {
  const tickSize = selectedProduct?.tick_size;
  const precision = calcPrecision(tickSize);
  const computedBookObj = {};
  let totalDepth = 0;

  book.forEach(([limit_price, size]) => {
    const price = parseFloat(limit_price);
    const clubbedPrice = Number(
      roundByPrecision(precision)(
        roundPriceLevel(
          Number(price),
          Number(convertExponentialToDecimal(selectedPriceClubbingValue)),
          side,
          Number(tickSize)
        )
      )
    );

    let clubbedSize = Number(size);
    if (computedBookObj[clubbedPrice]) {
      clubbedSize += Number(computedBookObj[clubbedPrice]?.size);
    }

    computedBookObj[clubbedPrice as number] = {
      price: clubbedPrice,
      size: clubbedSize,
    };
    totalDepth += Number(size);
  });

  const sortedBook = Object.values(computedBookObj).sort((a, b) => {
    return side === SIDE.SELL
      ? Number(a?.price) - Number(b?.price)
      : Number(b?.price) - Number(a?.price);
  });

  if (hideDepth) {
    return sortedBook;
  }

  return calcBookWithDepth(sortedBook, totalDepth, selectedProduct, symbol);
};

export const getComputedOrderbookFromSocket = (
  message: {
    a: [string, number, number][];
    b: [string, number, number][];
    i: number;
    lts: number;
    pts: number;
    s: string;
    type: string;
    d: string;
    pd: string;
    error?: boolean;
  },
  state$
) => {
  const selectedProduct = state$.value?.trade?.selected_product;
  const selectedPriceClubbingValue =
    state$.value?.l2Orderbook?.selectedPriceClubbingValue;
  const contractType = state$?.value?.trade?.selectedContractType;
  const hideDepth =
    contractType === ContractType.CallOptions || contractType === ContractType.PutOptions;

  const buy = computeOrderbook({
    book: message.b,
    selectedPriceClubbingValue,
    side: SIDE.BUY,
    hideDepth,
    selectedProduct,
    symbol: message.s,
  });

  const sell = computeOrderbook({
    book: message.a,
    selectedPriceClubbingValue,
    side: SIDE.SELL,
    hideDepth,
    selectedProduct,
    symbol: message.s,
  });

  return {
    buy,
    sell,
    product_id: message?.i,
    type: message?.type,
    symbol: message?.s,
    last_sequence_no: message?.lts,
    // last_updated_at: message?.last_updated_at,
    // timestamp: message?.timestamp,
    error: message?.error,
    bestBid: message?.b?.[0]?.[0],
    bestAsk: message?.a?.[0]?.[0],
    bestBidSize: message?.b?.[0]?.[1],
    bestAskSize: message?.a?.[0]?.[1],
    spread: message?.d,
    percentage_spread: message?.pd,
  };
};

export default computeOrderbook;
