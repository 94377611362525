import { type MouseEvent, useState } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';

import { postMessageToMobileApp } from 'components/app/helpers';
import InfoBox from 'components/Auth/Common/InfoBox';
import Render from 'components/render';
import Seo from 'components/seo';
import {
  APP_STORE_URL,
  DELTA_INDIA_APP_PLAY_STORE_URL,
  DELTA_INDIA_LANDING_SITE_ROOT,
  IS_INDIAN_EXCHANGE,
  IS_RN_WEBVIEW,
  IS_RN_WEBVIEW_ANDROID,
  PLAY_STORE_URL,
  IND_DOMAIN_SWITCH,
  DELTA_EXCHANGE_GLOBAL_URL,
} from 'constants/constants';
import { POST_MESSAGE_TO_MOBILE_EVENTS } from 'constants/enums';
import {
  RECOVER_USER_ACCOUNT_FEATURE_ENABLED,
  SHOW_DELTA_INDIA_LOGIN_LINK,
  SHOW_DELTA_GLOBAL_LOGIN_LINK_ON_LOGIN_PAGE,
  SHOW_DOWNLOAD_APP_ICON,
} from 'constants/featureFlag';
import { MIXPANEL_EVENT_NAMES, MIXPANEL_SOURCES } from 'constants/mixpanelEventNames';
import { FEATURE_FLAG_HYPERVERGE_CUSTOM_INPUT_SUPPORTED } from 'constants/rnApp';
import routes from 'constants/routes';
import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { device } from 'helpers/screenSizes';
import { getMobileOperatingSystem, noop } from 'helpers/utils';
import i18n from 'i18n/config';
import {
  AppleIcon,
  InfoFilled,
  InfoOutlinedIcon,
  PlayStoreIcon,
  DeltaLogoMobile,
  CircularExclamationWarning,
} from 'styles/assets/icons';
import DeltaIndiaLogoMobile from 'styles/assets/icons/DeltaIndiaLogoMobile';
import NewRedirectIcon from 'styles/assets/icons/RedirectIcon';
import DepositReward from 'styles/assets/images/deposit_reward.png';
import Box from 'UIKit/Box';
import DeltaButton from 'UIKit/DeltaButton';
import MaterialDialog from 'UIKit/MaterialDialog';

import styles from './styles.module.scss';

const textReactivate = i18n.t('auth:clickHere').toLocaleLowerCase();
const textAccountDeleted = i18n.t('auth:accountDeleted');

interface DeletedAccountExistsMessageProps {
  userId: number;
  email: string;
  testId?: string;
}

const InfoBoxes = () => {
  const { t } = useTranslation(['auth', 'common']);

  return (
    (<InfoBox
      header={t('auth:loginInfoBoxes.depositReward.header')}
      description={t('auth:loginInfoBoxes.depositReward.description')}
      image={
        <img
          src={DepositReward}
          className={styles.giftBoxImg}
          alt="depositReward"
          data-palette="InfoBoxes" />
      }
    />)
  );
};

const LoginSEO = () => {
  const { t } = useTranslation();

  let title = `${t('auth:logIn')} | ${
    IS_INDIAN_EXCHANGE ? t('common:titleIndian') : t('common:title')
  }`;

  let description = IS_INDIAN_EXCHANGE ? t('auth:seoLoginIndian') : t('auth:seoLogin');

  if (IND_DOMAIN_SWITCH) {
    title = `${t('auth:login')} | ${
      IS_INDIAN_EXCHANGE ? t('common:newTitleIndian') : t('common:newTitle')
    }`;
    description = IS_INDIAN_EXCHANGE
      ? t('auth:newSeoLoginIndian')
      : (t('auth:newSeoLogin') as string);
  }

  return <Seo title={title} description={description} />;
};

const ForgotPasswordLink = () => {
  const { t } = useTranslation(['auth']);
  const onClickForgotPassword = () => {
    trackMixpanelEvent('Forgot Password', {
      page_name: 'Sign-in',
    });
  };

  return (
    <Link
      to={routes.forgotPassword}
      className={styles.link}
      onClick={onClickForgotPassword}
      data-testID="forgotPasswordPageLink"
      replace
    >
      {t('auth:forgotPassword') as string}
    </Link>
  );
};

const LinksSection = () => {
  const { t } = useTranslation(['auth']);

  return (
    (<div className={styles.linksSection} data-palette="LinksSection">
      <ForgotPasswordLink />
      <Link
        to={routes.disable2fa}
        className={styles.link}
        data-testID="disable2faPageLink"
      >
        {t('auth:lost2faToken') as string}
      </Link>
    </div>)
  );
};

const SignUpMessage = () => {
  const { t } = useTranslation();

  const onClick = () => {
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.CLICK_HERE.SIGN_UP_CLICKED, {
      page_name: 'Sign-in',
    });
  };
  return (
    (<div className={styles.signupMessage} data-palette="SignUpMessage">
      {t('auth:noAccount')}{' '}
      <Link
        to={routes.signup}
        className={styles.link}
        onClick={onClick}
        data-testID="signupPageLink"
      >
        {t('auth:signUp') as string}
      </Link>
    </div>)
  );
};

const DownloadAppMessage = () => {
  const { t } = useTranslation();

  return (
    <Render when={SHOW_DOWNLOAD_APP_ICON}>
      <div className={styles.downloadApp}>
        {t('account:security.downloadApp')}
        <a
          href={APP_STORE_URL}
          target="_blank"
          rel="noreferrer"
          data-testID="downloadAppStoreLink"
          className={styles.downloadAppIcon}
        >
          <AppleIcon width={24} height={24} viewBox="0 0 20 25" />
        </a>
        <a
          href={PLAY_STORE_URL}
          target="_blank"
          rel="noreferrer"
          data-testID="downloadPlayStoreLink"
          className={styles.downloadAppIcon}
        >
          <PlayStoreIcon width={24} height={24} viewBox="0 0 20 25" />
        </a>
      </div>
    </Render>
  );
};

const OAuthUnavailableTip = () => {
  const { t } = useTranslation(['auth']);
  return (
    (<div className={styles.iosOAuthTip} data-palette="OAuthUnavailableTip">
      <div>
        <InfoOutlinedIcon />
      </div>
      <div>{t('auth:oAuthUnavailableTip')}</div>
    </div>)
  );
};

const PasswordNotSetMessage = () => {
  const onClickPasswordNotSetMessage = () => {
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SET_OR_CHANGE_PASSWORD, {
      Action: 'set',
      page_name: 'Web App',
      Operating_system: getMobileOperatingSystem(),
    });
  };
  return (
    (<div className={styles.errorMessage} data-palette="PasswordNotSetMessage">
      <Trans
        i18nKey="errors:custom.password_not_set"
        components={{
          clickableContent: (
            <Link
              className={styles.link}
              to={routes.forgotPassword}
              data-testid="click-to-set-password"
              onClick={onClickPasswordNotSetMessage}
            />
          ),
        }}
      />
    </div>)
  );
};

const UserNotFoundMessage = () => {
  return (
    (<div className={styles.oAuthErrorMessage} data-palette="UserNotFoundMessage">
      <Trans i18nKey="errors:custom.oauth_user_not_found">
        Account does not exist. Please
        <Link className={styles.link} to={routes.signup}>
          sign up
        </Link>
        to create an account.
      </Trans>
    </div>)
  );
};

type ComplianceMessageProps = { handleModal: (value: boolean) => void };
const ComplianceMessage = ({ handleModal }: ComplianceMessageProps) => {
  return (
    (<div className={styles.complianceContainer} data-palette="ComplianceMessage">
      <span
        className={styles.complianceMessage}
        data-testid="complianceMessage"
        role="alert"
      >
        <Trans
          i18nKey="auth:complianceMessage.short"
          components={{
            clickableContent: (
              <span
                data-testid="31bRule"
                onClick={handleModal}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
                aria-label="Click to open 31b rule modal"
                className={styles.complianceMessageClickableContent}
                data-palette="ComplianceMessage" />
            ),
          }}
        />
      </span>
    </div>)
  );
};

const getAuthTranslation = i18n.getFixedT(null, 'auth');
const lookingToLoginText = getAuthTranslation('lookingToLogin');
const deltaExchangeIndiaText = `${getAuthTranslation('deltaExchangeIndia')} ${
  IS_RN_WEBVIEW_ANDROID ? getAuthTranslation('app') : getAuthTranslation('site')
}`;
const clickHereText = getAuthTranslation('clickHere');
const deltaIndiaLoginLink = `${DELTA_INDIA_LANDING_SITE_ROOT}/app/login`;
const deltaGlobalLoginLink = `${DELTA_EXCHANGE_GLOBAL_URL}/app/login`;
const deltaIndiaLink = IS_RN_WEBVIEW_ANDROID
  ? DELTA_INDIA_APP_PLAY_STORE_URL
  : deltaIndiaLoginLink;

const DeltaIndiaLoginLinkMessage = () => {
  const onDeltaIndiaLoginLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (IS_RN_WEBVIEW) {
      e.preventDefault();
      postMessageToMobileApp(
        POST_MESSAGE_TO_MOBILE_EVENTS.OPEN_LINK_IN_EXTERNAL_BROWSER,
        {
          url: deltaIndiaLink,
        }
      );
    }

    trackMixpanelEvent(
      MIXPANEL_EVENT_NAMES.LOGIN.DELTA_INDIA_LOGIN_LINK_CLICKED_ON_GLOBAL,
      {
        type: 'static link',
      }
    );
  };

  return (
    (<p
      className={styles.deltaIndiaLinkMessage}
      data-palette="DeltaIndiaLoginLinkMessage">
      {lookingToLoginText}
      <DeltaIndiaLogoMobile className={styles.deltaLogo} />
      {deltaExchangeIndiaText}?<DeltaButton
        href={deltaIndiaLink}
        openLinkInNewTab
        variant="link-t1"
        data-testid="delta-india-login-link"
        wrapperClassName={styles.deltaIndiaLinkContent}
        onClick={onDeltaIndiaLoginLinkClick}
      >
        {clickHereText}
        <NewRedirectIcon className={styles.redirectIcon} />
      </DeltaButton>
    </p>)
  );
};

const userNotFoundText = i18n.t('errors:custom.user_not_found');
const meantToLoginText = i18n.t('auth:meantToLogin');
const deltaIndiaText = i18n.t('auth:deltaIndia');
const deltaExchangeGlobalText = i18n.t('auth:deltaExchangeGlobal');
const deltaGlobalMeantToLoginText = i18n.t('auth:deltaGlobalMeantToLogin');
const signUpText = i18n.t('auth:signUp');
const orText = i18n.t('auth:or');

const UserNotFoundSignupLinkMessage = () => {
  const [isModalOpen, setIsOpenModal] = useState(true);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const onDeltaIndiaLoginLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (IS_RN_WEBVIEW) {
      e.preventDefault();
      postMessageToMobileApp(
        POST_MESSAGE_TO_MOBILE_EVENTS.OPEN_LINK_IN_EXTERNAL_BROWSER,
        {
          url: deltaIndiaLink,
        }
      );
    }

    trackMixpanelEvent(
      MIXPANEL_EVENT_NAMES.LOGIN.DELTA_INDIA_LOGIN_LINK_CLICKED_ON_GLOBAL,
      {
        type: 'user not found link',
      }
    );
  };

  return (
    (<div
      data-testid="user-not-found-message"
      className={styles.userNotFoundMessage}
      data-palette="UserNotFoundSignupLinkMessage">
      <Box textColor="negative">
        <Trans
          i18nKey="errors:custom.user_not_found_create_account"
          components={{
            clickableContent: (
              <Link
                data-testid="error-message-signup-link"
                className={styles.link}
                to={routes.signup}
              />
            ),
          }}
        />
      </Box>
      <Render when={SHOW_DELTA_INDIA_LOGIN_LINK}>
        <MediaQuery query={device.up.md}>
          <p className={styles.userNotFoundSuggestionMessage}>
            <DeltaButton
              to={routes.signup}
              variant="link-t1"
              data-testid="delta-india-signup-link"
              LinkComponent={Link}
            >
              {signUpText}
            </DeltaButton>

            <div className={styles.orText}>{orText}</div>

            {meantToLoginText}
            <Box flex="flex" alignItems="center" gap={6}>
              <DeltaIndiaLogoMobile className={styles.deltaLogo} />
              {deltaIndiaText}?
            </Box>
            <DeltaButton
              href={deltaIndiaLink}
              openLinkInNewTab
              variant="link-t1"
              data-testid="delta-india-login-link"
              wrapperClassName={styles.deltaIndiaLinkContent}
              onClick={onDeltaIndiaLoginLinkClick}
            >
              {clickHereText}
              <NewRedirectIcon className={styles.redirectIcon} />
            </DeltaButton>
          </p>
        </MediaQuery>

        <MediaQuery query={device.down.md}>
          <MaterialDialog
            open={isModalOpen}
            onClose={closeModal}
            contentClassName={styles.modalContent}
          >
            <div className={styles.modalBody}>
              <MaterialDialog.CloseButton
                closeModal={closeModal}
                testId="user-not-found-modal-close-button"
              />
              <Box className={styles.iconContainer} autoMargin="both">
                <InfoFilled className={styles.infoIcon} />
              </Box>
              <Box textColor="primary">{userNotFoundText}</Box>
              <DeltaButton
                to={routes.signup}
                data-testid="delta-india-signup-link"
                LinkComponent={Link}
                className={styles.signupButton}
                fullWidth
              >
                {signUpText}
              </DeltaButton>
              <Box textColor="secondary" className={styles.orText}>
                {orText}
              </Box>
              <Box
                flex="flex"
                gap={6}
                textColor="secondary"
                className={styles.meantToLoginMessage}
              >
                {meantToLoginText}
                <Box flex="flex" alignItems="center" gap={6}>
                  <DeltaIndiaLogoMobile className={styles.deltaLogo} />
                  {deltaIndiaText}?
                </Box>
              </Box>
              <DeltaButton
                href={deltaIndiaLink}
                openLinkInNewTab
                variant="secondary-t1"
                data-testid="delta-india-login-link"
                fullWidth
                className={styles.clickHereButton}
                onClick={onDeltaIndiaLoginLinkClick}
              >
                <Box flex="flex" justifyContent="center" alignItems="center" gap={6}>
                  {clickHereText}
                  <NewRedirectIcon className={styles.redirectIcon} />
                </Box>
              </DeltaButton>
            </div>
          </MaterialDialog>
        </MediaQuery>
      </Render>
    </div>)
  );
};

const UserPresentOnGlobalExchangeMessage = () => {
  const [isModalOpen, setIsOpenModal] = useState(true);

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const onGlobalSignUpClick = () =>
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.GLOBAL_USER_SIGN_UP_CLICKED);

  const onGlobalClickHereClick = () =>
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.GLOBAL_USER_CLICK_HERE_CLICKED);

  return (
    <Box className={styles.userNotFoundMessage}>
      <Box textColor="negative">{userNotFoundText}</Box>
      <Render when={SHOW_DELTA_GLOBAL_LOGIN_LINK_ON_LOGIN_PAGE}>
        <MediaQuery query={device.up.md}>
          <Box as="p" className={styles.userPresentInGlobal}>
            <DeltaButton
              to={routes.signup}
              variant="link-t1"
              data-testid="delta-global-signup-link"
              LinkComponent={Link}
              onClick={onGlobalSignUpClick}
            >
              {signUpText}
            </DeltaButton>

            <Box className={styles.orText}>{orText}</Box>

            {deltaGlobalMeantToLoginText}
            <Box flex="flex" alignItems="center" gap={6}>
              <DeltaLogoMobile width={18} height={18} />
              {deltaExchangeGlobalText}?
            </Box>
            <DeltaButton
              href={deltaGlobalLoginLink}
              openLinkInNewTab
              variant="link-t1"
              data-testid="delta-global-login-link"
              wrapperClassName={styles.deltaIndiaLinkContent}
              onClick={onGlobalClickHereClick}
            >
              {clickHereText}
              <NewRedirectIcon className={styles.redirectIcon} />
            </DeltaButton>
          </Box>
        </MediaQuery>
        <MediaQuery query={device.down.md}>
          <MaterialDialog
            open={isModalOpen}
            onClose={closeModal}
            contentClassName={styles.modalContent}
          >
            <Box className={styles.modalBody}>
              <MaterialDialog.CloseButton
                closeModal={closeModal}
                testId="user-not-found-modal-close-button"
              />
              <Box className={styles.iconContainer} autoMargin="both">
                <InfoFilled className={styles.infoIcon} />
              </Box>
              <Box textColor="primary">{userNotFoundText}</Box>
              <DeltaButton
                to={routes.signup}
                data-testid="delta-global-signup-link"
                LinkComponent={Link}
                className={styles.signupButton}
                fullWidth
                onClick={onGlobalSignUpClick}
              >
                {signUpText}
              </DeltaButton>
              <Box textColor="secondary" className={styles.orText}>
                {orText}
              </Box>
              <Box
                flex="flex"
                gap={6}
                textColor="secondary"
                className={styles.meantToLoginMessage}
              >
                {deltaGlobalMeantToLoginText}
                <Box flex="flex" alignItems="center" gap={6}>
                  <DeltaLogoMobile className={styles.deltaLogo} />
                  {deltaExchangeGlobalText}?
                </Box>
              </Box>
              <DeltaButton
                href={deltaGlobalLoginLink}
                openLinkInNewTab
                variant="secondary-t1"
                data-testid="delta-global-login-link"
                fullWidth
                className={styles.clickHereButton}
                onClick={onGlobalClickHereClick}
              >
                <Box flex="flex" justifyContent="center" alignItems="center" gap={6}>
                  {clickHereText}
                  <NewRedirectIcon className={styles.redirectIcon} />
                </Box>
              </DeltaButton>
            </Box>
          </MaterialDialog>
        </MediaQuery>
      </Render>
    </Box>
  );
};

const DeletedAccountExistsMessage = ({
  userId,
  email,
  testId = 'recover-delete-account-message',
}: DeletedAccountExistsMessageProps) => {
  const showRecoverAccountMessage = IS_RN_WEBVIEW
    ? FEATURE_FLAG_HYPERVERGE_CUSTOM_INPUT_SUPPORTED &&
      RECOVER_USER_ACCOUNT_FEATURE_ENABLED
    : RECOVER_USER_ACCOUNT_FEATURE_ENABLED;

  return (
    <Render
      when={showRecoverAccountMessage}
      else={
        <Box data-testid={`${testId}-container`} fontSize="sm2" textColor="negative">
          {i18n.t<string>(`errors:user.deleted_account_exists`)}
        </Box>
      }
    >
      <Box data-testid={`${testId}-container`} fontSize="sm2" textColor="negative">
        {textAccountDeleted}
        <Link
          data-testID="recoverDeleteAccountLink"
          className={styles.link}
          to={`${routes.recoverDeleteAccount}?e=${encodeURIComponent(
            btoa(email)
          )}&user_id=${userId}`}
        >
          {textReactivate}
        </Link>
      </Box>
    </Render>
  );
};

const DeltaGlobalLoginLinkMessage = () => {
  const onClickDeltaGlobalLoginLink = () => {
    trackMixpanelEvent(MIXPANEL_EVENT_NAMES.DELTA_GLOBAL_LINK_CLICKED, {
      source: MIXPANEL_SOURCES.LOGIN_PAGE,
      site_demarkation: 'webapp',
    });
  };
  return (
    <Box className={styles.deltaGlobalLoginLinkMessage}>
      <Box className={styles.deltaGlobalLoginLinkText}>
        {i18n.t('auth:lookingToLogin') as string}
        <Box as="span" flex="flex" gap={6} alignItems="center">
          <DeltaLogoMobile width={18} height={18} />
          {i18n.t('auth:deltaExchangeGlobalSite') as string}
        </Box>
        <DeltaButton
          href={deltaGlobalLoginLink}
          openLinkInNewTab
          variant="link-t1"
          className={styles.link}
          data-testid="delta-global-login-link"
          onClick={onClickDeltaGlobalLoginLink}
        >
          <Box flex="flex" alignItems="center" gap={6}>
            {clickHereText}
            <NewRedirectIcon className={styles.redirectIcon} />
          </Box>
        </DeltaButton>
      </Box>
    </Box>
  );
};

const TestNetWarningMessage = () => {
  return (
    (<div className={styles.testNetWarning} data-palette="TestNetWarningMessage">
      <CircularExclamationWarning className={styles.warningIcon} />
      <span>{i18n.t('auth:testNetWarning') as string}</span>
    </div>)
  );
};

export {
  OAuthUnavailableTip,
  DownloadAppMessage,
  SignUpMessage,
  LinksSection,
  ForgotPasswordLink,
  LoginSEO,
  InfoBoxes,
  PasswordNotSetMessage,
  UserNotFoundMessage,
  ComplianceMessage,
  DeltaIndiaLoginLinkMessage,
  UserNotFoundSignupLinkMessage,
  DeletedAccountExistsMessage,
  DeltaGlobalLoginLinkMessage,
  UserPresentOnGlobalExchangeMessage,
  TestNetWarningMessage,
};
