import {
  error as errorNotifications,
  success as successNotifications,
} from 'react-notification-system-redux';
import { combineEpics, ofType } from 'redux-observable';
import { REHYDRATE } from 'redux-persist';
import { EMPTY, from, NEVER, timer } from 'rxjs';
import {
  catchError,
  // distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { l2PriceClubbingUpdate } from 'actions/l2Orderbook';
import { getOrderLeverage } from 'actions/orderbook';
import {
  get24HrTicker,
  getOptionsInfo,
  // updateOrderbook,
  updateProduct,
} from 'actions/trade';
import { isAuthenticated, refreshToken } from 'actions/user';
import { LoginViaBiometricsActionTypes, LoginViaQrActionTypes } from 'actionTypes/auth';
import { CONNECT_SELECTED_PRODUCT_CHANNEL_PUBLIC } from 'actionTypes/socket';
import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
import USER, {
  NATIVE_USER_DATA,
  SWITCH_TO_MAIN_ACCOUNT,
  UNAUTHORIZED,
} from 'actionTypes/user';
import { postMessageToMobileApp } from 'components/app/helpers';
import maxNotionalIntervalEpic from 'components/placeorder_v2/LeverageDropdown/maxNotional.epic';
import { IS_INDIAN_EXCHANGE } from 'constants/constants';
import { TIME_IN_MS } from 'constants/dateTime';
import { POST_MESSAGE_TO_MOBILE_EVENTS, STORAGE_KEYS } from 'constants/enums';
import { getGoChartingToken } from 'helpers/createJwt';
import { fromUnixTimestamp, getDiff, timeNow } from 'helpers/day';
import { identifyUser, mixPanelReset } from 'helpers/mixpanel-init';
import MoengageLib, { MoengageDestroySession, MoengageLogin } from 'helpers/moengage';
import { safeJsonParse } from 'helpers/parser';
import { findIndex, isNil, propEq, filter as Rfilter } from 'helpers/ramda';
import { goChartingUserDataObj, noop, refreshPage } from 'helpers/utils';
import { isNotEmpty, isTruthy } from 'ramdax';
import { basketOrderSwitchSelector } from 'selectors/basketOrdersSelectors';
import { allOpenPositionsSelector } from 'selectors/holdingsSelectors';
import {
  publicSocketActiveSelector,
  socketActiveSelector,
} from 'selectors/socketSelectors';
import {
  previousSelectedProductSelector,
  selectedProductSelector,
  selectedProductState,
} from 'selectors/tradeSelectors';
import { ContractType } from 'types/IProducts';
// import { getDecoratedMarketProducts } from 'decorators/marketProducts';
// import { addMarketProducts } from 'actions/markets';
import { setRecentTradeLoader } from 'variableStore/actions';

import {
  subscribeFunding,
  subscribeL2Orderbook,
  subscribeRecentTrade,
  subscribeSelectedProductMarkData,
  subscribeSelecteProductSpot,
  subscribeSpot,
  unsubscribeFunding,
  unsubscribeL2Orderbook,
  unsubscribeMark,
  unsubscribeObRt,
  unsubscribeSelecteProductSpot,
} from '../actions/socket';
import localCache from '../helpers/localCache';
import accountEpic from './account';
import alertsEpic from './alerts';
import analyticsEpic from './analytics';
import authEpic from './auth';
import cancellationsEpic from './cancellations';
import { initialAllTickersEpic, initialBoot } from './init';
import { l2OrderbookEpic } from './l2Orderbook';
import locationEpic from './location';
import { calculateMarginForNonPortfolioEpic } from './MaginValue';
import {
  bestBuyBestBidEpic,
  optionChainChartNavigationEpic,
  subscribeOpenPositionMarkEpic,
  subscribeOptionsChainMarkChannelEpic,
  subscribeSelectedMarkEpic,
  unsubscribeMarkEpic,
} from './mark';
import placeOrderTypeEpic from './placeOrderType';
import publicSocketEpic from './publicSocket';
import socketEpic, { enabledWalletSpotSymbols } from './socket';
import spotEpic from './spot';
import tickerEpic from './ticker';
// eslint-disable-next-line import/no-named-as-default
import tradeEpic from './trade';

// DO NOT RESOLVE THIS IMPORT, IT BREAKS THE BUILD
// eslint-disable-next-line import/order
import { setUserCountryCookie } from 'components/Auth/Login/helpers';

// Commenting this as the epic logs didn't work as expected and replaced with the logs at the component level
// import apiBlockedLogsEpic from './apiBlockedLogs';

// import { newSortedProductsList } from 'components/Header/partials/headerDropdown/helper';

// import { subscribeSelectedProductSpotChannelEpic } from './spot';
// import { socketActiveSelector } from 'selectors/socketSelectors';

// import { TICKER_REFRESH_INTERVAL } from 'constants/constants';

// side-effect action
const deleteNoticeboard = action$ =>
  action$
    .ofType(USER.LOGIN.SUCCESS, USER.LOGOUT.SUCCESS, UNAUTHORIZED)
    .pipe(mapTo(() => localCache.delete('noticeboard')));

const selectedProductEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRADE_CONSTANTS.PRODUCT_SELECTED),
    filter(() => {
      const socketActive = socketActiveSelector(state$.value);
      return socketActive;
    }),
    map(() => {
      const selectedProduct = selectedProductState(state$.value);
      return selectedProduct;
    }),
    filter(isTruthy),
    // distinctUntilChanged(),
    mergeMap(product => {
      const { symbol, contract_type: contractType, id } = product;

      const actions = [
        unsubscribeObRt(),
        // updateOrderbook(),
        setRecentTradeLoader(),
        l2PriceClubbingUpdate({
          selectedPriceClubbingValue: product?.ui_config?.price_clubbing_values?.[0],
          selectedPriceClubbingValueIndex: 0,
        }),
        // subscribeL2Orderbook(symbol),
        subscribeRecentTrade(symbol),
        get24HrTicker(symbol),
      ];

      // if (isAuthenticated(state$.value.user)) {
      //   // basket order check while subscribing orderbook .
      //   const isBasketView = basketOrderSwitchSelector(state$.value);
      //   switch (contractType) {
      //     case ContractType.PerpetualFutures:
      //     case ContractType.Futures:
      //     case ContractType.MoveOptions:
      //       actions.push(subscribeL2Orderbook(symbol));
      //       break;
      //     case ContractType.CallOptions:
      //     case ContractType.PutOptions: {
      //       const mediaQueryList = window.matchMedia(`(max-width: 1024px)`);
      //       if (mediaQueryList.matches) {
      //         if (!isBasketView) {
      //           // no need to check for basket order view in mobile
      //           actions.push(subscribeL2Orderbook(symbol));
      //         }
      //       } else {
      //         actions.push(subscribeL2Orderbook(symbol));
      //       }
      //       break;
      //     }
      //     default:
      //       actions.push(subscribeL2Orderbook(symbol));
      //       break;
      //   }
      // } else {
      //   actions.push(subscribeL2Orderbook(symbol));
      // }

      if (contractType === ContractType.InterestRateSwaps) {
        actions.push(subscribeSpot());
      }

      const previousSelectedProduct = previousSelectedProductSelector(state$.value);

      const enabledSpotWalletIndices = enabledWalletSpotSymbols();

      const allPositions = allOpenPositionsSelector(state$.value);
      const hasPosition = allPositions.reduce(
        (acc, position) =>
          position.product.symbol === previousSelectedProduct.symbol
            ? acc || true
            : acc || false,
        false
      );

      if (
        previousSelectedProduct?.spot_index?.symbol &&
        !enabledSpotWalletIndices.includes(previousSelectedProduct?.spot_index?.symbol) &&
        !hasPosition
      ) {
        actions.push(
          unsubscribeSelecteProductSpot(previousSelectedProduct?.spot_index?.symbol)
        );
      }
      if (isNotEmpty(previousSelectedProduct) && !hasPosition) {
        actions.push(unsubscribeMark(previousSelectedProduct.symbol));
      }

      if (contractType !== 'spot') {
        actions.push(
          unsubscribeFunding(),
          subscribeFunding(symbol),
          getOrderLeverage(id),
          subscribeSelectedProductMarkData(symbol),
          subscribeSelecteProductSpot(product.spot_index.symbol)
        );
      }

      return from([...actions]).pipe(
        // eslint-disable-next-line no-console
        catchError(err => console.error('DEBUG:\n', err))
      );
    })
  );

const productSelectedEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRADE_CONSTANTS.PRODUCT_SELECTED, CONNECT_SELECTED_PRODUCT_CHANNEL_PUBLIC),
    filter(() => {
      const socketActive = publicSocketActiveSelector(state$.value);
      return socketActive;
    }),
    map(() => {
      const selectedProduct = selectedProductState(state$.value);
      return selectedProduct;
    }),
    filter(isTruthy),
    mergeMap(product => {
      const { symbol, contract_type: contractType } = product;
      const actions = [unsubscribeL2Orderbook()];
      if (isAuthenticated(state$.value.user)) {
        // basket order check while subscribing orderbook .
        const isBasketView = basketOrderSwitchSelector(state$.value);
        switch (contractType) {
          case ContractType.PerpetualFutures:
          case ContractType.Futures:
          case ContractType.MoveOptions:
            actions.push(subscribeL2Orderbook(symbol));
            break;
          case ContractType.CallOptions:
          case ContractType.PutOptions: {
            const mediaQueryList = window.matchMedia(`(max-width: 1024px)`);
            if (mediaQueryList.matches) {
              if (!isBasketView) {
                // no need to check for basket order view in mobile
                actions.push(subscribeL2Orderbook(symbol));
              }
            } else {
              actions.push(subscribeL2Orderbook(symbol));
            }
            break;
          }
          default:
            actions.push(subscribeL2Orderbook(symbol));
            break;
        }
      } else {
        actions.push(subscribeL2Orderbook(symbol));
      }
      return from([...actions]).pipe(catchError(err => console.error('DEBUG:\n', err)));
    })
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const positionBracketOrderEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRADE.POSITION_BRACKET_ORDER_MESSAGE),
    map(action => {
      return successNotifications({
        title: action.data.title,
        message: action.data.message,
        position: 'bl',
      });
    })
  );

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const positionBracketOrderErrorEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRADE.POSITION_BRACKET_ORDER_ERROR_MESSAGE),
    map(action => {
      return errorNotifications({
        title: action.data.title,
        message: action.data.message,
        position: 'bl',
      });
    })
  );

const selectedProductExpiryCheckEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRADE.PRODUCTS.SUCCESS),
    map(action => {
      const { result } = action.result;
      const selectedProduct = selectedProductSelector(state$.value);

      if (!isNil(selectedProduct)) {
        const selectedContractType = selectedProduct.contract_type;
        const selectedProductIndex = findIndex(propEq('id', selectedProduct.id))(
          Rfilter(
            ({ contract_type: contractType }) => contractType === selectedContractType,
            result
          )
        );

        if (selectedProductIndex === -1) {
          return updateProduct({ ...selectedProduct, state: 'expired' });
        }
        return () => {};
      }
      return () => {};
    })
  );

const refreshCurrentAccTokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      LoginViaQrActionTypes.SET_AUTHORIZED_USER,
      LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER,
      USER.LOGIN.SUCCESS,
      USER.LOGINMFA.SUCCESS,
      USER.LOGIN_EMAIL_VERIFICATION.SUCCESS,
      REHYDRATE,
      USER.PHONE_OTP_VERIFICATION.SUCCESS,
      NATIVE_USER_DATA
    ),
    filter(() => isAuthenticated(state$.value.user)),
    switchMap(action => {
      const biometricsLoginMetadata = safeJsonParse(
        localStorage.getItem(STORAGE_KEYS.BIOMETRICS_LOGIN_METADATA)
      );

      if (biometricsLoginMetadata?.id && biometricsLoginMetadata?.isSubAccount) {
        return EMPTY;
      }

      const isSafeSession = state$.value.user.preferences.safe_session;

      let refreshInterval = isSafeSession ? 60000 : 3600000; // in milliseconds
      let refreshThreshold = isSafeSession ? 5 : 23 * 60; // in minutes

      if (action.type === LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER) {
        refreshInterval = TIME_IN_MS.ONE_MINUTE * 5;
        refreshThreshold = 5;
      }

      return timer(0, refreshInterval).pipe(
        map(() => {
          if (action.type === 'persist/REHYDRATE' && action.fromSync) {
            return () => {};
          }

          const tokenExpiryTimeInSeconds = state$.value.user.expires_at;
          const minutesUntilTokenExpiry = getDiff(
            fromUnixTimestamp(tokenExpiryTimeInSeconds),
            timeNow(),
            'minutes'
          );

          return minutesUntilTokenExpiry < refreshThreshold
            ? refreshToken(false)
            : () => {};
        }),
        takeUntil(action$.ofType(USER.LOGOUT.SUCCESS))
      );
    })
  );

const refreshMainAccTokenEpic = (action$, state$) =>
  action$.pipe(
    ofType(USER.GET_ACCOUNT_TOKEN.SUCCESS, REHYDRATE),
    filter(() => isTruthy(state$.value.user.main_account?.token)),
    switchMap(action => {
      const biometricsLoginMetadata = safeJsonParse(
        localStorage.getItem(STORAGE_KEYS.BIOMETRICS_LOGIN_METADATA)
      );

      if (biometricsLoginMetadata?.id && biometricsLoginMetadata?.isSubAccount) {
        return EMPTY;
      }

      const isSafeSession = state$.value.user.main_account.preferences.safe_session;
      const refreshInterval = isSafeSession ? 60000 : 3600000; // in milliseconds
      const refreshThreshold = isSafeSession ? 5 : 23 * 60;

      return timer(0, refreshInterval).pipe(
        filter(() => isTruthy(state$.value.user.main_account?.expires_at)),
        map(() => {
          if (action.type === 'persist/REHYDRATE' && action.fromSync) {
            return () => {};
          }

          const tokenExpiryTimeInSeconds = state$.value.user.main_account.expires_at;
          const minutesUntilTokenExpiry = getDiff(
            fromUnixTimestamp(tokenExpiryTimeInSeconds),
            timeNow(),
            'minutes'
          );

          return minutesUntilTokenExpiry < refreshThreshold
            ? refreshToken(true)
            : () => {};
        }),
        takeUntil(action$.ofType(USER.LOGOUT.SUCCESS, SWITCH_TO_MAIN_ACCOUNT))
      );
    })
  );

const postLoginCrossAppTokenHandlingEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      LoginViaQrActionTypes.SET_AUTHORIZED_USER,
      LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER,
      USER.LOGIN_EMAIL_VERIFICATION.SUCCESS,
      USER.LOGINMFA.SUCCESS,
      USER.REFRESH_TOKEN.SUCCESS,
      USER.GET_ACCOUNT_TOKEN.SUCCESS,
      SWITCH_TO_MAIN_ACCOUNT,
      USER.LOGIN.SUCCESS,
      USER.PHONE_OTP_VERIFICATION.SUCCESS,
      NATIVE_USER_DATA
    ),
    filter(() => isAuthenticated(state$.value.user)),
    mergeMap(action => {
      try {
        setUserCountryCookie(state$.value.user.country);
        const data = goChartingUserDataObj(state$.value.user);
        const goChartingToken = getGoChartingToken(data);

        localStorage.setItem('GochartingAuth:id_token', goChartingToken);
      } catch (error) {}

      // ! WARNING: Do not change anything past this line.
      // ! Below code is tied with mobile app biometrics. Please consult with @rahul-thampi
      const biometricsLoginMetadata = safeJsonParse(
        localStorage.getItem(STORAGE_KEYS.BIOMETRICS_LOGIN_METADATA)
      );
      const isPageReloaded = safeJsonParse(
        localStorage.getItem(STORAGE_KEYS.PAGE_RELOADED)
      );

      if (!IS_INDIAN_EXCHANGE && biometricsLoginMetadata) {
        if (isPageReloaded) {
          return EMPTY;
        }

        if (biometricsLoginMetadata?.id !== state$.value.user?.id) {
          return EMPTY;
        }

        if (
          action.type === USER.GET_ACCOUNT_TOKEN.SUCCESS &&
          biometricsLoginMetadata?.isSubAccount
        ) {
          // * INFO: Had to refresh the page with a timeout.
          // * Instant refresh sets the user from sub-account to main-account
          setTimeout(() => {
            refreshPage();
          }, 100);

          return EMPTY;
        }
      }
      postMessageToMobileApp(POST_MESSAGE_TO_MOBILE_EVENTS.USER_AUTHORIZED, {
        user: state$.value.user,
      });

      return EMPTY;
    })
  );

const logoutSuccessEpic = action$ =>
  action$.ofType(USER.LOGOUT.SUCCESS, UNAUTHORIZED).pipe(
    mapTo(() => {
      localStorage.removeItem('GochartingAuth:id_token');
      MoengageDestroySession();
      mixPanelReset();
      postMessageToMobileApp('LOGOUT');
      localStorage.removeItem(STORAGE_KEYS.IDFY_BLOCKED_TIMESTAMP_FOR_USER);
      localStorage.removeItem(STORAGE_KEYS.TRADE_SUCCESS_SHARE_CARD_DATA_LOADED);
      localStorage.removeItem(STORAGE_KEYS.KYC_SHARE_CONSENT_POPUP_SEEN);
      localStorage.removeItem(STORAGE_KEYS.ACCOUNT_CREATED_MODAL_COUNT);
      localStorage.removeItem(STORAGE_KEYS.KYC_REFRESH_DEADLINE_STRIP_CLOSE_TIME);
    })
  );

// currently used for moengage and mixpanel
const postLoginUserAnalyticsEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      LoginViaQrActionTypes.SET_AUTHORIZED_USER,
      LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER,
      USER.LOGIN_EMAIL_VERIFICATION.SUCCESS,
      USER.LOGINMFA.SUCCESS,
      USER.REFRESH_TOKEN.SUCCESS,
      USER.GET_ACCOUNT_TOKEN.SUCCESS,
      SWITCH_TO_MAIN_ACCOUNT,
      NATIVE_USER_DATA
    ),
    filter(() => isAuthenticated(state$.value.user)),
    tap(action => {
      if (action.type === LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER) {
        noop();
      } else if (action.type === NATIVE_USER_DATA) {
        requestAnimationFrame(() => {
          MoengageLib.addUniqueUserId();
          identifyUser(action.payload.id);
        });
      } else if (action.type === USER.LOGIN_EMAIL_VERIFICATION.SUCCESS) {
        const { result: response = {} } = action;
        if (response.result?.new_registration) {
          //  signup case . handled in signup
          noop();
        } else {
          MoengageLogin(state$.value.user);
        }
      } else {
        MoengageLogin(state$.value.user);
      }
    }),
    switchMap(() => NEVER)
  );

const refreshOptionsInfoEpic = action$ =>
  action$.pipe(
    ofType(TRADE_CONSTANTS.REFRESH_OPTIONS_INFO),
    take(1),
    switchMap(() => {
      return timer(TIME_IN_MS.ONE_SECOND * 5).pipe(
        map(() => getOptionsInfo()),
        takeUntil(action$.ofType(TRADE.GET_OPTIONS_INFO.SUCCESS))
      );
    })
  );

export default combineEpics(
  alertsEpic,
  cancellationsEpic,
  deleteNoticeboard,
  initialAllTickersEpic,
  initialBoot,
  locationEpic,
  analyticsEpic,
  selectedProductEpic,
  productSelectedEpic,
  tickerEpic,
  spotEpic,
  refreshCurrentAccTokenEpic,
  refreshMainAccTokenEpic,
  postLoginCrossAppTokenHandlingEpic,
  logoutSuccessEpic,
  socketEpic,
  publicSocketEpic,
  tradeEpic,
  subscribeSelectedMarkEpic,
  subscribeOptionsChainMarkChannelEpic,
  bestBuyBestBidEpic,
  positionBracketOrderEpic,
  positionBracketOrderErrorEpic,
  optionChainChartNavigationEpic,
  subscribeOpenPositionMarkEpic,
  unsubscribeMarkEpic,
  selectedProductExpiryCheckEpic,
  authEpic,
  accountEpic,
  calculateMarginForNonPortfolioEpic,
  postLoginUserAnalyticsEpic,
  maxNotionalIntervalEpic,
  refreshOptionsInfoEpic,
  l2OrderbookEpic,
  placeOrderTypeEpic
);
