interface CountryCodeType {
  [key: string]: string;
}

interface Country {
  label?: string;
  value?: string;
}

export type CountryListType = Country[];

export const countryCode: CountryCodeType = {
  Afghanistan: '+93',
  Albania: '+355',
  Algeria: '+213',
  'American Samoa': '+1-684',
  Andorra: '+376',
  Angola: '+244',
  Anguilla: '+1 264',
  Antarctica: '+672',
  'Antigua and Barbuda': '+1268',
  Argentina: '+54',
  Armenia: '+374',
  Aruba: '+297',
  Australia: '+61',
  Austria: '+43',
  Azerbaijan: '+994',
  Bahamas: '+1 242',
  Bahrain: '+973',
  Bangladesh: '+880',
  Barbados: '+809',
  Belarus: '+375',
  Belgium: '+32',
  Belize: '+501',
  Benin: '+229',
  Bermuda: '+1 441',
  Bhutan: '+975',
  Bolivia: '+591',
  'Bosnia and Herzegowina': '+387',
  Botswana: '+267',
  Brazil: '+55',
  Brunei: '+673',
  Bulgaria: '+359',
  'Burkina Faso': '+226',
  Burundi: '+257',
  Cambodia: '+855',
  Cameroon: '+237',
  'Canada (except Ontario)': '+1',
  'Cape Verde': '+238',
  'Cayman Islands': '+ 345',
  'Central African Republic': '+236',
  Chad: '+235',
  Chile: '+56',
  China: '+86',
  'Cocos Islands': '+61',
  Colombia: '+57',
  Comoros: '+269',
  Congo: '+242',
  'Cook Islands': '+682',
  'Costa Rica': '+506',
  "Cota D'Ivoire": '+225',
  Croatia: '+385',
  Cuba: '+53',
  Cyprus: '+357',
  'Czech Republic': '+420',
  Denmark: '+45',
  Djibouti: '+253',
  Dominica: '+1 767',
  'Dominican Republic': '+1 849',
  'East Timor': '+670',
  Ecuador: '+593',
  Egypt: '+20',
  'El Salvador': '+503',
  'Equatorial Guinea': '+240',
  Eritrea: '+291',
  Estonia: '+372',
  Ethiopia: '+251',
  'Falkland Islands': '+500',
  'Faroe Islands': '+298',
  Fiji: '+679',
  Finland: '+358',
  France: '+33',
  'French Polynesia': '+689',
  Gabon: '+241',
  Gambia: '+220',
  Georgia: '+995',
  Germany: '+49',
  Ghana: '+233',
  Gibraltar: '+350',
  Greece: '+30',
  Greenland: '+299',
  Grenada: '+1 473',
  Guam: '+671',
  Guatemala: '+502',
  Guinea: '+224',
  'Guinea-Bissau': '+225',
  Guyana: '+592',
  Haiti: '+509',
  Honduras: '+504',
  'Hong Kong': '+852',
  Hungary: '+36',
  Iceland: '+354',
  India: '+91',
  Indonesia: '+62',
  Iran: '+98',
  Iraq: '+964',
  Ireland: '+353',
  Israel: '+972',
  Italy: '+39',
  Jamaica: '+1 876',
  Japan: '+81',
  Jordan: '+962',
  Kazakhstan: '+7',
  Kenya: '+254',
  Kiribati: '686',
  "Democratic People's Republic of Korea": '+850',
  'South Korea': '+82',
  Kuwait: '+965',
  Kyrgyzstan: '+996',
  Laos: '+856',
  Latvia: '+371',
  Lebanon: '+961',
  Lesotho: '+266',
  Liberia: '+231',
  Libya: '+218',
  Liechtenstein: '+423',
  Lithuania: '+370',
  Luxembourg: '+352',
  Macedonia: '+389',
  Madagascar: '+261',
  Malawi: '+265',
  Malaysia: '+60',
  Maldives: '+960',
  Mali: '+223',
  Malta: '+356',
  'Marshall Islands': '+692',
  Martinique: '+596',
  Mauritania: '+222',
  Mauritius: '+230',
  Mexico: '+52',
  Micronesia: '+691',
  Moldova: '+373',
  Monaco: '+377',
  Mongolia: '+976',
  Montenegro: '+382',
  Montserrat: '+1664',
  Morocco: '+212',
  Mozambique: '+258',
  Myanmar: '+95',
  Namibia: '+264',
  Nauru: '+674',
  Nepal: '+977',
  Netherlands: '+31',
  'Netherlands Antilles': '+599',
  'New Zealand': '+64',
  Nicaragua: '+505',
  Niger: '+227',
  Nigeria: '+234',
  Niue: '+683',
  'Northern Mariana Islands': '+1 670',
  Norway: '+47',
  Oman: '+968',
  Pakistan: '+92',
  Palau: '+680',
  Panama: '+507',
  'Papua New Guinea': '+675',
  Paraguay: '+595',
  Peru: '+51',
  Philippines: '+63',
  Pitcairn: '+872',
  Poland: '+48',
  Portugal: '+351',
  'Puerto Rico': '+1 939',
  Qatar: '+974',
  Reunion: '+262',
  Romania: '+40',
  Russia: '+7',
  Rwanda: '+250',
  'Saint Kitts and Nevis': '+1 869',
  'Saint LUCIA': '+1 758',
  'Saint Vincent and the Grenadines': '+1 784',
  Samoa: '+685',
  'San Marino': '+378',
  'Sao Tome and Principe': '+239',
  'Saudi Arabia': '+966',
  Serbia: '+381',
  Senegal: '+221',
  Seychelles: '+248',
  Sierra: '+232',
  Singapore: '+65',
  Slovakia: '+421',
  Slovenia: '+386',
  'Solomon Islands': '+677',
  Somalia: '+252',
  'South Africa': '+27',
  'South Georgia': '+500',
  Spain: '+34',
  SriLanka: '+94',
  'St. Helena': '+290',
  Sudan: '+249',
  Suriname: '+597',
  Svalbard: '+47',
  Swaziland: '+268',
  Sweden: '+46',
  Switzerland: '+41',
  Syria: '+963',
  Taiwan: '+886',
  Tajikistan: '+992',
  Tanzania: '+255',
  Thailand: '+66',
  Togo: '+228',
  Tokelau: '+690',
  Tonga: '+676',
  'Trinidad and Tobago': '+1 868',
  Tunisia: '+216',
  Turkey: '+90',
  Turkmenistan: '+993',
  'Turks and Caicos': '+1 649',
  Tuvalu: '+688',
  Uganda: '+256',
  Ukraine: '+380',
  'United Arab Emirates': '+971',
  'United Kingdom': '+44',
  'United States': '+1',
  'United States Minor Outlying Islands': '+1-808',
  Uruguay: '+598',
  Uzbekistan: '+7',
  Vanuatu: '+678',
  Venezuela: '+58',
  Vietnam: '+84',
  'Virgin Islands (British)': '+1 284',
  'Virgin Islands (U.S)': '+1 340',
  'Wallis and Futana Islands': '+681',
  Yemen: '+967',
  Yugoslavia: '+38',
  Zambia: '+260',
  Zimbabwe: '+263',
};

export const dominicanRepublicCountryCodes: string[] = ['+1 809', '+1 829', '+1 849'];

export const countryList: CountryListType = [
  { label: 'Afghanistan' },
  { label: 'Albania' },
  { label: 'Algeria' },
  { label: 'American Samoa' },
  { label: 'Andorra' },
  { label: 'Angola' },
  { label: 'Anguilla' },
  { label: 'Antarctica' },
  { label: 'Antigua and Barbuda' },
  { label: 'Argentina' },
  { label: 'Armenia' },
  { label: 'Aruba' },
  { label: 'Australia' },
  { label: 'Austria' },
  { label: 'Azerbaijan' },
  { label: 'Bahamas' },
  { label: 'Bahrain' },
  { label: 'Bangladesh' },
  { label: 'Barbados' },
  { label: 'Belarus' },
  { label: 'Belgium' },
  { label: 'Belize' },
  { label: 'Benin' },
  { label: 'Bermuda' },
  { label: 'Bhutan' },
  { label: 'Bolivia' },
  { label: 'Bosnia and Herzegowina' },
  { label: 'Botswana' },
  { label: 'Brazil' },
  { label: 'Brunei' },
  { label: 'Bulgaria' },
  { label: 'Burkina Faso' },
  { label: 'Burundi' },
  { label: 'Cambodia' },
  { label: 'Cameroon' },
  { label: 'Canada' },
  { label: 'Cape Verde' },
  { label: 'Cayman Islands' },
  { label: 'Central African Republic' },
  { label: 'Chad' },
  { label: 'Chile' },
  { label: 'China' },
  { label: 'Cocos Islands' },
  { label: 'Colombia' },
  { label: 'Comoros' },
  { label: 'Congo' },
  { label: 'Cook Islands' },
  { label: 'Costa Rica' },
  { label: "Cote D'Ivoire" },
  { label: 'Croatia' },
  { label: 'Cuba' },
  { label: 'Cyprus' },
  { label: 'Czech Republic' },
  { label: 'Denmark' },
  { label: 'Djibouti' },
  { label: 'Dominica' },
  { label: 'Dominican Republic' },
  { label: 'East Timor' },
  { label: 'Ecuador' },
  { label: 'Egypt' },
  { label: 'El Salvador' },
  { label: 'Equatorial Guinea' },
  { label: 'Eritrea' },
  { label: 'Estonia' },
  { label: 'Ethiopia' },
  { label: 'Falkland Islands' },
  { label: 'Faroe Islands' },
  { label: 'Fiji' },
  { label: 'Finland' },
  { label: 'France' },
  { label: 'French Polynesia' },
  { label: 'Gabon' },
  { label: 'Gambia' },
  { label: 'Georgia' },
  { label: 'Germany' },
  { label: 'Ghana' },
  { label: 'Gibraltar' },
  { label: 'Greece' },
  { label: 'Greenland' },
  { label: 'Grenada' },
  { label: 'Guam' },
  { label: 'Guatemala' },
  { label: 'Guinea' },
  { label: 'Guinea-Bissau' },
  { label: 'Guyana' },
  { label: 'Haiti' },
  { label: 'Honduras' },
  { label: 'Hong Kong' },
  { label: 'Hungary' },
  { label: 'Iceland' },
  { label: 'India' },
  { label: 'Indonesia' },
  { label: 'Iran' },
  { label: 'Iraq' },
  { label: 'Ireland' },
  { label: 'Israel' },
  { label: 'Italy' },
  { label: 'Jamaica' },
  { label: 'Japan' },
  { label: 'Jersey' },
  { label: 'Jordan' },
  { label: 'Kazakhstan' },
  { label: 'Kenya' },
  { label: 'Kiribati' },
  { label: "Democratic People's Republic of Korea" },
  { label: 'South Korea' },
  { label: 'Kuwait' },
  { label: 'Kyrgyzstan' },
  { label: 'Laos' },
  { label: 'Latvia' },
  { label: 'Lebanon' },
  { label: 'Lesotho' },
  { label: 'Liberia' },
  { label: 'Libya' },
  { label: 'Liechtenstein' },
  { label: 'Lithuania' },
  { label: 'Luxembourg' },
  { label: 'Macedonia' },
  { label: 'Madagascar' },
  { label: 'Malawi' },
  { label: 'Malaysia' },
  { label: 'Maldives' },
  { label: 'Mali' },
  { label: 'Malta' },
  { label: 'Marshall Islands' },
  { label: 'Martinique' },
  { label: 'Mauritania' },
  { label: 'Mauritius' },
  { label: 'Mexico' },
  { label: 'Micronesia' },
  { label: 'Moldova' },
  { label: 'Monaco' },
  { label: 'Mongolia' },
  { label: 'Montenegro' },
  { label: 'Montserrat' },
  { label: 'Morocco' },
  { label: 'Mozambique' },
  { label: 'Myanmar' },
  { label: 'Namibia' },
  { label: 'Nauru' },
  { label: 'Nepal' },
  { label: 'Netherlands' },
  { label: 'Netherlands Antilles' },
  { label: 'New Zealand' },
  { label: 'Nicaragua' },
  { label: 'Niger' },
  { label: 'Nigeria' },
  { label: 'Niue' },
  { label: 'Northern Mariana Islands' },
  { label: 'Norway' },
  { label: 'Oman' },
  { label: 'Pakistan' },
  { label: 'Palau' },
  { label: 'Panama' },
  { label: 'Papua New Guinea' },
  { label: 'Paraguay' },
  { label: 'Peru' },
  { label: 'Philippines' },
  { label: 'Pitcairn' },
  { label: 'Poland' },
  { label: 'Portugal' },
  { label: 'Puerto Rico' },
  { label: 'Qatar' },
  { label: 'Reunion' },
  { label: 'Romania' },
  { label: 'Russia' },
  { label: 'Rwanda' },
  { label: 'Saint Kitts and Nevis' },
  { label: 'Saint LUCIA' },
  { label: 'Saint Vincent and the Grenadines' },
  { label: 'Samoa' },
  { label: 'San Marino' },
  { label: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia' },
  { label: 'Serbia' },
  { label: 'Senegal' },
  { label: 'Seychelles' },
  { label: 'Sierra' },
  { label: 'Singapore' },
  { label: 'Slovakia' },
  { label: 'Slovenia' },
  { label: 'Solomon Islands' },
  { label: 'Somalia' },
  { label: 'South Africa' },
  { label: 'South Georgia' },
  { label: 'Spain' },
  { label: 'SriLanka' },
  { label: 'St. Helena' },
  { label: 'Sudan' },
  { label: 'Suriname' },
  { label: 'Svalbard' },
  { label: 'Swaziland' },
  { label: 'Sweden' },
  { label: 'Switzerland' },
  { label: 'Syria' },
  { label: 'Taiwan' },
  { label: 'Tajikistan' },
  { label: 'Tanzania' },
  { label: 'Thailand' },
  { label: 'Togo' },
  { label: 'Tokelau' },
  { label: 'Tonga' },
  { label: 'Trinidad and Tobago' },
  { label: 'Tunisia' },
  { label: 'Turkey' },
  { label: 'Turkmenistan' },
  { label: 'Turks and Caicos' },
  { label: 'Tuvalu' },
  { label: 'Uganda' },
  { label: 'Ukraine' },
  { label: 'United Arab Emirates' },
  { label: 'United Kingdom' },
  { label: 'United States' },
  { label: 'United States Minor Outlying Islands' },
  { label: 'Uruguay' },
  { label: 'Uzbekistan' },
  { label: 'Vanuatu' },
  { label: 'Venezuela' },
  { label: 'Vietnam' },
  { label: 'Virgin Islands (British)' },
  { label: 'Virgin Islands (U.S)' },
  { label: 'Wallis and Futana Islands' },
  { label: 'Yemen' },
  { label: 'Yugoslavia' },
  { label: 'Zambia' },
  { label: 'Zimbabwe' },
];

export const countryNotValid: CountryListType = [
  { value: 'United States' },
  { value: 'United Kingdom' },
  { value: 'United States Minor Outlying Islands' },
  { value: 'United Arab Emirates' },
  { value: 'Puerto Rico' },
  { value: 'Virgin Islands (U.S)' },
  { value: 'Saint Vincent and the Grenadines' },
  { value: 'Iran' },
  { value: "Democratic People's Republic of Korea" },
  { value: 'Syria' },
  { value: 'Cuba' },
  { value: 'Afghanistan' },
  { value: 'Pakistan' },
  { value: 'Sudan' },
  { value: 'Libya' },
  { value: 'Iraq' },
  { value: 'Canada' },
  { value: 'American Samoa' },
  { value: 'Yugoslavia' },
  { value: 'Northern Mariana Islands' },
];

export const countryIds: CountryCodeType = {
  Andorra: 'AD',
  'United Arab Emirates': 'AE',
  Afghanistan: 'AF',
  'Antigua and Barbuda': 'AG',
  Anguilla: 'AI',
  Albania: 'AL',
  Armenia: 'AM',
  Angola: 'AO',
  Antarctica: 'AQ',
  Argentina: 'AR',
  'American Samoa': 'AS',
  Austria: 'AT',
  Australia: 'AU',
  Aruba: 'AW',
  'Aland Islands': 'AX',
  Azerbaijan: 'AZ',
  'Bosnia and Herzegovina': 'BA',
  Barbados: 'BB',
  Bangladesh: 'BD',
  Belgium: 'BE',
  'Burkina Faso': 'BF',
  Bulgaria: 'BG',
  Bahrain: 'BH',
  Burundi: 'BI',
  Benin: 'BJ',
  'Saint Barthelemy': 'BL',
  Bermuda: 'BM',
  Brunei: 'BN',
  'Bolivia (Plurinational State of)': 'BO',
  'Bonaire, Sint Eustatius and Saba': 'BQ',
  Brazil: 'BR',
  Bahamas: 'BS',
  Bhutan: 'BT',
  Botswana: 'BW',
  Belarus: 'BY',
  Belize: 'BZ',
  Canada: 'CA',
  'Cocos (Keeling) Islands': 'CC',
  'Congo, Democratic Republic of the': 'CD',
  'Central African Republic': 'CF',
  Congo: 'CG',
  Switzerland: 'CH',
  "Cote D'Ivoire": 'CI',
  'Cook Islands': 'CK',
  Chile: 'CL',
  Cameroon: 'CM',
  China: 'CN',
  Colombia: 'CO',
  'Costa Rica': 'CR',
  Cuba: 'CU',
  'Cabo Verde': 'CV',
  Curacao: 'CW',
  Cyprus: 'CY',
  Czechia: 'CZ',
  Germany: 'DE',
  Djibouti: 'DJ',
  Denmark: 'DK',
  Dominica: 'DM',
  'Dominican Republic': 'DO',
  Algeria: 'DZ',
  Ecuador: 'EC',
  Estonia: 'EE',
  Egypt: 'EG',
  Eritrea: 'ER',
  Spain: 'ES',
  Ethiopia: 'ET',
  Finland: 'FI',
  Fiji: 'FJ',
  'Falkland Islands (Malvinas)': 'FK',
  'Micronesia (Federated States of)': 'FM',
  'Faroe Islands': 'FO',
  France: 'FR',
  Gabon: 'GA',
  'United Kingdom of Great Britain and Northern Ireland': 'GB',
  Grenada: 'GD',
  Georgia: 'GE',
  Guernsey: 'GG',
  Ghana: 'GH',
  Gibraltar: 'GI',
  Greenland: 'GL',
  Gambia: 'GM',
  Guinea: 'GN',
  'Equatorial Guinea': 'GQ',
  Greece: 'GR',
  'South Georgia and the South Sandwich Islands': 'GS',
  Guatemala: 'GT',
  Guam: 'GU',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  'Hong Kong': 'HK',
  'Heard Island and McDonald Islands': 'HM',
  Honduras: 'HN',
  Croatia: 'HR',
  Haiti: 'HT',
  Hungary: 'HU',
  Indonesia: 'ID',
  Ireland: 'IE',
  Israel: 'IL',
  'Isle of Man': 'IM',
  India: 'IN',
  Iraq: 'IQ',
  'Iran (Islamic Republic of)': 'IR',
  Iceland: 'IS',
  Italy: 'IT',
  Jersey: 'JE',
  Jamaica: 'JM',
  Jordan: 'JO',
  Japan: 'JP',
  Kenya: 'KE',
  Kyrgyzstan: 'KG',
  Cambodia: 'KH',
  Kiribati: 'KI',
  Comoros: 'KM',
  'Saint Kitts and Nevis': 'KN',
  "Korea (Democratic People's Republic of)": 'KP',
  'Korea, Republic of': 'KR',
  Kuwait: 'KW',
  'Cayman Islands': 'KY',
  Kazakhstan: 'KZ',
  "Lao People's Democratic Republic": 'LA',
  Lebanon: 'LB',
  'Saint Lucia': 'LC',
  Liechtenstein: 'LI',
  'Sri Lanka': 'LK',
  Liberia: 'LR',
  Lesotho: 'LS',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Latvia: 'LV',
  Libya: 'LY',
  Morocco: 'MA',
  Monaco: 'MC',
  'Moldova, Republic of': 'MD',
  Montenegro: 'ME',
  'Saint Martin (French part)': 'MF',
  Madagascar: 'MG',
  'Marshall Islands': 'MH',
  'North Macedonia': 'MK',
  Mali: 'ML',
  Myanmar: 'MM',
  Mongolia: 'MN',
  Macao: 'MO',
  'Northern Mariana Islands': 'MP',
  Martinique: 'MQ',
  Mauritania: 'MR',
  Montserrat: 'MS',
  Malta: 'MT',
  Mauritius: 'MU',
  Maldives: 'MV',
  Malawi: 'MW',
  Mexico: 'MX',
  Malaysia: 'MY',
  Mozambique: 'MZ',
  Namibia: 'NA',
  Niger: 'NE',
  Nigeria: 'NG',
  Nicaragua: 'NI',
  'Netherlands[note 1]': 'NL',
  Norway: 'NO',
  Nepal: 'NP',
  Nauru: 'NR',
  Niue: 'NU',
  'New Zealand': 'NZ',
  Oman: 'OM',
  Panama: 'PA',
  Peru: 'PE',
  'French Polynesia': 'PF',
  'Papua New Guinea': 'PG',
  Philippines: 'PH',
  Pakistan: 'PK',
  Poland: 'PL',
  'Saint Pierre and Miquelon': 'PM',
  Pitcairn: 'PN',
  'Puerto Rico': 'PR',
  'Palestine, State of': 'PS',
  Portugal: 'PT',
  Palau: 'PW',
  Paraguay: 'PY',
  Qatar: 'QA',
  Réunion: 'RE',
  Romania: 'RO',
  Serbia: 'RS',
  'Russian Federation': 'RU',
  Rwanda: 'RW',
  'Saudi Arabia': 'SA',
  'Solomon Islands': 'SB',
  Seychelles: 'SC',
  Sudan: 'SD',
  Sweden: 'SE',
  Singapore: 'SG',
  'Saint Helena, Ascension and Tristan da Cunha': 'SH',
  Slovenia: 'SI',
  'Svalbard and Jan Mayen': 'SJ',
  Slovakia: 'SK',
  'Sierra Leone': 'SL',
  'San Marino': 'SM',
  Senegal: 'SN',
  Somalia: 'SO',
  Suriname: 'SR',
  'South Sudan': 'SS',
  'Sao Tome and Principe': 'ST',
  'El Salvador': 'SV',
  'Sint Maarten (Dutch part)': 'SX',
  'Syrian Arab Republic': 'SY',
  Eswatini: 'SZ',
  'Turks and Caicos Islands': 'TC',
  Chad: 'TD',
  Togo: 'TG',
  Thailand: 'TH',
  Tajikistan: 'TJ',
  Tokelau: 'TK',
  'Timor-Leste': 'TL',
  Turkmenistan: 'TM',
  Tunisia: 'TN',
  Tonga: 'TO',
  Turkey: 'TR',
  'Trinidad and Tobago': 'TT',
  Tuvalu: 'TV',
  'Taiwan, Province of China[note 2]': 'TW',
  'Tanzania, United Republic of': 'TZ',
  Ukraine: 'UA',
  Uganda: 'UG',
  'United States Minor Outlying Islands': 'UM',
  'United States of America': 'US',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  'Saint Vincent and the Grenadines': 'VC',
  'Venezuela (Bolivarian Republic of)': 'VE',
  'Virgin Islands (British)': 'VG',
  'Virgin Islands (U.S.)': 'VI',
  'Viet Nam': 'VN',
  Vanuatu: 'VU',
  'Wallis and Futuna': 'WF',
  Samoa: 'WS',
  Yemen: 'YE',
  'South Africa': 'ZA',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
};

export const DEFAULT_REFERRAL_CODE = 'USER010';

export const DEFAULT_DISCOUNT_PERCENTAGE = 10;

export const countryListNotValidForOTP = [
  { label: 'China' },
  { label: 'Bangladesh' },
  { label: 'Kyrgyzstan' },
];
