import { mergeAll } from 'helpers/ramda';

const black = {
  black: '#000000',
  lightblack: '#131a22',
  lightblack1: '#1d2733',
  lightblack2: '#141b24',
  lightblack3: '#19222c',
  lighterblack: '#19232d',
  lighterblack1: '#151d26',
  lighterblack2: '#212934',
  lighterblack3: '#28303c',
  woodSmokeBlack: '#0d0d0d',
  mirageBlack: '#1b202e',
  pearlBlack: '#0b1520',
  border: '#2D3B4C',
  tabLighterBlack: '#1f2a35',
  separatorBlack: '#212A34',

  headerSeparator: '#29313B',
  overlayBackground: '#19232D',
};

const white = {
  white: '#ffffff',
  lightwhite: '#dadcdd',
  greyishwhite: '#d1d2d5',
  greyishwhite1: '#a2a2a2',
  darkgreyishwhite: '#919191',
  offwhite: '#717782',
  offwhite1: '#e1e2e3',
  offwhite2: '#d4d4d4',
  offwhite3: 'var(--main-text-secondary)',
  secondarywhite: '#d4d4d4',
  smokeWhite: '#f0f0f0',
};

const grey = {
  grey: '#273142',
  lightgrey: '#303D4E',
  lightergrey: '#474e57',
  lightergrey1: '#5e646c1a',
  lightergrey2: '#dddddd',
  lightergrey3: '#a6a9ad',
  lightergrey4: '#a5a9ad',
  brightgrey: '#333a44',
  darkgrey: '#1D2737',
  darkgrey1: '#212934',
  darkgrey3: '#757a81',
  darkgrey4: '#2c343e',
  brightergrey: '#222a35',
  transparentgrey: '#21293499',
  notificationgrey: '#757575',
  notificationboxgrey: '#1f2935',
  notificationtextGrey: '#bdbdbd',
  bluegrey: '#262F39',
  darkbluegrey: '#2e3744',
  palettelightgrey: '#303d4e',
  chartgrey: '#2b333d',
  blackgrey: '#19222D',
  orient: '#13607e',
  mountainMistGrey: '#979797',
  fiordGrey: '#455264',
  ravenGrey: '#666f81',
  tabTextGrey: '#666c77',
  tabBgGrey: '#212933',
};

const blue = {
  blue: '#0000ff',
  // lightblue: '#0090bc',
  lightblue: 'var(--primaryTheme)',
  cyan: '#10a2ce',
  darkcyan: '#073847',
  darkblue: '#3976b5',
  brightblue: '#00c3ff',
  lighterblue: '#162834',
  highlightblue: '#3a87a7',
  darkerblue: '#00b5eb',
  brighterblue: '#1c53a4',
  transparentblue: '#173c4e',
  skyblue: '#2771f9',
  bluishgrey: '#384960',
  jordyBlue: '#7cb5ec',
  lochmarablue: '#0081d2',
  bostonBlue: '#0090bc',
  pacificBlue: '#0096c8',
  bigStoneBlue: '#192739',
  irisBlue: '#02ace0',
  pelorousBlue: '#2d8db5',
};

const green = {
  green: '#3A7D5C',
  hoverGreen: '#339664',
  lightgreen: '#55B987',
  brightergreen: '#06F922',
  lightergreen: '#37FA4C',
  brightgreen: '#69FB7A',
  faintgreen: '#77ce32',
  darkgreen: '#4f862c',
  greenblack: '#315027',
  palegreen: '#3E682C',
  mustardGreen: '#ffdf6c',
  greenMuted: '#3a765e',
  anotherGreen: '#3d9e78',
  tradingBarUpColor: '#04ac6d',

  lighterGreen: '#55b9871f',
};

const red = {
  red: '#85322E',
  hoverRed: '#9a3731',
  lightred: '#D75750',
  warningred: '#b36161',
  statusred: '#c52e28',
  errorred: '#d75750',
  darkred: '#8e2725',
  redblack: '#502124',
  palered: '#782628',
  redMuted: '#944443',
  anotherRed: '#b24943',
  tradingBarRedColor: '#d75750',

  lighterRed: '#d757501f',
};

const orange = {
  orange: '#FF9600',
  lightOrange: '#de9332',
  mustardOrange: '#ff7c10',
};

const yellow = {
  yellow: '#ffd423',
};

export default mergeAll([black, white, grey, blue, green, red, orange, yellow]);
