import styled from 'styled-components';

import { device } from '../helpers/screenSizes';

const Button = styled.button`
  background-color: ${props => props.theme.primaryTheme};
  color: var(--primaryCtaText);
  border: 0px;
  font-size: 14px;
  padding: 6px;
  margin: 0px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  height: 40px;
  width: 150px;
  &:focus {
    outline: none;
  }
  &:hover:not([disabled]) {
    background-color: var(--brand-bg-hover);
    color: var(--primaryCtaTextHover);
  }
  &:disabled {
    background: var(--brand-bg-disabled);
    color: var(--primaryCtaText);
    cursor: not-allowed;
  }
`;

// todo-h: @Faiyaz: The width of a button should automatically be set based on its content + padding;
// That's the reason the text isn't vertically aligned currently. See btn-download-logs.js for reference.
export const DownloadButton = styled(Button)`
  width: 105px;
  height: 32px;
  font-size: 12.1px;
  border-radius: 2px;
  background-color: ${props => props.theme.primaryBackground};
  border: solid 1px ${props => props.theme.primaryBackground};
  color: ${props => props.theme.secondaryText};

  @media ${device.down.md} {
    width: 100px;
    height: 30px;
  }

  & svg {
    position: relative;
    top: -3px;
    margin-right: 5px;
  }

  &:hover {
    background-color: ${props => props.theme.primaryTheme};
    border-color: ${props => props.theme.primaryTheme};
    color: ${props => props.theme.primaryText};

    & > svg path {
      fill: ${props => props.theme.primaryText};
    }
  }
`;

export default Button;
