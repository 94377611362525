import { store } from '@risingstack/react-easy-state';

// import { MIXPANEL_EVENT_NAMES } from 'constants/mixpanelEventNames';
// import { trackMixpanelEvent } from 'helpers/mixpanel-init';
import { cropAfterDecimals } from 'helpers/utils';

import {
  type UpdateScalpingRebateFunctionParams,
  type ScalpingRebateProps,
} from './scalpingLink.types';

const scalpingRebate = store<ScalpingRebateProps>({
  latestCommisionRebate: null,
  totalCommisionRebate: null,

  resetRebateAmount: () => {
    scalpingRebate.latestCommisionRebate = null;
    scalpingRebate.totalCommisionRebate = null;
  },

  updateScalpingRebateAmount: ({
    latestCommisionRebate,
    totalCommisionRebate,
  }: UpdateScalpingRebateFunctionParams) => {
    scalpingRebate.latestCommisionRebate = String(
      cropAfterDecimals(Number(latestCommisionRebate), 2)
    );
    scalpingRebate.totalCommisionRebate = String(
      cropAfterDecimals(Number(totalCommisionRebate), 2)
    );
    // if (!firstTimeLoad) {
    //   trackMixpanelEvent(MIXPANEL_EVENT_NAMES.SCALPER_OFFER.SCALPER_TRADE, {
    //     latest_commission_rebate: latestCommisionRebate,
    //     total_commission_rebate: totalCommisionRebate,
    //   });
    // }
  },
});

export type { ScalpingRebateProps };
export { scalpingRebate };
