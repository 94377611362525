/* eslint-disable no-return-assign */
import { store } from '@risingstack/react-easy-state';

import { IVariableStore } from 'types/IVariableStore';

// @ts-ignore
const variableStore: IVariableStore = store({
  selecteProductFundingData: {
    estimatedFundingRate: null,
    nextFundingRealization: null,
    fundingRate: null,
  },
  tickerData: {
    tickerObj: {},
    spotPrice: {},
    markPrice: {},
    greeks: {
      delta: {},
      gamma: {},
      theta: {},
      vega: {},
    },
    askIV: {},
    bidIV: {},
    bidQty: {},
    askQty: {},
    bestAsk: {},
    bestBuy: {},
    impliedVolatility: {},
    priceBands: {},
    oi: {},
    callOi: {},
    putOi: {},
    markIV: {},
    oiChange: {},
  },
  // priceBands: {},
  irRates: {},
  selectedProductData: {
    markPrice: {
      implied_volatility: null,
      product_id: null,
      price: null,
      price_band: null,
    },
    spotPrice: null,
  },
  openPositionMarkData: {},
  walletSpotPrice: {},
  basketOrderOrderbooks: {
    buy: [],
    sell: [],
    product_id: null,
    symbol: null,
  }, // orderbook for selected product in basket
  // isResolutionChanged: false,
  recentTrades: [],
  orderbook: {
    buy: [],
    sell: [],
    showLoader: true,
  }, // selected product orderbook
  easyOptionsOrderbook: {
    buy: [],
    sell: [],
    product_id: null,
    symbol: null,
  },
  lastTrade: {}, // selected product lastTrade
  heartbeatTS: 0,
  openPositionBestAsk: null,
  openPositionBestBid: null,
  portfolio_margin: {},
  l2Updates: {
    bids: new Map(),
    asks: new Map(),
    sequence_no: null,
    symbol: '',
  },
  crossMarginChannel: {
    cross_upl: {},
    wallets: [],
  },
  multiCollateral: {
    wallets: [],
  },
  tradingViewSubscriptionData: {},
  publicHeartbeatTS: 0,
  // askIV: {},
  // bidIV: {},
  // bidQty: {},
  // askQty: {},
});

export default variableStore;
