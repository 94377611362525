/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/named */
/* eslint-disable camelcase */
import { pipe, path, toString, curry, map, evolve, equals, last } from 'helpers/ramda';
import i18n from 'i18n/config';
import type { Product } from 'types/IProducts';
import { IBook, ILastTrade, IPagination } from 'types/ITrade';
import { ITicker } from 'types/IVariableStore';
import type { OrderBook } from 'types/publicSocket';

import { getInt } from '../../helpers/utils';

// type pathToString = {
//   (_path: string[], obj: object): string;
//   (_path: string[]): (obj: object) => string;
// }
export const pathToString = curry((_path, obj) => pipe(path(_path), toString)(obj));

export const productIdByPayload = pathToString(['payload', 'product_id']);
export const productIdByResult = pathToString(['result', 'product_id']);

export const mapLimitPricetoPrice = ([price, size, depth]: OrderBook[number]): IBook => ({
  price: String(price),
  size: Number(size),
  depth: String(depth),
  limit_price: String(price),
});

export function calcPages(obj: IPagination): number {
  return Math.ceil(obj['x-pagination-total'] / obj['x-pagination-page-size']);
}

// type convertPriceToFixed =
//   (precision: number) => (obj: Book[]) => (Book[])

export const convertPriceToFixed = precision =>
  map(
    evolve({
      price: (_price: number | string) => {
        return parseFloat(_price.toString()).toFixed(precision);
      },
    })
  );

export function calcPrecision(data: number | string): number {
  const [, afterDecimal] = data.toString().split('.');
  return afterDecimal ? afterDecimal.length : 0;
}

type calcTurnoverType = (ticker: ITicker, product?: Partial<Product>) => string;

export const calcTurnover: calcTurnoverType = (tickerData, product) => {
  if (product === undefined || product.id === '0') {
    return '-';
  }

  const {
    quoting_asset,
    settling_asset,
    underlying_asset,
    contract_value,
    contract_type,
    notional_type,
    contract_unit_currency,
  } = product;

  // @ts-ignore-start
  switch (contract_type) {
    case 'call_options':
    case 'put_options':
    case 'turbo_put_options':
    case 'turbo_call_options':
    case 'move_options':
    case 'options_combos': {
      const turnover = (Number(contract_value) * tickerData.volume).toFixed(
        underlying_asset.minimum_precision
      );
      const turnoverAsset = underlying_asset.symbol;
      return `${turnover} ${turnoverAsset}`;
    }
    case 'spreads':
    case 'interest_rate_swaps': {
      const turnover = Number(product.contract_value) * tickerData.volume;
      const turnoverAsset = contract_unit_currency;
      return `${turnover} ${turnoverAsset}`;
    }
    default: {
      const turnover =
        notional_type === 'inverse'
          ? parseFloat(contract_value) * tickerData.volume
          : parseFloat(contract_value) * tickerData.volume * tickerData.close;

      const turnoverAsset = notional_type === 'inverse' ? quoting_asset : settling_asset;
      const precisionValue = equals(turnoverAsset.symbol, 'BTC') ? 2 : 0;
      return `${
        Number.isInteger(turnover) ? turnover : turnover.toFixed(precisionValue)
      } ${turnoverAsset.symbol}`;
    }
  }
};

export function calcPriceMovement(trade: ILastTrade, lastTrade?: ILastTrade): ILastTrade {
  let direction = 'up';
  if (parseFloat(trade?.price) < parseFloat(lastTrade?.price || '0')) {
    direction = 'down';
  } else if (parseFloat(trade?.price) > parseFloat(lastTrade?.price || '0')) {
    direction = 'up';
  } else {
    direction = lastTrade?.price_movement || 'up';
  }

  return {
    ...trade,
    price_movement: direction,
    has_changed: parseFloat(trade?.price) !== parseFloat(lastTrade?.price || '0'),
  };
}

export function populatePriceMovement(recent_trades: ILastTrade[]) {
  return recent_trades
    ?.reduceRight((acc, trade) => {
      const last_trade = last(acc);
      return [
        ...acc,
        {
          ...trade,
          ...calcPriceMovement(trade, last_trade),
          datetime: Number((trade?.timestamp || 1000) / 1000),
        },
      ];
    }, [])
    ?.reverse?.();
}

export const extractPaginationHeaders = (headers: IPagination) => ({
  currentPageNum: getInt(headers['x-pagination-page-num']),
  pageSize: getInt(headers['x-pagination-page-size']),
  totalPages: getInt(headers['x-pagination-total']),
});

// const calcTimeToProcess = (created_at: number): number => {
//   const withdrawalTime = new Date(created_at);
//   const withdrawalUTCDate = withdrawalTime.getUTCDate();
//   const withdrawalUTCHours = withdrawalTime.getUTCHours();

//   const currentTime = new Date();
//   const currentUTCDate = currentTime.getUTCDate();
//   const currentUTCHours = currentTime.getUTCHours();

//   if (currentUTCDate === withdrawalUTCDate && withdrawalUTCHours < 11) {
//     return 15 - currentUTCHours;
//   } else if (currentUTCDate === withdrawalUTCDate && withdrawalUTCHours >= 11) {
//     return 15 - currentUTCHours + 24;
//   } else if (currentUTCDate !== withdrawalUTCDate) {
//     return 15 - currentUTCHours;
//   } else {
//     return 15 - currentUTCHours;
//   }
// };

export const processWithdrawalState = withdrawal => {
  switch (withdrawal.state) {
    case 'unconfirmed':
      return {
        api_state: 'unconfirmed',
        state: i18n.t('trading:processWithdrawalState.unconfirmedState'),
        state_helper_text: i18n.t('trading:processWithdrawalState.unconfirmedHelper'),
      };
    case 'confirmed':
      // let timeToProcess = calcTimeToProcess(withdrawal.created_at);
      // // default to 2 hrs if negative ttp
      // if (timeToProcess < 0) {
      //   timeToProcess = 2;
      // }
      return {
        // time_process: timeToProcess,
        api_state: 'confirmed',
        state: i18n.t('trading:processWithdrawalState.awaitingProcessing'),
        // state_helper_text: `Withdrawal request confirmed and will be processed within next ${timeToProcess} hours.`,
      };
    case 'cancelled':
      return {
        api_state: 'cancelled',
        state: i18n.t('trading:processWithdrawalState.cancelledState'),
        state_helper_text: i18n.t('trading:processWithdrawalState.cancelledHelper'),
      };
    case 'initiated':
      return {
        api_state: 'initiated',
        state: i18n.t('trading:processWithdrawalState.initiatedState'),
        state_helper_text: i18n.t('trading:processWithdrawalState.initiatedHelper'),
      };
    case 'deemed':
      return {
        api_state: 'deemed',
        state: i18n.t('trading:processWithdrawalState.initiatedState'),
        state_helper_text: i18n.t('trading:processWithdrawalState.deemedHelper'),
      };
    case 'complete':
      return {
        api_state: 'complete',
        state: i18n.t('trading:processWithdrawalState.completeState'),
        state_helper_text: i18n.t('trading:processWithdrawalState.completeHelper'),
      };
    default:
      return {
        api_state: '',
        state: '',
        state_helper_text: '',
      };
  }
};

export const filterDataByContractType = (contractType: string, data: any[]) => {
  return data.filter(item => item.product.contract_type.includes(contractType));
};

export const getLabelByContractType = (contractType: string, label: string) => {
  if (contractType === 'interest_rate_swaps') {
    return label.replace(i18n.t('trading:price'), i18n.t('trading:rate'));
  }
  return label;
};

export const PRIVATE_SOCKET_ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};
