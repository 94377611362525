/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-template */
import { isMobile } from 'react-device-detect';

import { APP_DRAWER_TAB_ID_MAPPING } from 'reducers/initialstate/appDrawer';
import { CUSTODIAN_STATUS } from 'types/Account';

import routes from './routes';
import { TIME_FORMATS } from './timeformats';

const PROD_ENV = {
  PROD: 'production',
  BETA: 'beta',
  PROD_BACKUP: 'production-backup',
  PROD_INDIA: 'production-india',
} as const;

const IS_INDIAN_EXCHANGE = process.env.REACT_APP_IS_INDIAN_EXCHANGE === 'true';
const IND_DOMAIN_SWITCH = process.env.REACT_APP_IND_DOMAIN_SWITCH === 'true';
const IS_TESTNET_LINK_IN_INDIAN_EXCHANGE =
  process.env.REACT_APP_FEATURE_ADD_TESTNET_LINK_IN_INDIAN_EXCHANGE === 'true';

let siteRoot = !IS_INDIAN_EXCHANGE
  ? 'https://www.delta.exchange'
  : 'https://india.delta.exchange';

if (IND_DOMAIN_SWITCH) {
  siteRoot = IS_INDIAN_EXCHANGE
    ? 'https://delta.exchange'
    : 'https://global.delta.exchange';
}

const SITE_ROOT = siteRoot;

const GUIDES_SITE_ROOT = `https://guides.delta.exchange/${
  IS_INDIAN_EXCHANGE ? 'delta-exchange-india-user-guide' : 'delta-exchange-user-guide'
}`;

export const API_URL = process.env.REACT_APP_API_URL;
/** @type {string} */
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export const PUBLIC_SOCKET_URL = process.env.REACT_APP_PUBLIC_SOCKET_URL as string;

const isProd =
  process.env.REACT_APP_ENV === PROD_ENV.PROD ||
  process.env.REACT_APP_ENV === PROD_ENV.BETA ||
  process.env.REACT_APP_ENV === PROD_ENV.PROD_BACKUP;

const DISABLE_SEARCH_ENGINE_CRAWLING =
  process.env.REACT_APP_DISABLE_SEARCH_ENGINE_CRAWLING !== null &&
  process.env.REACT_APP_DISABLE_SEARCH_ENGINE_CRAWLING !== undefined;

const isDev = process.env.REACT_APP_ENV === 'development';

const isCI = process.env.REACT_APP_ENV === 'ci';
const isDevnet = process.env.REACT_APP_ENV === 'devnet' || isCI || isDev;
const isTestnet = ['testnet-india', 'test', 'testnet-global'].includes(
  process.env.REACT_APP_ENV ?? ''
);

const SIGNAL_TRADING = 'Signal Trading';

const IS_CI = process.env.REACT_APP_ENV === 'ci';

const VANILLA_SETTLING_ASSET = process.env.REACT_APP_VANILLA_SETTLING_ASSET;

const RELEASE_VERSION = process.env.REACT_APP_RELEASE_VERSION;

// Need to update these version when there is a new update on mobile application
const INDIA_ANDROID_VERSION = '1.2.15';
const INDIA_IOS_VERSION = '1.2.13';
const GLOBAL_ANDROID_VERSION = '1.12.2';
const GLOBAL_IOS_VERSION = '1.8.1';

const ANDROID_VERSION = IS_INDIAN_EXCHANGE
  ? INDIA_ANDROID_VERSION
  : GLOBAL_ANDROID_VERSION;
const IOS_VERSION = IS_INDIAN_EXCHANGE ? INDIA_IOS_VERSION : GLOBAL_IOS_VERSION;

/**
 * @description - It's being used only in one component and seems that component is not used anymore.
 * Component - src/containers/header
 */
export const HEADER_BLACKLISTED_TAB = [APP_DRAWER_TAB_ID_MAPPING.POSITIONS];
export const HEADER_BLACKLISTED_PATH = [
  routes.login,
  `${routes.signup}/:referral?`,
  routes.verifyRegister,
  routes.forgotPassword,
  `${routes.resetPassword}/:token`,
  routes.account.default,
  routes.contracts,
  routes.references,
  routes.referral,
  routes.offers,
  routes.account.withdrawal_confirmation,
  routes.easy_options.trade,
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.optionsCombos.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.spot.markets,
  routes.expired_futures_and_options,
  routes.options_chain.trade,
];

export const EMPTY_HEADER_PATH = [
  routes.login,
  routes.signup,
  routes.forgotPassword,
  routes.resetPassword,
  routes.verifyRegister,
  routes.account.withdrawal_confirmation,
  routes.expired_futures_and_options,
];

export const SHOW_TICKER = [
  routes.trade,
  routes.irs.trade,
  routes.futures.trade,
  routes.options.trade,
  routes.move_options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.options_chain.trade,
  routes.spot.trade,
];

export const SHOW_WARNING_BANNER = [...SHOW_TICKER];

export const SHOW_PRIMARY_HEADER = [
  routes.trade,
  routes.irs.trade,
  routes.futures.trade,
  routes.options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.options_chain.trade,
  routes.move_options.trade,
  routes.spot.trade,
  routes.optionsCombos.trade,
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.spot.markets,
  routes.optionsCombos.markets,
  routes.expired_futures_and_options,
  routes.options_chain.markets,
  routes.favorites.markets,
  routes.portfolio,
  routes.customLeaderBoard,
  routes.easy_options.trade,
  routes.bell_alerts,
  routes.optionsAnalyticsDashboard.default,
  routes.tradeHistory,
  routes.embedChart,
];

export const SHOW_LIQUIDATION_RISK = [
  routes.trade,
  routes.irs.trade,
  routes.futures.trade,
  routes.options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.options_chain.trade,
  routes.move_options.trade,
  routes.spot.trade,
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.spot.markets,
  routes.options_chain.markets,
  routes.optionsCombos.markets,
];

const SHOW_TRADING_REVOKED_STRIP = [
  routes.futures.trade,
  routes.options.trade,
  routes.spreads.trade,
  routes.irs.trade,
  routes.turbo.trade,
  routes.options_chain.trade,
  routes.move_options.trade,
  routes.spot.trade,
];

export const SHOW_PRIMARY_HEADER_MOBILE = [routes.homepage.default];

const HIDE_MAINTENANCE_BANNER_FROM_ROUTE = [routes.optionsLeaderboard];

export const SHOW_MAINTENANCE_NOTIF = [
  ...SHOW_PRIMARY_HEADER,
  ...SHOW_PRIMARY_HEADER_MOBILE,
].filter(item => {
  return !HIDE_MAINTENANCE_BANNER_FROM_ROUTE.includes(item);
});

export const SHOW_ERC20_DISABLE_STRIP = [...SHOW_PRIMARY_HEADER, routes.account.default];

export const SHOW_INFORMATION_STRIP_ROUTES = [
  ...SHOW_PRIMARY_HEADER,
  ...SHOW_PRIMARY_HEADER_MOBILE,
];

export const SHOW_SCALPER_OFFER_STRIP_ROUTES = [
  ...SHOW_PRIMARY_HEADER,
  routes.homepage.default,
  routes.account.bankDetails,
  routes.account.deposit,
];

export const MARKETS_ROUTES = [
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.spot.markets,
  routes.optionsCombos.markets,
];

export const HIDE_TROLLBOX_PATH = [
  routes.login,
  routes.signup,
  routes.forgotPassword,
  routes.account.withdrawal_confirmation,
  routes.account.default,
  routes.account.security,
  routes.account.deposit,
  routes.account.deposit_history,
  routes.account.withdrawal,
  routes.account.withdrawal_history,
  routes.account.buy_crypto,
  routes.account.fiat_deposit_history,
  routes.account.profile,
  routes.account.manageapikeys,
  routes.expired_futures_and_options,
];

export const SHOW_MOBILE_FOOTER = [
  routes.futures.trade,
  routes.irs.trade,
  routes.options.trade,
  routes.options_chain.trade,
  routes.move_options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.optionsCombos.trade,
  routes.spot.trade,
  routes.favorites.markets,
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.options_chain.markets,
  routes.spot.markets,
  routes.optionsCombos.markets,
  routes.homepage.default,
  routes.optionAnalytics.markets,
];

export const FOOTER_PATH_MOBILE_SCREENS_PATHS = [
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.options_chain.markets,
  routes.spot.markets,
  routes.optionsCombos.markets,
];

export const FOOTER_PATH_LARGE_SCREENS_PATHS = [
  routes.favorites.markets,
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.spot.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.referral,
  routes.offers,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.options_chain.markets,
  routes.optionsLeaderboard,
  routes.optionsCombos.markets,
  // routes.easy_options.trade,
  // routes.options.trade,
  // routes.turbo.trade,
  // routes.spreads.trade,
  // routes.options_chain.trade,
  // routes.spot.trade,
  routes.optionsAnalyticsDashboard.default,
  routes.tradeHistory,
];

export const SHOW_SECONDARY_HEADER = [
  routes.futures.trade,
  routes.irs.trade,
  routes.options.trade,
  routes.move_options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.options_chain.trade,
  routes.spot.trade,
  routes.optionsCombos.trade,
];

export const contractTypeRedirection = [
  { from: `${routes.default}futures`, to: routes.futures.markets },
  { from: `${routes.default}move_options`, to: routes.move_options.markets },
  { from: `${routes.default}spot`, to: routes.spot.markets },
  { from: `${routes.default}spreads`, to: routes.spreads.markets },
  { from: `${routes.default}interest_rate_swaps`, to: routes.irs.markets },
  { from: `${routes.default}easy_options`, to: routes.easy_options.trade },
  { from: `${routes.default}options_combos`, to: routes.optionsCombos.trade },
];

export const USER_AUTH_PATH = [routes.login, routes.signup];

export const [CONNECTING, OPEN, CLOSING, CLOSED] = [0, 1, 2, 3];
// for development env SENTRY_DSN will be undefined
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const PALETTE_CLIENT_KEY = process.env.REACT_APP_PALETTE_CLIENT_KEY;

export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS;

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  ...(isProd && {
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  }),
};

export const BLOCKCHAIN_EXPLORER_HOST = process.env.REACT_APP_BLOCKCHAIN_EXPLORER_HOST;

export const ETH_USDT_BLOCKCHAIN_EXPLORER_HOST =
  process.env.REACT_APP_ETH_USDT_BLOCKCHAIN_EXPLORER_HOST;

export const BSC_BLOCKCHAIN_EXPLORER_HOST =
  process.env.REACT_APP_BSC_BLOCKCHAIN_EXPLORER_HOST;

export const XRP_BLOCKCHAIN_EXPLORER_HOST =
  process.env.REACT_APP_XRP_BLOCKCHAIN_EXPLORER_HOST;

export const SOL_BLOCKCHAIN_EXPLORER_HOST =
  process.env.REACT_APP_SOL_BLOCKCHAIN_EXPLORER_HOST;

export const TRC20_BLOCKCHAIN_EXPLORER_HOST =
  process.env.REACT_APP_TRC20_BLOCKCHAIN_EXPLORER_HOST;

const POLYGON_BLOCKCHAIN_EXPLORER_HOST =
  process.env.REACT_APP_POLYGON_BLOCKCHAIN_EXPLORER_HOST;

export const ENABLE_THEMING = process.env.REACT_APP_ENABLE_THEMING === 'true';
export const ENABLE_BLINKING = process.env.REACT_APP_ENABLE_BLINKING === 'true';
export const SHOW_POPUPS = process.env.REACT_APP_SHOW_POPUPS === 'true';
export const ENABLE_BOTS = process.env.REACT_APP_ENABLE_BOTS === 'true';
export const ENABLE_INTRO = process.env.REACT_APP_ENABLED_INTRO === 'false';

export const MIN_WITHDRAWAL_AMOUNT = 0.001; //
export const MIN_WITHDRAWAL_AMOUNT_INDIA = 500; //
export const MIN_WITHDRAWAL_AMOUNT_REFERRAL_BONUS = 1000;
export const MAX_WITHDRAWAL_AMOUNT_INDIA = 500000; //

export const MINIMUM_DEPOSIT_AMOUNT_INDIA = 500;
export const MAXIMUM_DEPOSIT_AMOUNT_INDIA = 500000;

export const ENABLED_WALLETS = process.env.REACT_APP_ENABLE_WALLETS?.split?.(',');

// id used for level 3 verification(verify address)
export const BLOCKPASS_CLIENT_ID = process.env.REACT_APP_BLOCKPASS_CLIENT_ID;

// id used for level 2 verification(verify phone and id)
export const ID_VERIFICATION_BLOCKPASS_CLIENT_ID =
  process.env.REACT_APP_IDENTITY_VERIFICATION_BLOCKPASS_ID;
export const DEPOSIT_POPUP_THRESHOLDS = {
  BTC: 0.001,
  USDT: 10,
};

export const TRADE_FARMING_INCEPTION_DATE = '2021-11-11T12:00:00Z'; // 11th Nov, 12pm UTC

export const LANDING_PAGE_URL = process.env.REACT_APP_LANDING_PAGE_URL;
export const DELTA_CMS_URL = process.env.REACT_APP_DELTA_CMS_URL;
export const BACKUP_PAGE_URL = process.env.REACT_APP_BACKUP_PAGE_URL;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const VAPID_KEY = process.env.REACT_APP_VAPID_KEY;

export const REFERRAL_MESSAGE_PERCENT = '10';
const GLOBAL_FRESHDESK_URL = 'https://deltaexchange.freshdesk.com';
const INDIA_FRESHDESK_URL = 'https://deltaexchangeindia.freshdesk.com';

export const FUNDING_CHART_URL =
  'https://www.delta.exchange/data-analytics/d/i59H8qzWz/funding-rates-delta-exchange?refresh=30s&orgId=1&var-instrument_name=FUNDING:';

export const MIN_LEVERAGE = 0.1;
export const BTC_MAX_PREC = 8;
export const LEVERAGE_PREC = 2;

export const ENABLE_OPTIONS = process.env.REACT_APP_ENABLE_EASY_OPTIONS === 'true';

export const isTrollboxEnabled = process.env.REACT_APP_SHOW_TROLLBOX === 'true';

export const FONT_FAMILY =
  "'Aileron Regular',-apple-system,BlinkMacSystemFont,'Open Sans', Arial,sans-serif";

export const EMPTY_COLUMN_PLACEHOLDER = '-';

export const MARKETS_CHART_TYPES = {
  PRICE_CHART: 'price',
  FUNDING: 'funding',
};

export const TABLE_DATA_FETCH_COUNT = 30;
export const DEFAULT_PAGINATION_ROWS_PER_PAGE = 15;
export const DEFAULT_PAGINATION_PAGE = 0;
export const LOCAL_STORAGE_PAGINATION_KEY = 'pagination';
export const LOCAL_STORAGE_MOBILE_PAGINATION_KEY = 'mobile_pagination';
export const LOCAL_STORAGE_KYC_POPUP_KEY = 'kyc_popup_closed_at';
export const LOCAL_STORAGE_KYC_UPGRADE_POPUP_KEY = 'kycUpgradePopupCloseTime';

// TODO: Rename
// TODO: Remove unused keys
export const PAGINATION_PERSIST_STATE_TABLES = {
  ORDERS_HISTORY: 'ordersHistory',
  FILLS: 'fills',
  STOP_ORDERS: 'stop-orders',
  OPEN_ORDERS: 'open-orders',
  OPEN_POSITIONS: 'open-positions',
  BTC_TRANSACTIONS: 'btc-transactions',
  USDC_TRANSACTIONS: 'usdc-transactions',
  TL_ASSET_HISTORY: 'transaction-log-asset-history',
  TL_ORDER_HISTORY: 'transaction-log-order-history',
  TL_TRADE_HISTORY: 'transaction-log-trade-history',
  TL_TRANSFER_FUND_HISTORY: 'transaction-log-trasnfer-fund-history',
  EXPIRED_PRODUCTS: 'expired-products',
  PENDING_WITHDRAWALS: 'pending-withdrawals',
};

export const NUMBER_QUANTITY = {
  THOUSAND: 1000,
  TENTHOUSAND: 10000,
  LAC: 100000,
  MILLION: 1000000,
  TEN_MILLION: 10000000,
};

// TODO: Use window.matchMedia('(max-width: 1024px)') to maintain consistency between settings. This change may affect behavior and needs careful consideration.
export const TAB_INACTIVE_DELAY = isMobile ? 1000 * 60 : 1000 * 60 * 2; //  mobile (1min) and desktop (30min).
export const TICKER_DELAY = 1000 * 60; // 1 minute
export const SOCKET_CONNECTION_TIMEOUT = 10000;
export const SOCKET_RECONNECTION_DELAY = 5000;
export const TICKER_REFRESH_INTERVAL = 2500;

export const POPOVER_AUTO_CLOSE_DELAY = 500;

export const POPOVER_CLOSE_PREV_THRESHOLD = 1000;

export const POST_ONLY_ORDER_NAMES = {
  GTC: 'GTC',
  FOK: 'FOK',
  IOC: 'IOC',
};

export const HEADERS = {
  PAGE_NUM: 'x-pagination-page-num',
  TOTAL: 'x-pagination-total',
  PAGE_SIZE: 'x-pagination-page-size',
};

// filtering by contract type
export const HOLDINGS_TABLE_API_BASE = {
  page_num: 1,
  page_size: TABLE_DATA_FETCH_COUNT,
  backgroundFetch: false,
};

export const HOLDINGS_HIDE_FILTER_TABS = ['open-positions', 'balance'];

export const HOLDINGS_HIDE_TAB_ON_MOBILE = ['balance'];

export const HOLDINGS_SHOW_REFRESH_TABS = ['fills', 'orders-history'];

export const OTP_LENGTH = 6;

export const MIN_MOBILE_ORDERBOOK_LEVELS_BOTH = 150;
export const MIN_MOBILE_ORDERBOOK_LEVELS_SINGLE = 150;
export const MIN_DESKTOP_ORDERBOOK_LEVELS_BOTH = 10;
export const MIN_DESKTOP_SPOT_ORDERBOOK_LEVELS_BOTH = 11;
export const MIN_DESKTOP_ORDERBOOK_LEVELS_SINGLE = 150;
export const MIN_OPTIONS_CHAIN_ORDERBOOK_LEVELS = 50;
export const MIN_DESKTOP_SPOT_ORDERBOOK_LEVELS_SINGLE = 300;

export const OVERLAY_TYPES = {
  CONTRACT_DROPDOWN: 'contract_dropdown',
  BALANCE_MENU: 'balance_menu',
};

// TODO: Move to theme
export const ORDERBOOK_TABLE_HEAD_HEIGHT = 36;

export const CONTRACT_TYPE = {
  IRS: 'interest_rate_swaps',
  MOVE_OPTIONS: 'move_options',
  FUTURES: 'futures',
  OPTIONS: 'options',
  OPTIONS_COMBOS: 'options_combos',
  SPOT: 'spot',
};

// Add user id here to disable notifications
// 2568 is testing id
export const USER_IDS_DISABLE_FOR_NOTIFICATIONS = isProd
  ? [15, 16, 122, 221, 3422, 17540]
  : [];

export const mobileDefault = { page_size: 5, page_num: 1 };

export const contractLabel = {
  move_options: 'MOVE',
  interest_rate_swaps: 'IRS',
  futures: 'Futures',
  spreads: 'Spread',
  perpetual_futures: 'Perpetual',
  put_options: 'Put',
  call_options: 'Call',
  turbo_call_options: 'Turbo Call',
  turbo_put_options: 'Turbo Put',
  spot: 'Spot',
};

// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
export const settling_type = symbol => {
  switch (symbol.split('-')[2]) {
    case 'D':
      return 'Daily';

    case 'W':
      return 'Weekly';

    case 'M':
      return 'Monthly';

    default:
      return '';
  }
};

export const shareCardContractTypes = {
  interest_rate_swaps: 'Interest Rate Swap',
  move_options: 'MOVE',
  put_options: 'Put Options',
  call_options: 'Call Options',
  turbo_put_options: 'Turbo Put Options',
  turbo_call_options: 'Turbo Call Options',
  perpetual_futures: 'Perpetual Futures',
  futures: 'Futures',
  spreads: 'Spreads',
  spot: 'Spot',
};

export const optionsContractType = [
  { id: 0, value: 'All', label: 'All' },
  { id: 1, value: 'move_options', label: 'MOVE' },
  { id: 2, value: 'call_options', label: 'Call' },
  { id: 3, value: 'put_options', label: 'Put' },
  // { id: 4, value: 'turbo_call_options', label: 'Turbo Call' },
  // { id: 5, value: 'turbo_put_options', label: 'Turbo Put' },
];

export const shareCardGetLink = `${API_URL}/v2/share_card`;

export const SHOW_ACCOUNTS_MOBILE_HEADER = [
  routes.account.my_account,
  routes.resources,
  routes.account.security.default,
  routes.account.security.account_activity,
  routes.account.profile,
  routes.account.identityVerification,
  routes.account.preferences,
  routes.account.security.address_management,
  routes.account.security.change_password,
  routes.account.security.twofa,
  routes.account.manageapikeys,
  routes.account.withdrawal_bank_history,
  routes.account.balances,
  routes.account.withdrawal,
  routes.account.withdrawalBank,
  routes.account.withdrawal_history,
  routes.account.deposit,
  routes.account.trackDeposit,
  routes.account.deposit_history,
  routes.account.buy_crypto,
  routes.account.fiat_deposit_history,
  routes.account.transactionLog.default,
  routes.account.transactionLog.asset_history,
  routes.account.transactionLog.order_history,
  routes.account.transactionLog.trade_history,
  routes.account.accountAnalytics.default,
  routes.account.transactionLog.transfer_history,
  routes.account.subAccounts,
  routes.convert,
  routes.account.portfolioMargin,
  routes.account.bankDetails,
  routes.account.signalTrading,
  routes.account.signalTradingInfo,
  routes.account.activeWebhooks,
];

export const SHOW_2FA_RESET_POP_UP = [
  ...SHOW_PRIMARY_HEADER,
  ...SHOW_PRIMARY_HEADER_MOBILE,
  ...SHOW_ACCOUNTS_MOBILE_HEADER,
];

export const pageHeaderByPathName = {
  '/trollbox': 'Trollbox',
  '/account/my_account': 'My Accounts',
  '/account/security': 'Security',
  '/account/notifications': 'News & Notifications',
  '/resources': 'Resources',
  '/account/preferences': 'Preferences',
  '/account/manageapikeys': 'API Keys',
  '/account/profile': 'Profile',
  '/account/security/account_activity': 'Account Activity',
  '/account/security/change_password': 'Change Password',
  '/account/security/address_management': 'Address Management',
  '/account/security/settings_2fa': 'Two-Factor Authentication',
  '/account/balances': 'Balances',
  '/account/withdrawal/XRP': 'Withdraw',
  '/account/withdrawal/DETO': 'Withdraw',
  '/account/withdrawal/USDT': 'Withdraw',
  '/account/withdrawal/BTC': 'Withdraw',
  '/account/withdrawal/ETH': 'Withdraw',
  '/account/withdrawal/BNS': 'Withdraw',
  '/account/withdrawal/SOL': 'Withdraw',
  '/account/withdrawal/USDC': 'Withdraw',
  '/account/withdrawal_history': 'Withdrawal History',
  '/account/deposit/XRP': 'Deposit',
  '/account/deposit/DETO': 'Deposit',
  '/account/deposit/USDT': 'Deposit',
  '/account/deposit/BTC': 'Deposit',
  '/account/deposit/ETH': 'Deposit',
  '/account/deposit/BNS': 'Deposit',
  '/account/deposit/SOL': 'Deposit',
  '/account/deposit/USDC': 'Deposit',
  '/account/deposit_history': 'Deposit History',
  '/account/buy_crypto': 'Buy Crypto',
  '/account/fiat_deposit_history': 'Fiat Deposit History',
  '/account/transaction_logs/asset_history': 'Transaction Logs',
  '/account/transaction_logs/order_history': 'Transaction Logs',
  '/account/transaction_logs/trade_history': 'Transaction Logs',
};

export const KYC_BLOG =
  'https://www.delta.exchange/blog/support/what-are-delta-exchanges-kyc-requirements';

export const KYC_REQUIRED_COINS = ['DETO'];

export const certificatesList = [
  { name: 'Selfie', slug: 'face_match' },
  { name: 'Proof of ID', slug: 'id_document' },
  { name: 'Proof of Address', slug: 'address_proof' },
  { name: 'AML Checks', slug: 'aml_monitoring' },
];

export const NUMBER_TYPES = {
  decimal: 'decimal',
  natural: 'natural',
};

export const REGEX_PATTERN_NAME = /^((?:\p{L}){2,30}\s?){1,4}$/u;
export const REGEX_PATTERN_EMAIL =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const OPTIONS_CHAIN_VISUALISATIONS = {
  TABLE: 'table',
  CHART: 'chart',
};

const STRIKE_PRICE_TICK_SIZE = {
  BTC: 1,
  ETH: 1,
  LTC: 1,
  BNB: 1,
  BCH: 1,
  LINK: 0.1,
  XRP: 0.01,
  ADA: 0.01,
  SOL: 1,
  AVAX: 1,
  MATIC: 0.01,
};

const UNDERLYING_STRIKE_PRECISION = {
  BTC: 0,
  ETH: 0,
  SOL: 0,
  BNB: 0,
  XRP: 2,
  LINK: 1,
};

const GAMMA_TICK_SIZE = {
  BTC: 0.000001,
  ETH: 0.00001,
  LTC: 0.001,
  BNB: 0.0001,
  BCH: 0.001,
  LINK: 0.001,
  XRP: 0.01,
  ADA: 0.01,
  SOL: 0.0001,
  AVAX: 0.0001,
  MATIC: 0.01,
};

const VEGA_TICK_SIZE = {
  BTC: 0.01,
  ETH: 0.01,
  SOL: 0.0001,
  AVAX: 0.0001,
  BNB: 0.001,
  LINK: 0.001,
  MATIC: 0.00001,
  XRP: 0.00001,
};

const THETA_TICK_SIZE = {
  BTC: 0.01,
  ETH: 0.01,
  SOL: 0.0001,
  AVAX: 0.0001,
  MATIC: 0.00001,
  LINK: 0.001,
  BNB: 0.001,
  XRP: 0.00001,
};

// [DEIFE-271]: We should have the following precision for showing greeks
const GREEK_PRECISION = {
  GAMMA: 5,
  VEGA: 2,
  THETA: 2,
  DELTA: 2,
};

export const WithdrawalEnabledCoins = [
  'BTC',
  'USDT',
  'DETO',
  'ETH',
  'XRP',
  'BNS',
  'SOL',
  'USDC',
];

export const holdingsContractTypeList = [
  { label: 'All', id: 0, value: 'all' },
  { label: 'Futures', id: 1, value: 'futures' },
  { label: 'Spot', id: 2, value: 'spot' },
  { label: 'Options', id: 3, value: 'options' },
  { label: 'Spreads', id: 4, value: 'spreads' },
  { label: 'IRS', id: 5, value: 'interest_rate_swaps' },
];

export const OPTIONS_CHAIN_DEFAULT_COIN = 'BTC';

const OPTIONS_COLUMNS = [
  {
    key: 'Pos',
    enabled: false,
  },
  {
    key: 'Delta',
    enabled: true,
  },
  {
    key: 'OI',
    enabled: true,
  },
  {
    key: 'changeInOi',
    enabled: true,
  },
  {
    key: 'Bid/AskQty',
    enabled: true,
  },
  {
    key: 'MarkPrice',
    enabled: false,
  },
  {
    key: 'LastPrice',
    enabled: false,
  },
  {
    key: '24hChange',
    enabled: false,
  },
  {
    key: 'Volume',
    enabled: false,
  },
];

const OPTIONS_TRADE_COLUMNS = [
  {
    key: 'Pos',
    enabled: false,
  },
  {
    key: 'Delta',
    enabled: false,
  },
  {
    key: 'OI',
    enabled: true,
  },
  {
    key: 'changeInOi',
    enabled: true,
  },
  {
    key: 'Bid/AskQty',
    enabled: true,
  },
  {
    key: 'MarkPrice',
    enabled: false,
  },
  {
    key: 'Volume',
    enabled: false,
  },
  // {
  //   key: 'BidQty',
  //   text: 'Bid Qty / Units',
  //   enabled: false,
  // },
];

export const SHOW_CONNECTION_STRIP = [
  routes.irs.trade,
  routes.futures.trade,
  routes.options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.options_chain.trade,
  routes.spot.trade,
  routes.move_options.trade,
  routes.robo_strategies.markets,
  routes.futures.markets,
  routes.irs.markets,
  routes.move_options.markets,
  routes.turbo.markets,
  routes.spreads.markets,
  routes.spot.markets,
  routes.favorites.markets,
  routes.options_chain.markets,
  routes.tradeHistory,
  Object.values(routes.account),
];

export const SHOW_CONNECTION_STRIP_MOBILE = [
  routes.irs.trade,
  routes.futures.trade,
  routes.options.trade,
  routes.turbo.trade,
  routes.spreads.trade,
  routes.options_chain.trade,
  routes.spot.trade,
  routes.move_options.trade,
  // routes.account.balances,
];

export const QUANTITY_CURRENCY_TYPE = {
  MARGINED_CURRENCY: 'margined_currency',
  UNDERLYING_CURRENCY: 'underlying_currency',
};

export const IS_RN_WEBVIEW =
  typeof window !== 'undefined' && (window?.isRNWebView ?? false);

const APPLE_DEVICES = ['iPhone', 'Macintosh', 'iPad'];

// eslint-disable-next-line no-underscore-dangle
export const IS_RN_WEBVIEW_ANDROID = window?._isRNAndroid;

// eslint-disable-next-line no-underscore-dangle
export const IS_RN_WEBVIEW_IOS = window?._isRNiOS;

export const NOTIFICATION_TYPES = {
  ANNOUNCEMENT: 'announcement',
  SYSTEM: 'system',
};

export const NOTIFICATION_KEY = 'last_read_notification';

export const API_BLOCKER_LOG_TIMER = 10000;

export const YOUTUBE_LINK = 'https://www.youtube.com/@deltaexchange';

export const WITHDRAWAL_YOUTUBE_VIDEO =
  'https://www.youtube.com/embed/iAOac7E20U8?rel=0&autoplay=1';

export const DEPOSIT_YOUTUBE_VIDEO =
  'https://www.youtube.com/embed/r9G9sqW-MMc?rel=0&autoplay=1';

export const OPTIONS_YOUTUBE_VIDEO =
  'https://www.youtube.com/embed/wMBmQcvyUTU?rel=0&autoplay=1';

export const SPREADS_YOUTUBE_VIDEO =
  'https://www.youtube.com/embed/tuiqWxSIeso?si=12G4o-P8kRYBcb3p';

export const ADD_FUNDS_YOUTUBE_VIDEO =
  'https://www.youtube.com/embed/XMsmvygsadA?si=yQ5_qm-6P2VGLk8j';

export const ADD_FUNDS_VIA_UPI_YOUTUBE_VIDEO =
  'https://www.youtube.com/embed/e5fSU_eDa90?si=hpz8n0icg120nqCJ';

export const MARKET_CARDS_URL = process.env.REACT_APP_MARKET_CARDS_URL as string;
export const GENERAL_GITHUB_DETAILS_URL =
  process.env.REACT_APP_GENERAL_GITHUB_DETAILS_URL;

export const COUNTRIES_WITH_SINGLE_VERIFICATION_FLOW = [
  'India',
  'Canada (except Ontario & Quebec)',
];

export const OTP_FIELD_LENGTH = 6;

export const PORTFOLIO_MARGIN_MIN_BALANCE_TO_ENABLE = 1000; // 1000 USDT

export const PORTFOLIO_MARGIN_SETTLING_ASSET = IS_INDIAN_EXCHANGE ? 'USD' : 'USDT';

export const PORTFOLIO_MARGIN_GUIDE_LINK =
  'https://guides.delta.exchange/delta-exchange-user-guide/trading-guide/margin-explainer/portfolio-margin';
export const BASKET_ORDER_GUIDE_LINK =
  'https://guides.delta.exchange/delta-exchange-user-guide/trading-guide/order-types/basket-orders';

export const ORDERBOOK_SIZE_LEARN_MORE_LINK =
  'https://www.delta.exchange/blog/support/sizes-in-underlying-currency/';

export const OPTIONS_COMBOS_SPREAD_LINK =
  'https://www.delta.exchange/de/blog/options-spreads';

const DISABLE_CLOUD_SYNC_BLOG_LINK =
  'https://www.delta.exchange/blog/disable-google-authenticator-cloud-sync-feature-for-better-security';

const AUTHENTICATOR_APPS_BLOG_LINK = IS_INDIAN_EXCHANGE
  ? `${INDIA_FRESHDESK_URL}/support/solutions/articles/80001132536-authenticator-apps-for-2fa`
  : `${SITE_ROOT}/blog/support/authenticator-apps-for-2fa`;

export const MARGIN_TYPES = {
  ISOLATED: 'isolated',
  PORTFOLIO: 'portfolio',
  CROSS: 'cross',
};

export const INDIAN_EXCHANGE_CONTRACT_TYPES = [
  'call_options',
  'put_options',
  'futures',
  'perpetual_futures',
];

export const BASKET_ORDERS_SCREEN_VIEW = {
  DEFAULT: 'default',
  PRODUCTS_VIEW: 'products-view',
  ORDERS_VIEW: 'order-view',
  ANALYZE_VIEW: 'analyze-view',
};

export const DELTA_SUPPORT_MAIL = 'support@delta.exchange';
const SUPPORT_MAIL_INDIAN_EXCHANGE = 'support@india.delta.exchange';
const SUPPORT_MAIL_INSTITUTION_KYC = 'insti-support@delta.exchange';
const VERIFICATION_DOCUMENT_SHARING_ADDRESS = 'compliance.delta@exceliumtech.com';

export const TRANSAK_API_KEY = process.env.REACT_APP_TRANSAK_API_KEY;
export const TRANSAK_ENV = process.env.REACT_APP_TRANSAK_ENV;

export const ADMIN_USER_ID = [
  'AdminK',
  // 'Admin_X',
  'AdminY',
  'Admin1',
  'AdminD',
  'AdminA',
  'AdminM',
  // 'Scavanger',
  'Admin_R',
  'Admin-7',
  'Diliprj14',
  'damp-shadow-4356',
  'Admin-s',
];

const optionAssetSortOrder = {
  BTC: 1,
  ETH: 2,
  SOL: 3,
  BNB: 4,
  XRP: 5,
  LINK: 6,
  AVAX: 7,
  MATIC: 8,
};

export const MIN_USER_AGE = 18;

export const ORDER_SIDE = {
  BUY: 'buy',
  SELL: 'sell',
};

export const NO_OF_SUBACCOUNTS = 2;

const INDIAN_EXCHANGE_APK_URL =
  'https://firebasestorage.googleapis.com/v0/b/beta-delta-exchange-prod.appspot.com/o/delta-exchange-india%2F33.apk?alt=media&token=d46f1745-9576-41ff-97ef-497e513000c4';

const GLOBAL_APK_URL =
  'https://firebasestorage.googleapis.com/v0/b/deltaexchange-production.appspot.com/o/72.apk?alt=media&token=c2b16b17-3848-4c79-96b3-9280ccca802e';

export const ANDROID_APK_URL = IS_INDIAN_EXCHANGE
  ? INDIAN_EXCHANGE_APK_URL
  : GLOBAL_APK_URL;

export const APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL;

const DELTA_INDIA_APP_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.deltaexchangeindia';

export const DELTA_GLOBAL_APP_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=exchange.delta';

export const PLAY_STORE_URL = IS_INDIAN_EXCHANGE
  ? DELTA_INDIA_APP_PLAY_STORE_URL
  : DELTA_GLOBAL_APP_PLAY_STORE_URL;

export const NON_KYC_CHECK_DATE = new Date('2022-04-26T00:00:00.000Z');
export const NON_KYC_EXPIRY_DATE = new Date('2022-07-22T00:00:00Z');
export const OFFER_TYPES_MAPPING = /** @type {const} */ {
  NEW_100: 'deposit_bonus',
  NEW_500: 'deposit_bonus',
  CRYPTO_MANIA: 'new_welcome_bonus',
  MEGA_30K_OFFER: 'deposit_mega_bonus',
  DEPOSIT_MEGA_BONUS: 'deposit_mega_bonus',
  WEEKLY_DELIGHT: 'weekly_delight',
  MONTHLY_MILLIONAIRE: 'monthly_millionaire',
  SPRINT_TO_500: 'sprint_to_500',
  ZERO_FEE_OPTIONS_BUYING: 'zero_fee_options_buying',
  TFC_BONUS: 'tfc_bonus',
  OPTIONS_CASHBACK: 'options_cashback',
  DEPOSIT_FORTUNE: 'deposit_fortune',
  DETO_MSP: 'deto_msp',
  ZERO_WITHDRAWAL_FEE: 'zero_withdrawal_fee',
  RAF_OPTIONS_TRADING: 'raf_options_trading',
};
/** @type {string[]}  */
export const OFFERS_NOT_TO_BE_CLAIMED_FOR_NEW_100 = [
  OFFER_TYPES_MAPPING.DEPOSIT_MEGA_BONUS,
  OFFER_TYPES_MAPPING.NEW_100,
];
export const KYC_RESTRICTED_BLOG =
  'https://www.delta.exchange/blog/support/what-happens-when-your-trading-access-is-restricted';

/**
 * @deprecated use STORAGE_KEYS from enum file
 */
export const LOCAL_STORAGE_KEYS = {
  MOENGAGE_REGISTERED: 'MOENGAGE_REGISTERED',
  TRACKIER_CLICK_ID: 'tr_click_id',
  MOE_DATA: 'MOE_DATA',
  DELTA_THEME: 'delta_theme',
  ORDER_PLACING_BRACKET_ORDER_VISIBILITY: 'order_placing_bracket_order_visibility',
};

export const TWENTY_FOUR_HOURS = 86400;
export const FIFTEEN_MINUTES = 900;
export const ONE_HOUR = 3600;
export const TIMER_DIVIDER = 1000;
const UNDER_MAINTENANCE_URL_INDIA =
  'https://www.delta.exchange/support/solutions/articles/80001132383-what-to-expect-during-the-maintenance-';

const UNDER_MAINTENANCE_URL_GLOBAL =
  'https://global.delta.exchange/support/solutions/articles/80001014518-what-to-expect-during-maintenance-';

export const UNDER_MAINTENANCE_URL = IS_INDIAN_EXCHANGE
  ? UNDER_MAINTENANCE_URL_INDIA
  : UNDER_MAINTENANCE_URL_GLOBAL;

export const TRADING_ALLOWED_INDIAN_NON_VERIFIED_USERS = [];
export const MOE_NOTIFICATION_TIME_HOUR = 336;

export const MAX_REMITANO_BUY_LIMIT_USD = 5000;

export const AVAILABLE_THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  INDIAN_LIGHT: 'indian_light',
  INDIAN_DARK: 'indian_dark',
} as const;

export const ROUND_DOWN = 1;
export const ROUND_UP = 0;

export const SIMPLEX_PUBLIC_KEY = process.env.REACT_APP_SIMPLEX_PUBLIC_KEY;
export const SIMPLEX_WIDGET_URL = process.env.REACT_APP_SIMPLEX_WIDGET_URL;
export const ONMETA_SDK_URL = process.env.REACT_APP_ONMETA_SDK_URL;
export const ONMETA_KEY = process.env.REACT_APP_ONMETA_KEY;

export const GOOGLE_FORM_SURVEY =
  'https://docs.google.com/forms/d/e/1FAIpQLSdL4YpJKCXJGTVtlNmqK6Vr-epn-tGNHAve2TRhdzupI4_sRA/viewform';

// Alpyne
export const ALPYNE_IFRAME_URL = process.env.REACT_APP_ALPYNE_IFRAME_URL;
export const ALPYNE_VENDOR_CODE = process.env.REACT_APP_ALPYNE_VENDOR_CODE;
export const ALPYNE_API_SECRET = process.env.REACT_APP_ALPYNE_API_SECRET;
export const ALPYNE_SUPPORT_LINK = 'https://alpyne.tech/support';
export const ALPYNE_TERMS_OF_USE_LINK = 'http://bit.ly/3EROJMZ';
export const ALPYNE_PRIVACY_POLICY_LINK = 'http://bit.ly/3goCC0m';

const TICK_FALLBACK = {
  BTC: '0.001',
  ETH: '0.01',
  SOL: '0.001',
  BNB: '0.001',
  XRP: '0.00001',
  LINK: '0.001',
};

const CONTRACT_VALUE_FALLBACK = {
  BTC: '0.001',
  ETH: '0.01',
  SOL: '1',
  BNB: '1',
  XRP: '1',
  LINK: '1',
};

const TESTNET_NEW_GLOBAL_URL = 'https://demo-global.delta.exchange/app';
const TESTNET_NEW_INDIA_URL = 'https://demo.delta.exchange/app';

const TESTNET_URL = IS_INDIAN_EXCHANGE ? TESTNET_NEW_INDIA_URL : TESTNET_NEW_GLOBAL_URL;

const NUMBER_OF_SPOT_PAIRS = 6;

const KYC_LEVEL_ONE_DISCONTINUATION_SUPPORT_LINK =
  'https://www.delta.exchange/blog/support/mobile-number-verification-level-discontinuation';

const KYC_UPGRADE_TRADING_BLOCKED_DATE = new Date('2023-03-31T00:00:00.000Z');
const ERC20_DISABLED_TIME = new Date('2023-04-12T22:00:00.000Z');

const SORT_STATES = /**  @type {const} */ {
  ascending: 'ascending',
  descending: 'descending',
};

const ONMETA_TERMS_PAGE_URL = 'https://onmeta.in/terms-endusers';
const MUDREX_TERMS_PAGE_URL = 'https://mudrex.com/terms';

const ONMETA_SUPPORT_MAIL = 'support@onmeta.in';

const OPTION_LEADERBOARD_TOTAL_REWARDS = '$1000';
/**
 * User which register before this date don't get verfication strip
 */
const NEW_USER_REGISTRATION_DATE = '2023-03-15T00:00:00Z';

/**
 * Password and 2fa reset required if updation happend befor this date.
 */
const SECURITY_UPDATION_CHECK_DATE = '2023-03-24T12:00:00.000Z';

const KYC_VENDORS = {
  idfy: 'idfy',
  blockpass: 'blockpass',
  hyperverge: 'hyperverge',
  shuftipro: 'shuftipro',
} as const;

/**
 * @see [Hyperverge Web SDK](https://documentation.hyperverge.co/sdks/web/)
 */
const HYPERVERGE_SDK_URL =
  'https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.11.0/src/sdk.min.js';

const COUNTRY_ALLOWED_FOR_LEVEL2 = [
  'united arab emirates',
  'france',
  'germany',
  'netherlands',
  'australia',
  'saudi arabia',
  'italy',
  'spain',
  'qatar',
  'turkey',
  'japan',
  'kuwait',
  'switzerland',
  'czechia',
  'oman',
  'hong kong',
  'belgium',
  'greece',
  'portugal',
  'bahrain',
  'ireland',
  'sweden',
  'malaysia',
  'austria',
  'new zealand',
  'croatia',
  'south korea',
  'luxembourg',
  'finland',
  'denmark',
  'norway',
];

const CUSTODIAN_PENDING_STATES = [
  CUSTODIAN_STATUS.INITIATED,
  CUSTODIAN_STATUS.PENDING,
  CUSTODIAN_STATUS.PROCESSING,
  CUSTODIAN_STATUS.REFUND_INITIATED,
  CUSTODIAN_STATUS.FIAT_FAILED,
  CUSTODIAN_STATUS.CREATED,
];

const CUSTODIAN_COMPLETED_STATES = [
  CUSTODIAN_STATUS.REFUNDED,
  CUSTODIAN_STATUS.COMPLETED,
  CUSTODIAN_STATUS.DECLINED,
];
const ERROR_CODES = {
  INVALID_CAPTCHA: 'invalid_captcha',
  LOGIN_BLOCKED: 'login_blocked',
  LOGIN_NOT_ACTIVE: 'login_not_active',
  OAUTH_NOT_ACTIVE: 'oauth_not_active',
  USER_NOT_FOUND: 'user_not_found',
  INVALID_PASSWORD: 'invalid_password',
  PASSWORD_NOT_SET: 'password_not_set',
  API_EMPTY_RESPONSE: 'api_empty_response',
  FAILED_TO_FETCH: 'Failed to fetch',
  BCRYPT_HASH_LIMIT: 'bcrypt_hash_limit',
  BAD_SCHEMA: 'bad_schema',
  GET_AVAILABLE_BALANCE_FAILED: 'get_available_balance_failed',
  FIAT_WITHDRAWAL_ASSET_EMPTY: 'fiat_withdrawal_asset_empty',
  MISSING_CUSTODIAN_WITHDRAWAL_ASSET_DETAIL: 'missing_custodian_withdrawal_asset_detail',
  INVALID_WITHDRAWAL_AMOUNT: 'invalid_withdrawal_amount',
  NO_SUCH_CUSTODIAN: 'no_such_custodian',
  VERIFY_WITHDRAWAL_BLOCKED: 'verify_withdrawal_blocked',
  INVALID_MFA_CODE: 'invalid_mfa_code',
  INVALID_PRE_2FA_TOKEN: 'invalid_pre_2fa_token',
  INVALID_NEW_MFA_CODE: 'invalid_new_mfa_code',
  MFA_CHECK_BLOCKED: 'mfa_check_blocked',
  INVALID_LOGIN_OTP: 'invalid_login_otp',
  INVALID_OTP: 'invalid_otp',
  INVALID_EMAIL_CODE: 'invalid_email_code',
  OTP_DOES_NOT_MATCH: 'otp_does_not_match',
  OTP_EXPIRED: 'otp_expired',
  VERIFY_WITHDRAWAL_EMAIL_OTP_BLOCKED: 'verify_withdrawal_email_otp_blocked',
  ALL_ATTEMPTS_USED: 'all_attempts_used',
  ACCOUNT_NOT_VERIFIED: 'account_not_verified',
  MEMO_REQUIRED: 'memo_required',
  WITHDRAWAL_DISABLED: 'withdrawal_disabled',
  SUB_ACCOUNT_WITHDRAWAL_DISABLED: 'sub_account_withdrawal_disabled',
  VALIDATION_ERROR: 'validation_error',
  WITHDRAWALS_DISABLED_FOR_ASSET_NETWORK: 'withdrawals_disabled_for_asset_network',
  WITHDRAWALS_BLOCKED_FOR_USER: 'withdrawals_blocked_for_user',
  NEGATIVE_WITHDRAWAL_AMOUNT: 'negative_withdrawal_amount',
  WITHDRAWAL_LESS_THAN_MIN: 'withdrawal_less_than_min',
  PENDING_WITHDRAWAL_REQUEST: 'pending_withdrawal_request',
  KYC_WITHDRAWAL_LIMIT_EXCEEDED: 'kyc_withdrawal_limit_exceeded',
  INSUFFICIENT_BALANCE: 'insufficient_balance',
  FORFEIT_AND_WITHDRAW: 'forfeit_and_withdraw',
  PENDING_VERIFICATION: 'pending_verification',
  PORTFOLIO_RISK_LIMITS_BREACHED: 'portfolio_risk_limits_breached',
  TRADING_BLOCKED: 'trading_blocked',
  DELETED_ACCOUNT_EXISTS: 'deleted_account_exists',
  RATE_LIMIT_EXCEEDED: 'rate_limit_exceeded',
};

export const LOGIN_ERROR_CODES = [
  ERROR_CODES.USER_NOT_FOUND,
  ERROR_CODES.PENDING_VERIFICATION,
  ERROR_CODES.INVALID_PASSWORD,
  ERROR_CODES.BCRYPT_HASH_LIMIT,
  ERROR_CODES.LOGIN_BLOCKED,
  ERROR_CODES.LOGIN_NOT_ACTIVE,
  ERROR_CODES.PASSWORD_NOT_SET,
  ERROR_CODES.DELETED_ACCOUNT_EXISTS,
];

export const SIGNUP_ERROR_CODES = [
  'already_registered',
  'already_registered_verification_pending',
  'invalid_email_format',
  'blocked_domain',
  ERROR_CODES.BCRYPT_HASH_LIMIT,
  'invalid_country',
  ERROR_CODES.DELETED_ACCOUNT_EXISTS,
];

const SINGAPORE_TRADING_REVOKED_DATE = new Date('2023-07-17T00:00:00Z');
const LANDING_PAGE_DECODE_DELTA_CASH_BLOG = `${LANDING_PAGE_URL}/blog/support/decode-delta-cash`;

const ASSET_SYMBOL = IS_INDIAN_EXCHANGE ? 'USD' : 'USDT';

const SPOT_PRICE = {
  INR: 80,
  [ASSET_SYMBOL]: 1,
};

const LEADERBOARD_PRODUCT_TYPE = {
  OPTIONS: 'options',
  FUTURES: 'futures',
  OPTIONS_FUTURES: 'options_futures',
} as const;

const HOMEPAGE_ROUTE = '/app';
const DOCS_URL = IS_INDIAN_EXCHANGE
  ? 'https://docs.delta.exchange'
  : 'https://docs-global.delta.exchange';

const FRESHDESK_URL = IS_INDIAN_EXCHANGE ? INDIA_FRESHDESK_URL : GLOBAL_FRESHDESK_URL;
const TELEGRAM_URL = 'https://t.me';
const PLATFORM_STATUS_URL_GLOBAL_EXCHANGE = 'https://status.global.delta.exchange';
const PLATFORM_STATUS_URL_INDIAN_EXCHANGE = 'https://status.delta.exchange';
const PLATFORM_STATUS_URL = IS_INDIAN_EXCHANGE
  ? PLATFORM_STATUS_URL_INDIAN_EXCHANGE
  : PLATFORM_STATUS_URL_GLOBAL_EXCHANGE;

const SUPPORT_HOMEPAGE_URL = `${SITE_ROOT}/support/home`;
const SUPPORT_TICKET_URL = process.env.REACT_APP_SUPPORT_TICKET_LINK;
const SUPPORT_CENTER_LINK = process.env.REACT_APP_SUPPORT_CENTER_LINK;

const TOP_SECTION_CARD_TYPES = {
  MARKETS_OVERVIEW_CARDS: 'markets_overview_cards',
  OFFERS_AND_PROMOTION_CARDS: 'offers_and_promotion_cards',
};

const APPLE_OAUTH_CLIENT_ID = process.env.REACT_APP_APPLE_OAUTH_CLIENT_ID;
const NO_DELTA_CASH_PERCENT_GLOBAL = 25;
const NO_DELTA_CASH_PERCENT_INDIA = 15;
const NEOFI_CONTACT_LINK = 'https://neofi.co/contact';
const DETO_PRECISION = 2;

const COUNTRIES_WITH_NON_VERIFICATION = ['Singapore'];

const CALENDLY_API_TRADING_LINK = 'https://calendly.com/ayush-aggarwal-delta/30min';

const SCALPING_BLOG_URL = `${INDIA_FRESHDESK_URL}/support/solutions/articles/80001149608-btc-futures-scalpers-offer`;
const RECOVER_ACCOUNT_BLOG_URL = `${INDIA_FRESHDESK_URL}/support/solutions/articles/80001157636-reactivating-your-delta-exchange-account-quick-and-easy-steps`;

const POSITIONS_MOBILE_VIEW_MODES = {
  STD_VIEW: 'STD_VIEW',
  RISK_VIEW: 'RISK_VEW',
};

const OPTION_COMBOS_STRIKE_GAPS = [200, 400, 500, 600, 800, 1000, 1200, 1500];

const DELTA_TERMS_OF_USE_URL = 'https://www.delta.exchange/terms-of-use';
const FIU_LINK = 'https://fiuindia.gov.in/';
const FIU_ID = 'VA00041101';

const OPTIONS_ANALYTICS_COMMON_DATE_MAPPING_FORMAT = TIME_FORMATS.DD_MM_YY;

const MOENGAGE_APP_ID = process.env.REACT_APP_MOENGAGE_APP_ID;

/**
 * @description either '0' or '1'
 * describes whether the data should go to dev/test environment or production
 * 0=PROD , 1 = dev/test
 */
const MOENGAGE_DEBUG_ENABLED = process.env.REACT_APP_MOENGAGE_DEBUG;
const BANK_ACCOUNT_DEFAULT_LABEL = 'Main Account';

const PAYMENT_METHODS = {
  IMPS: 'IMPS',
  UPI: 'UPI',
  NEFT: 'NEFT',
  RTGS: 'RTGS',
} as const;

const DELTA_EXCHANGE_YOUTUBE_CHANNEL =
  'https://www.youtube.com/channel/UCaw-1cUd74wvEatvZna0TzQ';

const IOS_APP_ALTERNATIVE_YT_URL =
  'https://www.youtube.com/watch?v=M2TbBd4vdgE&list=PLZYaByIzWLVwtMLuOqDBoUCr6s_JDEfSv&index=6';

const DELTA_INDIA_LANDING_SITE_ROOT = process.env.REACT_APP_DELTA_INDIA_LANDING_SITE_ROOT;

const FEATURE_FLAGS = {
  SHOW_DELTA_INDIA_SIGNUP_MESSAGE:
    process.env.REACT_APP_FEATURE_FLAG_SHOW_DELTA_INDIA_SIGNUP_MESSAGE === 'true',
};

const API_KEYS_TUTORIAL_PAGE_LINK =
  'https://www.delta.exchange/blog/guide-to-api-trading-with-delta-india?category=all';

const QR_CODE_ISSUER = process.env.REACT_APP_QR_CODE_ISSUER;

const BOARD_RESOLUTION_DOCUMENT_LINK =
  'https://static.delta.exchange/web/docs/board-resolution.pdf';
const SOURCE_OF_FUNDS_DOCUMENTS_LINK_DELTA_INDIA =
  'https://static.delta.exchange/web/docs/delta-exchange-india-declaration-of-source-of-funds.pdf';

const SOURCE_OF_FUNDS_HUF_DOCUMENT_LINK =
  'https://static.delta.exchange/web/docs/delta-exchange-india-declaration-of-source-of-funds-for-individual-HUF.pdf';
const AUTHORISATION_LETTER_DOCUMENT_LINK =
  'https://static.delta.exchange/web/docs/authorisation-letter-non-individual.pdf';

const DELTA_INDIA_SIGNUP_URL = `${DELTA_INDIA_LANDING_SITE_ROOT}/app/signup`;

const DOWNLOAD_APP_BANNER_IMAGE_URL = process.env.REACT_APP_DOWNLOAD_APP_BANNER_IMAGE_URL;

const ONMETA_SUPPORT_LINK = 'https://platform.onmeta.in/support';
const ONMETA_SUPPORT_EMAIL = 'support@onmeta.in';

const FRESHDESK_DECODE_DELTA_CASH_BLOG = `${INDIA_FRESHDESK_URL}/support/solutions/articles/80001141198-decode-delta-cash`;

const FRESHDESK_2FA_FAQS = IS_INDIAN_EXCHANGE
  ? `${INDIA_FRESHDESK_URL}/support/solutions/folders/80000720059`
  : 'https://www.delta.exchange/blog/support/faqs-on-2fa';

const FAIR_PRICE_USER_GUIDE_LINK = `${SITE_ROOT}/user-guide/docs/trading-guide/fair-price/`;

const OPTIONS_MARK_PRICE_GUIDE_LINK = `${GUIDES_SITE_ROOT}/derivatives-guide/options-guide#options-mark-price`;

// static-resources is the cache key of workbox found in sw.js file
const STATIC_RESOURCES_CACHE_KEY = `static-resources`;

// this is the cache key of charts_library found in sw.js file
const PRE_CACHE_KEY = `sw-precache-v3-delta_cache_v1-${window.location.origin}${
  IS_CI ? '/' : '/app/'
}`;

const FILES_TO_TRACK_DATA = [
  // !! Local main file name is bundle.js
  // {
  //   trackingName: 'bundle.js',
  //   regex: /.*\/static\/js\/bundle.*.js$/,
  //   cacheKey: STATIC_RESOURCES_CACHE_KEY,
  // },
  {
    trackingName: 'main.js',
    regex: /.*\/static\/js\/main.*.js$/,
    cacheKey: STATIC_RESOURCES_CACHE_KEY,
  },
  {
    trackingName: 'main.css',
    regex: /.*\/static\/css\/main.*.css$/,
    cacheKey: STATIC_RESOURCES_CACHE_KEY,
  },
  {
    trackingName: 'charting_library',
    regex: /^.*charting_library\.js$/,
    cacheKey: PRE_CACHE_KEY,
  },
];

const TAX_INFO_LINK =
  'https://deltaexchangeindia.freshdesk.com/support/solutions/folders/80000720077';

const TOTAL_EQUITY_DECIMAL = 2;

const DELTA_CASH_DISCOUNT_PERCENT = IS_INDIAN_EXCHANGE ? 15 : 25;

const SOURCE_OF_FUNDS_DOCUMENT_LINK = IS_INDIAN_EXCHANGE
  ? 'https://static.delta.exchange/web/docs/delta-exchange-india-declaration-of-source-of-funds.pdf'
  : 'https://static.delta.exchange/web/docs/delta-exchange-declaration-of-source-of-funds.pdf';

const IOS_APP_INDIA_URL =
  'https://apps.apple.com/in/app/delta-exchange-india/id6478332344';
const DELTA_INDIA_RELEASE_YEAR = '2024';

const CAREERS_LINK = 'https://careers.delta.exchange';

const DELTA_INDIA_MORE_DEPOSIT_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSerniZxr6YFp_wolyeLAIMyVi0B_qV77KBAijL-V_2cQfz22Q/viewform';

export const urlContractTypeRedirection = (contractType: string) => {
  switch (contractType) {
    case 'futures':
    case 'perpetual_futures':
      return 'futures';
    case 'call_options':
    case 'put_options':
    case 'turbo_call_options':
    case 'turbo_put_options':
      return 'options';
    case 'move_options':
    case 'options_combos':
    case 'spot':
      return 'spot';
    default:
      return contractType;
  }
};

const DOLLAR_SIGN_FOR_INDIAN_EXCHANGE = IS_INDIAN_EXCHANGE ? '$' : '';

const UNIT_OF_ASSETS_QUANTITY = IS_INDIAN_EXCHANGE ? 'Lot' : 'Cont';

const DELTA_EXCHANGE_GLOBAL_URL = 'https://global.delta.exchange';

const OAUTH_CANCEL_CODE = '12501';
const ALGO_API_PAGE = isTestnet
  ? `${TESTNET_URL}/account/manageapikeys`
  : `${LANDING_PAGE_URL}/algo/delta-exchange-apis`;

const TRADING_FEES_URL = isTestnet ? `${SITE_ROOT}/fees` : '/fees';

const VALUELEAF_PIXEL_BASE_URL = 'https://utils.follow.whistle.mobi/pixel.php';

const WITHDRAW_WALLET_TYPES = {
  TRADING: 'trading',
  REFERRAL: 'referral',
} as const;

const BASKET_ORDER_VIEW_ORIGINS = {
  MARKETS: 'markets',
};

const GRACE_EXPIRY_DAYS = IS_INDIAN_EXCHANGE ? 30 : 0;

const PROD_URL = IS_INDIAN_EXCHANGE
  ? 'https://delta.exchange'
  : 'https://global.delta.exchange';

const VIP_PROGRAM_URL =
  'https://global.delta.exchange/support/solutions/articles/80001014535-vip-program-get-up-to-50-fee-rebate';
const EXCHANGE_PREFERENCE_FEATURE_FLAG = !IS_RN_WEBVIEW;

const EXCHANGE_PREFERENCE_SESSION_DURATION = 60 * 60 * 1000;

const INDIAN_EXCHANGE_DOMAIN = process.env.REACT_APP_INDIAN_EXCHANGE_DOMAIN;

const GLOBAL_EXCHANGE_DOMAIN = process.env.REACT_APP_GLOBAL_EXCHANGE_DOMAIN;

const COMMUNITY_SECTION_LINK = 'https://community.delta.exchange/';

export {
  RECOVER_ACCOUNT_BLOG_URL,
  SCALPING_BLOG_URL,
  DELTA_INDIA_RELEASE_YEAR,
  ANDROID_VERSION,
  API_KEYS_TUTORIAL_PAGE_LINK,
  APPLE_DEVICES,
  APPLE_OAUTH_CLIENT_ID,
  ASSET_SYMBOL,
  AUTHENTICATOR_APPS_BLOG_LINK,
  AUTHORISATION_LETTER_DOCUMENT_LINK,
  BANK_ACCOUNT_DEFAULT_LABEL,
  BOARD_RESOLUTION_DOCUMENT_LINK,
  CALENDLY_API_TRADING_LINK,
  CONTRACT_VALUE_FALLBACK,
  COUNTRIES_WITH_NON_VERIFICATION,
  COUNTRY_ALLOWED_FOR_LEVEL2,
  CUSTODIAN_COMPLETED_STATES,
  CUSTODIAN_PENDING_STATES,
  DELTA_CASH_DISCOUNT_PERCENT,
  DELTA_EXCHANGE_YOUTUBE_CHANNEL,
  DELTA_INDIA_APP_PLAY_STORE_URL,
  DELTA_INDIA_LANDING_SITE_ROOT,
  DELTA_INDIA_SIGNUP_URL,
  DELTA_TERMS_OF_USE_URL,
  DETO_PRECISION,
  DISABLE_CLOUD_SYNC_BLOG_LINK,
  DISABLE_SEARCH_ENGINE_CRAWLING,
  DOCS_URL,
  DOWNLOAD_APP_BANNER_IMAGE_URL,
  ERC20_DISABLED_TIME,
  ERROR_CODES,
  FAIR_PRICE_USER_GUIDE_LINK,
  FEATURE_FLAGS,
  FILES_TO_TRACK_DATA,
  FIU_ID,
  FIU_LINK,
  FRESHDESK_2FA_FAQS,
  FRESHDESK_DECODE_DELTA_CASH_BLOG,
  FRESHDESK_URL,
  GAMMA_TICK_SIZE,
  GREEK_PRECISION,
  HOMEPAGE_ROUTE,
  HYPERVERGE_SDK_URL,
  IOS_APP_ALTERNATIVE_YT_URL,
  IOS_APP_INDIA_URL,
  IOS_VERSION,
  IS_CI,
  IS_INDIAN_EXCHANGE,
  isCI,
  isDev,
  isDevnet,
  isProd,
  isTestnet,
  KYC_LEVEL_ONE_DISCONTINUATION_SUPPORT_LINK,
  KYC_UPGRADE_TRADING_BLOCKED_DATE,
  KYC_VENDORS,
  LANDING_PAGE_DECODE_DELTA_CASH_BLOG,
  LEADERBOARD_PRODUCT_TYPE,
  MOENGAGE_APP_ID,
  MOENGAGE_DEBUG_ENABLED,
  MUDREX_TERMS_PAGE_URL,
  NEOFI_CONTACT_LINK,
  NEW_USER_REGISTRATION_DATE,
  NO_DELTA_CASH_PERCENT_GLOBAL,
  NO_DELTA_CASH_PERCENT_INDIA,
  NUMBER_OF_SPOT_PAIRS,
  ONMETA_SUPPORT_EMAIL,
  ONMETA_SUPPORT_LINK,
  ONMETA_SUPPORT_MAIL,
  ONMETA_TERMS_PAGE_URL,
  OPTION_COMBOS_STRIKE_GAPS,
  OPTION_LEADERBOARD_TOTAL_REWARDS,
  optionAssetSortOrder,
  OPTIONS_ANALYTICS_COMMON_DATE_MAPPING_FORMAT,
  OPTIONS_COLUMNS,
  OPTIONS_MARK_PRICE_GUIDE_LINK,
  OPTIONS_TRADE_COLUMNS,
  PAYMENT_METHODS,
  PLATFORM_STATUS_URL,
  POLYGON_BLOCKCHAIN_EXPLORER_HOST,
  POSITIONS_MOBILE_VIEW_MODES,
  PRE_CACHE_KEY,
  PROD_ENV,
  QR_CODE_ISSUER,
  RELEASE_VERSION,
  SECURITY_UPDATION_CHECK_DATE,
  SHOW_TRADING_REVOKED_STRIP,
  SINGAPORE_TRADING_REVOKED_DATE,
  SITE_ROOT,
  SORT_STATES,
  SOURCE_OF_FUNDS_DOCUMENT_LINK,
  SOURCE_OF_FUNDS_DOCUMENTS_LINK_DELTA_INDIA,
  SOURCE_OF_FUNDS_HUF_DOCUMENT_LINK,
  STATIC_RESOURCES_CACHE_KEY,
  STRIKE_PRICE_TICK_SIZE,
  SUPPORT_CENTER_LINK,
  SUPPORT_HOMEPAGE_URL,
  SUPPORT_MAIL_INDIAN_EXCHANGE,
  SUPPORT_MAIL_INSTITUTION_KYC,
  SUPPORT_TICKET_URL,
  TAX_INFO_LINK,
  TELEGRAM_URL,
  TESTNET_URL,
  THETA_TICK_SIZE,
  TICK_FALLBACK,
  TOP_SECTION_CARD_TYPES,
  UNDERLYING_STRIKE_PRECISION,
  VANILLA_SETTLING_ASSET,
  VEGA_TICK_SIZE,
  VERIFICATION_DOCUMENT_SHARING_ADDRESS,
  IND_DOMAIN_SWITCH,
  IS_TESTNET_LINK_IN_INDIAN_EXCHANGE,
  TOTAL_EQUITY_DECIMAL,
  UNIT_OF_ASSETS_QUANTITY,
  CAREERS_LINK,
  DELTA_EXCHANGE_GLOBAL_URL,
  DOLLAR_SIGN_FOR_INDIAN_EXCHANGE,
  TESTNET_NEW_INDIA_URL,
  SPOT_PRICE,
  DELTA_INDIA_MORE_DEPOSIT_FORM_LINK,
  OAUTH_CANCEL_CODE,
  ALGO_API_PAGE,
  VALUELEAF_PIXEL_BASE_URL,
  WITHDRAW_WALLET_TYPES,
  BASKET_ORDER_VIEW_ORIGINS,
  PROD_URL,
  EXCHANGE_PREFERENCE_FEATURE_FLAG,
  EXCHANGE_PREFERENCE_SESSION_DURATION,
  INDIAN_EXCHANGE_DOMAIN,
  GLOBAL_EXCHANGE_DOMAIN,
  TRADING_FEES_URL,
  SIGNAL_TRADING,
  VIP_PROGRAM_URL,
  GRACE_EXPIRY_DAYS,
  COMMUNITY_SECTION_LINK,
};
