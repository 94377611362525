import Moengage from '@moengage/web-sdk';
import type { Primitive } from 'zod';

import { countryCode } from 'components/Auth/Signup/constants';
import {
  IS_RN_WEBVIEW,
  LOCAL_STORAGE_KEYS,
  MOE_NOTIFICATION_TIME_HOUR,
  isTestnet,
} from 'constants/constants';
import StoreProxy from 'storeProxy';
import IUser from 'types/IUser';

import { getTimestampInMS, timeNow } from './day';
import { devError } from './logger';
import { addLogsToSentry } from './sentry';

interface SignUpParams {
  id: number;
  email: string;
  country: string;
  isMobileApp?: boolean | undefined;
  pageName: string;
}
interface AnyObject {
  [key: string]: unknown;
}

const triggerEvent = (eventName: string, params: AnyObject = {}) => {
  try {
    Moengage?.track_event(eventName, params);
  } catch (error) {
    console.error(error);
  }
};

const addRegisteredDate = () => {
  Moengage?.add_user_attribute(
    'user_registered_date',
    new Date(getTimestampInMS(timeNow()))
  );
};
const signUp = ({ id, email, country, isMobileApp, pageName }: SignUpParams) => {
  try {
    Moengage?.add_unique_user_id(id).then(() => {
      Moengage?.add_email(email);
      addRegisteredDate();
      if (country) {
        Moengage?.add_user_attribute('registered_country', country);
      }
      Moengage?.track_event('Signup_Successful', {
        page_name: pageName,
        is_mobile_app: isMobileApp,
      });
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      MoengageLib.triggerEvent('Login_Successful', {
        page_name: pageName,
        is_mobile_app: isMobileApp,
      });
    });
  } catch (error) {
    devError(false, 'MoengageSignup', 'Tracking MoengageSignup failed', !isTestnet);
  }
};

export const MoengageLogin = async (user: IUser) => {
  try {
    await Moengage?.add_unique_user_id(user.id).then(() => {
      if (user && user.id && user.email) {
        Moengage?.track_event('Login_Successful', {
          page_name: 'Sign- In',
          is_mobile_app: IS_RN_WEBVIEW,
        });

        Moengage?.add_email(user.email);
        if (user.country) {
          Moengage?.add_user_attribute('registered_country', user.country);

          if (user.phone_number) {
            const code = countryCode[user.country];
            Moengage?.add_mobile(code + user.phone_number);
          }
        }

        if (user.firstName) {
          Moengage?.add_first_name(user.firstName);
        }
      }

      Moengage?.add_user_attribute('2fa_enabled', Boolean(user.isMfaEnabled));
    });
  } catch (error) {
    devError(false, 'MoengageLogin', 'Tracking Login failed', !isTestnet);
  }
};

export const MoengageDestroySession = () => {
  Moengage?.destroy_session();
};

const showOptIn = () => {
  try {
    const moeLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.MOE_DATA);
    if (!moeLocalStorage) {
      return false;
    }
    const moeData: {
      SOFT_ASK_STATUS?: string;
      OPT_IN_SHOWN_TIME: number;
    } = JSON.parse(moeLocalStorage);
    const softAskStatus = moeData.SOFT_ASK_STATUS;
    if (!softAskStatus) {
      return true;
    }
    const optInShownTime = parseInt(moeData.OPT_IN_SHOWN_TIME.toString(), 10);
    const differenceInHours = (Date.now() - optInShownTime) / (60 * 60 * 1000);
    if (softAskStatus === 'dismissed' && differenceInHours > MOE_NOTIFICATION_TIME_HOUR) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

const MoengageAddUserAttribute = (attributeName: string, attributeValue: Primitive) => {
  Moengage?.add_user_attribute(attributeName, attributeValue);
};

const MoengageAddUniqueUserId = async () => {
  const userId = StoreProxy.selector(state => state.user?.id);

  try {
    if (!userId) {
      addLogsToSentry({ userId }, 'No user id found while moengage registration web');

      return;
    }

    await Moengage?.add_unique_user_id(userId);
  } catch (error) {
    addLogsToSentry({ userId }, error);
  }
};

const MoengageLib = {
  logout: MoengageDestroySession,
  login: MoengageLogin,
  addRegisteredDate,
  signUp,
  triggerEvent,
  showOptIn,
  addUniqueUserId: MoengageAddUniqueUserId,
};

export { triggerEvent as MoengageTriggerEvent, MoengageAddUserAttribute };
export default MoengageLib;
