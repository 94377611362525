import { useEffect } from 'react';

import {
  GOOGLE_ANALYTICS,
  IS_CI,
  MOENGAGE_APP_ID,
  MOENGAGE_DEBUG_ENABLED,
} from './constants/constants';

const Analytics = () => {
  useEffect(() => {
    const initializeAnalytics = async () => {
      try {
        if (GOOGLE_ANALYTICS) {
          const ReactGA = await import('react-ga4');
          ReactGA.default.initialize(GOOGLE_ANALYTICS);
        }

        if (MOENGAGE_APP_ID) {
          const Moengage = await import('@moengage/web-sdk');
          Moengage.default.initialize({
            app_id: MOENGAGE_APP_ID,
            debug_logs: MOENGAGE_DEBUG_ENABLED,
            enableSPA: true,
            cluster: 'DC_3',
            swPath: IS_CI ? '/sw.js' : '/app/sw.js',
            swScope: IS_CI ? '/' : '/app/',
          });
        }
      } catch (error) {
        console.error('Failed to initialize analytics:', error);
      }
    };

    initializeAnalytics();
  }, []);

  return null;
};

export default Analytics;
