import { batch } from '@risingstack/react-easy-state';

import store from '../store';

export const updateHeartbeatTS = () => {
  batch(() => {
    store.heartbeatTS = new Date().getTime();
  });
};

export const updatePublicHeartbeatTS = () => {
  batch(() => {
    store.publicHeartbeatTS = new Date().getTime();
  });
};
