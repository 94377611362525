import { API_URL } from './constants';

const TRANSACTION_LOGS = {
  /** /v2/fills/history/download/csv */
  DOWNLOAD_FILLS_HISTORY: '/v2/fills/history/download/csv',
  /** /v2/orders/history/download/csv */
  DOWNLOAD_ORDER_HISTORY: '/v2/orders/history/download/csv',
  /** v2/wallet/transactions */
  GET_WALLET_TRANSACTIONS: 'v2/wallet/transactions',
  /** v2/wallet/transactions/download */
  DOWNLOAD_WALLET_TRANSACTIONS: 'v2/wallet/transactions/download',
  /** /v2/download_invoice/download_gst_invoice */
  DOWNLOAD_INVOICE: '/v2/download_invoice/download_gst_invoice',
  /** /v2/download_invoice/email_gst_invoice */
  EMAIL_INVOICE: '/v2/download_invoice/email_gst_invoice',
};

const HOLDINGS = {
  /** /v2/fills */
  GET_FILLS_HISTORY: '/v2/fills',
  /** /v2/orders/history */
  GET_ORDER_HISTORY: '/v2/orders/history',
  /** /v2/positions/margined */
  GET_POSITIONS: '/v2/positions/margined',
  /** /v2/orders */
  OPEN_ORDERS: '/v2/orders',
};

const PRODUCTS = {
  /** /v2/products */
  GET_PRODUCT: '/v2/products',
  /** /v2/products/redacted */
  GET_PRODUCTS: '/v2/products/redacted',
  /** /v2/assets */
  GET_ASSETS: '/v2/assets',
  /** /v2/deposit_assets */
  GET_DEPOSIT_ASSETS: '/v2/deposit_assets',
  /** /v2/spot_indices */
  GET_SPOT_INDICES: '/v2/spot_indices',
  /** /v2/products/universal_search */
  GET_UNIVERSAL_SEARCH_PRODUCTS: '/v2/products/universal_search',
  /** /v2/recent_product_search */
  GET_RECENT_SEARCHES: '/v2/recent_product_search',
  /** /v2/save_last_product_search */
  SAVE_LAST_PRODUCT_SEARCH: '/v2/save_last_product_search',
};

const FUNDS = {
  /** /v2/funds */
  GET_FUNDS: '/v2/funds',
};

const TRANSAK_API = {
  /** /v2/transak/fiat_currencies */
  GET_FIAT_COINS: 'v2/transak/fiat_currencies',
  /** /v2/fiat_deposit_assets */
  GET_FIAT_DEPOSIT_ASSETS: 'v2/fiat_deposit_assets',
  /** /v2/transak/crypto_fiat_buy_conversion */
  GET_FIAT_CRYPTO_CONVERSION: 'v2/transak/crypto_fiat_buy_conversion',
};

const REMITANO_API = {
  /** /v2/remitano/create_order/ */
  GET_PAYMENT_URL: '/v2/remitano/create_order/',
  /** /v2/remitano/currency_info/ */
  GET_CURRENCIES: '/v2/remitano/currency_info/',
  /** /v2/remitano/tickers/price */
  GET_CURRENCIES_EXCHANGE_RATES: '/v2/remitano/tickers/price',
};

const SIMPLEX_API = {
  /** /v2/simplex/quote */
  CREATE_SIMPLEX_PAYMENT_QUOTE: '/v2/simplex/quote',
  /** /v2/simplex/payment */
  CREATE_SIMPLEX_PAYMENT_REQUEST: '/v2/simplex/payment',
  /** /v2/simplex/fiat_currencies */
  GET_SIMPLEX_SUPPORTED_FIAT_CURRENCIES: '/v2/simplex/fiat_currencies',
};

const WALLET_API = {
  /** /v2/withdrawals */
  WITHDRAWALS: '/v2/withdrawals',
  /** /v2/withdrawals/create */
  SUBMIT_WITHDRAWALS: '/v2/withdrawals/create',
  /** /v2/withdrawals/limits */
  WITHDRAWAL_LIMITS: '/v2/withdrawals/limits',
  /** /v2/withdrawals/cancel */
  WITHDRAWAL_CANCEL: '/v2/withdrawals/cancel',
  /** /v2/withdrawals/confirmation_code */
  WITHDRAWAL_EMAIL_OTP: '/v2/withdrawals/confirmation_code',
  /** /v2/trading_credits */
  WITHDRAWAL_TRADING_CREDITS: '/v2/trading_credits',
  /** /v2/trading_credits/forfeit */
  WITHDRAWAL_TRADING_CREDITS_FORFEIT: '/v2/trading_credits/forfeit',
  /** /v2/deposits */
  DEPOSITS: '/v2/deposits',
  /** /v2/wallet/balances */
  BALANCES: '/v2/wallet/balances',
  /** /v2/funds/holdings */
  INVEST_BALANCES: '/v2/funds/holdings',
  /** /v2/deposits/address */
  ADDRESS: '/v2/deposits/address',
  /** /v2/conversion/convert */
  CONVERSION: '/v2/conversion/convert',
  /** /v2/conversion/rate */
  CONVERSION_RATE: '/v2/conversion/rate',
  /** /v2/conversion/config */
  CONVERSION_CONFIG: '/v2/conversion/config',
  /** /v2/settings */
  SETTINGS: '/v2/settings',
  /** v2/wallet/fiat_deposits */
  FIAT_DEPOSITS: 'v2/wallet/fiat_deposits',
  /** /v2/withdrawals */
  FIAT_WITHDRAWAL_HISTORY: '/v2/withdrawals',
  /** /v2/wallet/testnet_balance_topup */
  WALLET_BALANCE_TOP_UP: '/v2/wallet/testnet_balance_topup',
};

const ONMETA_API = {
  /** /v2/onmeta/quote */
  QUOTE: '/v2/onmeta/quote',
  /** /v2/onmeta/create_order */
  CREATE_ORDER: '/v2/onmeta/create_order',
  /** /v2/onmeta/share_kyc_details */
  SHARE_KYC_DATA_ONRAMP: '/v2/onmeta/share_kyc_details',
  /** /v2/onmeta/offramp_invoices */
  OFFRAMP_INVOICE: '/v2/onmeta/offramp_invoices',
};

const NEOFI_API = {
  /** /v2/neo_fi/onramp/conversion_rates */
  QUOTE: '/v2/neo_fi/onramp/conversion_rates',
  /** /v2/neo_fi/onramp/order */
  ORDER_URL: '/v2/neo_fi/onramp/order',
};

const ONRAMPER_API = {
  /** /v2/onramper/get_widget_link */
  ORDER_URL: '/v2/onramper/get_widget_link',
  /** /v2/onramper/get_supported_asset */
  CURRENCY_LIST: '/v2/onramper/get_supported_asset',
};
const MUDREX_API = {
  /** /v2/mudrex/onramp/conversion_rates */
  QUOTE: '/v2/mudrex/onramp/conversion_rates',
  /** /v2/mudrex/signature_details */
  SIGNATURE: '/v2/mudrex/signature_details',
};

const CHARTS_API = {
  /** /v2/chart */
  root: `${API_URL}/v2/chart`,
  /** /v2/chart/symbols */
  SEARCH_SYMBOL: `${API_URL}/v2/chart/symbols`,
  /** /v2/chart/search */
  COMPARE_SYMBOL: `${API_URL}/v2/chart/search`,
  /** /v2/chart/history */
  HISTORY_SYMBOL: `${API_URL}/v2/chart/history`,
  /** /v2/chart/config */
  CONFIG: `${API_URL}/v2/chart/config`,
  /** https://tv-save.delta.exchange */
  STORAGE: `https://tv-save.delta.exchange`,
  /** /v2/options_rv */
  GET_OPTIONS_RV_DATA: '/v2/options_rv',
  /** /v2/options_iv */
  GET_OPTIONS_IV_DATA: '/v2/options_iv',
  /** /v2/oi_change */
  GET_OI_CHANGE: '/v2/oi_change',
  /** /v2/cumulative_oi */
  GET_CUMULATIVE_OI_CHART: '/v2/cumulative_oi',
  /** /v2/chart/history */
  GET_CHART_HISTORY: '/v2/chart/history',
  /** /v2/options/taker_volume */
  GET_OPTIONS_TAKER_VOLUME: '/v2/options/taker_volume',
  /** /v2/options/max_pain */
  GET_OI_MAX_PAIN: '/v2/options/max_pain',
};

const ORDERS = {
  /** /v2/orders */
  ORDERS: '/v2/orders',
  /** /v2/orders/bracket */
  BRACKET: '/v2/orders/bracket',
  /** /v2/orders/estimate_margin */
  ESTIMATE_MARGIN: '/v2/orders/estimate_margin',
  /** /v2/products/${productId}/orders/leverage */
  LEVERAGE: productId => `/v2/products/${productId}/orders/leverage`,
  /** /v2/orders/basket */
  BASKET: '/v2/orders/basket',
  /** /v2/orders/all */
  CLOSE_ALL: '/v2/orders/all',
};

const POSITIONS = {
  /** v2/positions/change_margin */
  CHANGE_MARGIN: 'v2/positions/change_margin',
  /** v2/positions/auto_topup */
  AUTO_TOPUP: '/v2/positions/auto_topup',
  /** v2/positions/close_all */
  CLOSE_ALL: '/v2/positions/close_all',
};

const USER_PROFILE = {
  /** /v2/login */
  LOGIN: '/v2/login',
  /** /v2/profile/refresh_token */
  REFRESH_TOKEN: '/v2/profile/refresh_token',
  /** /v2/logout */
  LOGOUT: '/v2/logout',
  /** /v2/profile */
  KYC_STATUS: '/v2/profile',
  /** /v2/profile/idfy_link */
  GET_IDFY_LINK: '/v2/profile/idfy_link',
  /** /v2/profile/kyc_cert_status */
  KYC_CERTIFICATES: '/v2/profile/kyc_cert_status',
  /** /v2/register/sign_up */
  REGISTER_SIGNUP: '/v2/register/sign_up',
  /** /v2/users/referral_codes */
  CHECK_REFERRAL_CODE: '/v2/users/referral_codes',
  /** /v2/register/resend_email_verification_code */
  REGISTER_RESEND_VERIFICATION_CODE: '/v2/register/resend_email_verification_code',
  /** /v2/register/verify_email */
  REGISTER_VERIFY: '/v2/register/verify_email',
  /** /v2/profile/enable_mfa */
  ENABLE_MFA: '/v2/profile/enable_mfa',
  /** /v2/profile/enable_mfa/email_code */
  ENABLE_MFA_SEND_EMAIL_CODE: '/v2/profile/enable_mfa/email_code',
  /** /v2/profile/enable_mfa_new */
  RESET_MFA: '/v2/profile/enable_mfa_new',
  /** /v2/profile/disable_mfa */
  DISABLE_MFA: '/v2/profile/disable_mfa',
  /** /v2/login/mfa_auth */
  LOGIN_MFA: '/v2/login/mfa_auth',
  /** /v2/profile/phone_number */
  PHONE_NUMBER: '/v2/profile/phone_number',
  /** /v2/profile/phone_number/verify */
  VERIFY_PHONE_NUMBER: '/v2/profile/phone_number/verify',
  /** /v2/profile/phone_number/resend_verification_code */
  RESEND_PHONE_NUMBER: '/v2/profile/phone_number/resend_verification_code',
  /** v2/login_with_email_code */
  LOGIN_EMAIL_VERIFICATION: 'v2/login_with_email_code',
  /** /v2/login/login_with_phone_otp */
  PHONE_OTP_VERIFICATION: '/v2/login/login_with_phone_otp',
  /** /v2/login/email_verification_code */
  RESEND_LOGIN_EMAIL_CODE: '/v2/login/email_verification_code',
  /** /v2/login/verification_code_on_phone_number */
  SEND_OTP_ON_PHONE_NUMBER: '/v2/login/verification_code_on_phone_number',
  /** /v2/login/verification_phone_otp */
  SEND_VERIFICATION_PHONE_OTP: '/v2/login/verification_phone_otp',
  /** /v2/profile/update */
  UPDATE: '/v2/profile/update',
  /** /v2/profile/change_password */
  CHANGE_PASSWORD: '/v2/profile/change_password',
  /** /v2/profile/change_password_new */
  CHANGE_PASSWORD_NEW: '/v2/profile/change_password_new',
  /** /v2/profile/change_password_otp_validation */
  CHANGE_PASSWORD_VALIDATION: '/v2/profile/change_password_otp_validation',
  /** /v2/profile/change_password_verification_code */
  CHANGE_PASSWORD_VERIFICATION_CODE: '/v2/profile/change_password_verification_code',
  /** /v2/users/my_referrals */
  MY_REFERRALS: '/v2/users/my_referrals',
  /** /v2/profile/api_keys */
  API_KEYS: '/v2/profile/api_keys',
  /** /v2/profile/api_key */
  DELETE_API_KEY: '/v2/profile/api_key',
  /** /v2/profile/api_key */
  CREATE_API_KEY: '/v2/profile/api_key',
  /** /v2/profile/send_create_api_key_code */
  SEND_CODE_API_KEY: '/v2/profile/send_create_api_key_code',
  /** /v2/forgot_password */
  FORGOT_PASSWORD: '/v2/forgot_password',
  /** /v2/reset_password */
  RESET_PASSWORD: '/v2/reset_password',
  /** /v2/profile/login_history */
  HISTORY: '/v2/profile/login_history',
  /** /v2/users/trading_preferences */
  GET_TRADE_PREFERENCES: '/v2/users/trading_preferences',
  /** /v2/users/trading_preferences */
  UPDATE_TRADE_PREFERENCES: '/v2/users/trading_preferences',
  /** /v2/profile/update_preferences */
  UPDATE_USER_PREFERENCES: '/v2/profile/update_preferences',
  /** /v2/users/margin_mode */
  UPDATE_PORTFOLIO_MARGIN: '/v2/users/margin_mode',
  /** /v2/offers */
  OFFERS: '/v2/offers',
  /** /v2/offers/applied */
  OFFERS_APPLIED: '/v2/offers/applied',
  /** /v2/offers/apply_promo */
  OFFERS_APPLY_PROMO: '/v2/offers/apply_promo',
  /** /v2/offers/remove_promo */
  OFFERS_REMOVE_PROMO: '/v2/offers/remove_promo',
  /** /v2/users/profile */
  GET_USER_PROFILE: '/v2/users/profile',
  /** /v2/users/account_analytics/pnl_summary */
  GET_ACCOUNT_ANALYTICS_PNL_SUMMARY: '/v2/users/account_analytics/pnl_summary',
  /** /v2/users/account_analytics/pnl */
  GET_ACCOUNT_ANALYTICS_DETO_CHART: '/v2/users/account_analytics/pnl',
  /** /v2/users/account_analytics/daily_pnl_chart */
  GET_ACCOUNT_ANALYTICS_PNL_CHART: '/v2/users/account_analytics/daily_pnl_chart',
  /** /v2/users/account_analytics/pnl_chart */
  GET_ACCOUNT_ANALYTICS_PNL_CHART_DATA: '/v2/users/account_analytics/pnl_chart',
  /** /v2/users/account_analytics/volume */
  GET_ACCOUNT_ANALYTICS_VOLUME: '/v2/users/account_analytics/volume',
  /** /v2/users/account_analytics/fees_paid */
  GET_ACCOUNT_ANALYTICS_FEES_PAID: '/v2/users/account_analytics/fees_paid',
  /** /v2/users/account_analytics/activity */
  GET_ACCOUNT_ANALYTICS_ACTIVITY: '/v2/users/account_analytics/activity',
  /** /v2/users/account_analytics/equity_change */
  GET_ACCOUNT_ANALYTICS_DAILY_EQUITY_CHANGE: '/v2/users/account_analytics/equity_change',
  /** /v2/users/account_analytics/statistics */
  GET_ACCOUNT_ANALYTICS_STATISTICS: '/v2/users/account_analytics/statistics',
  /** /v2/users/account_analytics/account_equity */
  ACCOUNT_ANALYTICS_EQUITY_CHART: '/v2/users/account_analytics/account_equity',
  /** /v2/users/account_analytics/withdrawal_and_deposits */
  DEPOSIT_AND_WITHDRAWALS: '/v2/users/account_analytics/withdrawal_and_deposits',
  /** /v2/withdrawals/available_limit_for_non_kyc_user */
  UNVERIFIED_USERS_WITHDRAWAL_LIMIT: '/v2/withdrawals/available_limit_for_non_kyc_user',
  /** v2/withdrawals/pending */
  WITHDRAWAL_PENDING: 'v2/withdrawals/pending',
  /** /v2/bell_alerts */
  BELL_ALERTS: '/v2/bell_alerts',
  /** /v2/bell_alerts/read */
  BELL_ALERTS_READ: '/v2/bell_alerts/read',
  /** /v2/option_buying_tool/ */
  EASY_OPTIONS: '/v2/option_buying_tool/',
  /** /v2/volume_history */
  VOLUME_HISTORY: '/v2/volume_history',
  /** /v2/profile/kyc_document_detail */
  GET_KYC_DOCUMENTS: '/v2/profile/kyc_document_detail',
  /** /v2/profile/kyc_consent */
  GET_CONSENT_POPUP_DETAILS: '/v2/profile/kyc_consent',
  /** v2/profile/refresh_kyc */
  REFRESH_KYC: 'v2/profile/refresh_kyc',
  /** v2/profile/phone_verification/add */
  ADD_PHONE_NUMBER: 'v2/profile/phone_verification/add',
  /** v2/profile/phone_verification/verify */
  VERIFY_PHONE_NUMBER_OTP: '/v2/profile/phone_verification/verify',
  /** v2/profile/phone_verification/resend_otp */
  RESEND_PHONE_NUMBER_OTP: 'v2/profile/phone_verification/resend_otp',
  /** /v2/verify_email_with_link */
  VERIFY_EMAIL_WITH_LINK: '/v2/verify_email_with_link',
  /** /v2/profile/vendor_link */
  GET_KYC_VENDOR_LINK: '/v2/profile/vendor_link',
  /** /v2/kyc/kyc_verification_status */
  GET_KYC_VERIFICATION_STATUS: '/v2/kyc/kyc_verification_status',
  /** /v2/kyc/import_from_global_iam */
  KYC_IMPORT_GLOBAL: '/v2/kyc/import_from_global_iam',
  /** /v2/ip_details */
  IP_DETAILS: '/v2/ip_details',
  /** /v2/trades_history */
  GET_TRADE_HISTORY: '/v2/trades_history',
  /** /v2/trades_history/files */
  FETCH_TRADE_HISTORY_RECORDS: '/v2/trades_history/files',
  /** /v2/ip_address */
  IP_ADDRESS: '/v2/ip_address',
  /** /v2/profile/delete_account */
  DELETE_ACCOUNT: '/v2/profile/delete_account',
  /** /v2/profile/delete_account_confirmation */
  DELETE_ACCOUNT_CONFIRMATION: '/v2/profile/delete_account_confirmation',
  /** /v2/users/account_analytics/share_cards */
  TRADE_SUCCESS_CARD: '/v2/users/account_analytics/share_cards',
  REQUEST_DISABLE_MFA_OTP: '/v2/user/disable_mfa',
  VERIFY_DISABLE_MFA_OTP: '/v2/user/disable_mfa_verify_otp',
  AUTHENTICATED_USER_LOST_2FA: '/v2/user/face_auth_disable_mfa',

  // Anti-Phishing Code
  ANTI_PHISHING: {
    /** /v2/profile/send_update_phishing_code_verification_code */
    SEND_VERIFICATION_CODE: '/v2/profile/send_update_phishing_code_verification_code',
    /** /v2/profile/update_phishing_code */
    UPDATE_CODE: '/v2/profile/update_phishing_code',
  },
  /** /v2/users/price_alert_preferences */
  UPDATE_PRICE_ALERT_PREFERENCE: '/v2/users/price_alert_preferences',
  GTM_EVENTS: '/v2/users/gtm_events',
};

const AUTH = {
  LOGIN: {
    /** /v2/login/qrcode/new_token */
    CREATE_LOGIN_VIA_QR_TOKEN: '/v2/login/qrcode/new_token',
    /** /v2/login/qrcode/token_details */
    GET_LOGIN_VIA_QR_TOKEN_DETAILS: '/v2/login/qrcode/token_details',
  },
};

const OAUTH = {
  /** /v2/oauth/add_to_existing_account */
  LINK_ACCOUNT: `${API_URL}/v2/oauth/add_to_existing_account`,
  /** /v2/oauth/login */
  LOGIN: `${API_URL}/v2/oauth/login`,
  /** /v2/oauth/register */
  REGISTER: `${API_URL}/v2/oauth/register`,
  /** /v2/register/verify_email_with_oauth */
  VERIFY_WITH_OAUTH: `/v2/register/verify_email_with_oauth`,

  // ACCESS token is mandatory
  /** https://www.googleapis.com/oauth2/v3/userinfo?access_token= */
  GOOGLE_USER_INFO: `https://www.googleapis.com/oauth2/v3/userinfo?access_token=`,
};

const SUB_ACCOUNTS = {
  /** /v2/sub_accounts/total_equity */
  GET_SUB_ACCOUNTS: '/v2/sub_accounts/total_equity',
  /** /v2/sub_account */
  SUB_ACCOUNT: '/v2/sub_account',
  /** /v2/sub_account/auth_token */
  GET_ACCOUNT_TOKEN: '/v2/sub_account/auth_token',
  /** /v2/wallets/sub_account_balance_transfer */
  TRNASFER_FUNDS_SUB_ACCOUNT: '/v2/wallets/sub_account_balance_transfer',
  /** /v2/wallets/sub_accounts_transfer_history */
  TRNASFER_FUNDS_HISTORY_SUB_ACCOUNT: '/v2/wallets/sub_accounts_transfer_history',
};

const SECURITY = {
  /** /v2/all_active_logins */
  ACTIVE_LOGINS: '/v2/all_active_logins',
  /** /v2/wallets/user_withdrawal_addresses?user_id=${userId} */
  ADDRESS: userId => `/v2/wallets/user_withdrawal_addresses?user_id=${userId}`,
  /** /v2/wallets/user_withdrawal_address */
  WITHDRAWAL_ADDRESS: '/v2/wallets/user_withdrawal_address',
  /** /v2/logout_all */
  LOGOUT_ALL: '/v2/logout_all',
};

/** /v2/stats */
const STATS = '/v2/stats';
/** /v2/leaderboard */
const LEADERBOARD_API = '/v2/leaderboard';
/** /v2/leaderboard/details */
const LEADERBOARD_EVENT_DETAILS_API = '/v2/leaderboard/details';

const BASKET_ORDERS_API = {
  /** /v2/orders/estimate_margin/basket */
  ESTIMATE_MARGIN: '/v2/orders/estimate_margin/basket',
  /** /v2/orders/basket */
  PLACE_ORDER: '/v2/orders/basket',
};

const FRESHDESK_API = {
  /** /v2/freshdesk/tickets */
  CREATE_TICKET: `/v2/freshdesk/tickets`,
  /** /v2/freshdesk/tickets/validate_otp */
  VALIDATE_OTP: '/v2/freshdesk/tickets/validate_otp',
  /** /v2/freshdesk/tickets/send_otp */
  SEND_OTP: '/v2/freshdesk/tickets/send_otp',
  /** /v2/freshdesk/tickets/resend_otp */
  RESEND_OTP: '/v2/freshdesk/tickets/resend_otp',
};

const ALPYNE_API = {
  /** /v2/alpyne/signature */
  GET_SIGNATURE: '/v2/alpyne/signature',
  /** /v2/alpyne/usdt_rates */
  GET_USDT_QUOTE: `/v2/alpyne/usdt_rates`,
  /** /v2/alpyne/create_user */
  CREATE_USER: '/v2/alpyne/create_user',
};

const TRADES_API = {
  GET_OPTIONS_RECENT_TRADES: '/v2/trades/options',
  ENABLE_FUTURES_SCALPING: '/v2/users/enable_futures_scalper_offer',
};

const MARKETS_API = {
  /** /v2/market/futures */
  FUTURES: '/v2/market/futures',
  /** /v2/market/options */
  OPTIONS: '/v2/market/options',
  /** /v2/market/option_combos */
  OPTIONS_COMBOS: '/v2/market/option_combos',
  /** /web/options/info */
  OPTIONS_INFO: '/web/options/info',
  /** /web/options_chain */
  OPTIONS_CHAIN: '/web/options_chain',
};

const ACCOUNTS_API = {
  /** /v2/withdrawals */
  FIAT_WITHDRAWAL_HISTORY: '/v2/withdrawals',
};
/** @type {string} */
const REUSABLE_STRIP_URL = process.env.REACT_APP_REUSABLE_STRIP_URL;

const BANK_DETAILS = {
  LIST: {
    /** /v2/user_bank_details */
    GET: '/v2/user_bank_details',
  },

  ADD_ACCOUNT: {
    /** /v2/bank_details/register/send_otp */
    SEND_OTP: '/v2/bank_details/register/add_bank_account',
    /** /v2/bank_details/register/resend_otp */
    RESEND_OTP: '/v2/bank_details/register/resend_otp',
    /** /v2/bank_details/register/validate_otp */
    VERIFY_OTP: '/v2/bank_details/register/validate_otp',
    /** /v2/user_bank_details/bank_account_verification_status */
    CHECK_STATUS: '/v2/user_bank_details/bank_account_verification_status',
  },

  /** /v2/bank_details/delete_record */
  DELETE_BANK_ACCOUNT: '/v2/bank_details/delete_record',
  /** /v2/get_bank_details */
  GET_BANK_DETAILS: '/v2/get_bank_details',
};

const FIAT_BANK_DETAILS_INDIA = {
  /** /v2/fiat_bank/bank_account_details */
  GET: 'v2/fiat_bank/bank_account_details',
};

const DEPOSITS_INDIA = {
  /** /v2/fiat_bank/fiat_bank_new_deposit */
  NEW: 'v2/fiat_bank/fiat_bank_new_deposit',
  /** /v2/fiat_bank/confirm_deposit */
  CONFIRM: 'v2/fiat_bank/confirm_deposit',

  /** /v2/wallet/fiat_deposits */
  HISTORY: {
    GET: 'v2/wallet/fiat_deposits',
  },

  UPI: {
    /** /v2/upi */
    WHITELIST_ID: '/v2/upi',
    /** /v2/upi_details/qr_code_generation */
    GENERATE_PAYMENT_URL: 'v2/upi_details/qr_code_generation',
    /** /v2/upi */
    GET_WHITELISTED_IDS: '/v2/upi',
    GET_DEPOSIT_STATUS: '/v2/deposit/status',
  },

  /** /v2/deposit/claim */
  CLAIM_DEPOSIT: '/v2/deposit/claim',
  /** /v2/deposit/refund_detail */
  SUBMIT_REFUND_DETAILS: '/v2/deposit/refund_detail',
  /** /v2/deposit/user_refund_details */
  GET_USER_REFUND_DETAILS: '/v2/deposit/user_refund_details',
};

const WITHDRAWAL_INDIA = {
  NEW: 'v2/fiat_withdrawals/confirmation_code',
  /** /v2/fiat_withdrawals/create */
  CODE_VERIFICATION: 'v2/fiat_withdrawals/create',

  HISTORY: {
    /** /v2/withdrawals */
    GET: 'v2/withdrawals',
  },
};

const OFFRAMP_API = {
  /** /v2/user_bank_details */
  USER_BANK_DETAILS: '/v2/user_bank_details',
  /** /v2/user_upi_details */
  USER_UPI_DETAILS: '/v2/user_upi_details',
  /** /v2/bank_details/register/send_otp */
  BANK_DETAILS_REGISTER_SEND_OTP: '/v2/bank_details/register/send_otp',
  /** /v2/bank_details/register/resend_otp */
  BANK_DETAILS_REGISTER_RESEND_OTP: '/v2/bank_details/register/resend_otp',
  /** /v2/bank_details/register/validate_otp */
  BANK_DETAILS_REGISTER_VALIDATE_OTP: '/v2/bank_details/register/validate_otp',
  /** /v2/bank_details/delete_record */
  BANK_DETAILS_DELETE: '/v2/bank_details/delete_record',
  /** /v2/upi_details/register/send_otp */
  UPI_DETAILS_REGISTER_SEND_OTP: '/v2/upi_details/register/send_otp',
  /** /v2/upi_details/register/resend_otp */
  UPI_DETAILS_REGISTER_RESEND_OTP: '/v2/upi_details/register/resend_otp',
  /** /v2/upi_details/register/validate_otp */
  UPI_DETAILS_REGISTER_VALIDATE_OTP: '/v2/upi_details/register/validate_otp',
  /** /v2/upi_details/delete_record */
  UPI_DETAILS_DELETE: '/v2/upi_details/delete_record',
  /** /v2/fiat_withdrawal_assets */
  FIAT_WITHDRAWAL_ASSETS: '/v2/fiat_withdrawal_assets',
  /** /v2/fiat_withdrawals/create */
  FIAT_WITHDRAWAL_CREATE: '/v2/fiat_withdrawals/create',
  /** /v2/fiat_withdrawals/confirmation_code */
  FIAT_WITHDRAWAL_CONFIRMATION_CODE: '/v2/fiat_withdrawals/confirmation_code',
  /** /v2/onmeta/sell_quote */
  CREATE_ONMETA_QUOTE: '/v2/onmeta/sell_quote',
  /** /v2/alpyne/usdt_rates */
  CREATE_ALPYNE_QUOTE: '/v2/alpyne/usdt_rates',
  /** /v2/mudrex/offramp/conversion_rates */
  CREATE_MUDREX_QUOTE: '/v2/mudrex/offramp/conversion_rates',
};

const SOCKET_CHANNELS = {
  /** /v2/ticker */
  V2_TICKER: '/v2/ticker',
};

const ONRAMP_API = {
  /** /v2/fiat_deposit_quotes */
  FIAT_DEPOSIT_QUOTES: '/v2/fiat_deposit_quotes',
};

const SIGNAL_API = {
  /** /v2/get_sources */
  GET_SOURCES: '/v2/get_sources',
  /** /v2/send_create_webhook_code */
  SEND_VERIFICATION_CODE: '/v2/send_create_webhook_code',
  /** /v2/webhook_automation */
  WEBHOOK: '/v2/webhook_automation',
  /** /v2/get_webhooks */
  GET_WEBHOOKS: '/v2/get_webhooks',
  /** /v2/send_fetch_webhook_code */
  GET_VERIFICATION_CODE: '/v2/send_fetch_webhook_code',
  /** /v2/get_user_trading_webhook */
  GET_USER_TRADING_WEBHOOK: '/v2/get_user_trading_webhook',
};

const BASKET_ANALYTICS_API = {
  /** /v2/orders/basket/analytics */
  GET_BASKET_ANALYTICS: '/v2/orders/basket/analytics',
};

const PNL_ANALYTICS_CHART_APIS = {
  /** /v2/users/account_analytics/funding_chart */
  FUNDING_CHART: '/v2/users/account_analytics/funding_chart',
};
const RECOVER_ACCOUNT = {
  /** /v2/profile/recovery_otp */
  GENERATE_OTP: '/v2/profile/recovery_otp',
  /** /v2/profile/recovery_link */
  VERIFY_AND_GET_VENDOR: '/v2/profile/recovery_link',
};

const FACE_AUTH_RECOVERY_STATUS = '/v2/user/user_recovery_status';

export {
  TRANSACTION_LOGS,
  HOLDINGS,
  USER_PROFILE,
  SUB_ACCOUNTS,
  ORDERS,
  POSITIONS,
  WALLET_API,
  CHARTS_API,
  PRODUCTS,
  SECURITY,
  FUNDS,
  STATS,
  TRANSAK_API,
  REMITANO_API,
  BASKET_ORDERS_API,
  SIMPLEX_API,
  AUTH,
  FRESHDESK_API,
  LEADERBOARD_API,
  ONMETA_API,
  ALPYNE_API,
  NEOFI_API,
  TRADES_API,
  MARKETS_API,
  ACCOUNTS_API,
  OAUTH,
  BANK_DETAILS,
  LEADERBOARD_EVENT_DETAILS_API,
  REUSABLE_STRIP_URL,
  DEPOSITS_INDIA,
  FIAT_BANK_DETAILS_INDIA,
  WITHDRAWAL_INDIA,
  OFFRAMP_API,
  ONRAMPER_API,
  SOCKET_CHANNELS,
  ONRAMP_API,
  MUDREX_API,
  SIGNAL_API,
  BASKET_ANALYTICS_API,
  PNL_ANALYTICS_CHART_APIS,
  RECOVER_ACCOUNT,
  FACE_AUTH_RECOVERY_STATUS,
};
