import { UNIT_OF_ASSETS_QUANTITY } from 'constants/constants';
import { stopOrderTriggersMap } from 'constants/triggerMap';
import { map, defaultTo, min, max, head, reduce, ifElse, pluck } from 'helpers/ramda';
import i18n from 'i18n/config';
import { ContractType } from 'types/IProducts';

import { convertOrderBook } from './formulas';
import { generateAssetUnitLabel } from './localized';
import { spotUnderlyingNotional } from './spotFormulas';
import { cropAfterDecimals } from './utils';

const parse = (x: string) => parseFloat(x);

const convertOrders = (orders: string[]) => map(parse, orders);

const defaultToDash = defaultTo('-');

const finiteCheck = (value: number) => (Number.isFinite(value) ? value : undefined);

export const defaultToHiphen = (value: number) => defaultToDash(finiteCheck(value));

const bestPriceCondition = ifElse(
  ({ isBuy }) => isBuy,
  ({ orders }) => reduce(min, head(orders), orders),
  ({ orders }) => reduce(max, head(orders), orders)
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const limitPriceCheck = (price, orderbook, side, threshold, productId) => {
  const orders = convertOrderBook(orderbook, side);
  const isBuy = side === 'buy';
  const getPrices = pluck('price');
  const bestPrice = parseFloat(
    bestPriceCondition({
      isBuy,
      orders: convertOrders(getPrices(orders)),
    }).toString()
  );

  const percent = Number((((price - bestPrice) / bestPrice) * 100).toFixed(2));

  if (isBuy ? percent > threshold : percent < -10) {
    return `Deep into the market: Your order is ${defaultToHiphen(
      Math.round(percent)
    )}% ${isBuy ? 'above' : 'below'} the best ${isBuy ? 'ask' : 'bid'}`;
  }
  return '';
};

export const impactSizeCheck = (size, impactSize, contractValue, contractCurrency) => {
  if (size > 30 * impactSize) {
    return `Large size: Your order size is ${size} ${generateAssetUnitLabel('', {
      moreThanOne: true,
    })} (${size * contractValue} ${contractCurrency}).`;
  }
  return '';
};

export const impactSizeCheckSpot = ({
  size,
  isQuoteAsset,
  price,
  impactSize,
  contractCurrency,
}) => {
  const spotSize = isQuoteAsset ? spotUnderlyingNotional(price, size) : Number(size);
  if (spotSize > 30 * Number(impactSize)) {
    return `Large size: Your order size is ${spotSize} ${contractCurrency}`;
  }
  return '';
};

export const slippageCheck = ({
  estimatedExecutionPrice,
  markPrice, // best bid or best ask in case of spot
  contractType,
  side,
  precision,
  spotPrice,
  orders,
  size,
}) => {
  if (
    Number(estimatedExecutionPrice) === 0 ||
    Number(size) === 0 ||
    !orders ||
    orders.length === 0
  ) {
    return null;
  }

  // Selling: If Best Bid > Mark Price
  // Buying: If Best Offer < Mark Price
  if (side === 'sell' && Number(orders[0].price) > Number(markPrice)) return null;
  if (side === 'buy' && Number(orders[0].price) < Number(markPrice)) return null;

  const slippageValue = contractType === ContractType.Spot ? orders[0].price : markPrice;

  const percent = Number(
    (
      ((Number(estimatedExecutionPrice) - Number(slippageValue)) /
        Number(slippageValue)) *
      100
    ).toFixed(2)
  );

  const slippageCondition = (() => {
    switch (contractType) {
      case ContractType.PerpetualFutures:
      case ContractType.Futures:
        return Math.abs(percent) > 5;
      case ContractType.OptionsCombos:
      case ContractType.CallOptions:
      case ContractType.PutOptions:
      case ContractType.MoveOptions:
        // (estimatedExecutionPrice - markPrice) > max (10% * markPrice, 0.05% * spotPrice)
        return (
          Math.abs(Number(estimatedExecutionPrice) - Number(markPrice)) >
          max(0.1 * Number(markPrice), 0.0005 * Number(spotPrice))
        );
      default:
        return false;
    }
  })();

  const direction = percent > 0 ? 'above' : 'below';
  if (slippageCondition) {
    return `High slippage: The estimated execution price (${defaultToHiphen(
      Number(cropAfterDecimals(estimatedExecutionPrice, precision))
    )}) of your order is ${defaultToHiphen(Math.abs(percent))}% ${direction} the ${
      // eslint-disable-next-line no-nested-ternary
      contractType !== ContractType.Spot
        ? 'mark price'
        : side === 'buy'
        ? 'top offer'
        : 'top bid'
    }`;
  }
  return null;
};

export const isAssetEqualToLotOrCont = (asset: string) =>
  UNIT_OF_ASSETS_QUANTITY === asset;

export const isAssetNotEqualToLotOrCont = (asset: string) =>
  UNIT_OF_ASSETS_QUANTITY !== asset;

export function validateOrderCondition(
  order: {
    side: 'buy' | 'sell';
    stop_trigger_method: keyof typeof stopOrderTriggersMap;
    stop_price: number | string;
    stop_order_type: 'stop_loss_order' | 'take_profit_order';
  },
  contractType: ContractType,
  currentPrice: number
) {
  const {
    side,
    stop_trigger_method: stopTriggerMethod,
    stop_price: stopPrice,
    stop_order_type: stopOrderType,
  } = order;

  const isPutOptionsWithIndexPrice =
    contractType === ContractType.PutOptions && stopTriggerMethod === 'spot_price';

  // Use stopOrderType normally, but reverse it for Put Options with Index Price
  let effectiveOrderType = stopOrderType;

  if (isPutOptionsWithIndexPrice) {
    effectiveOrderType =
      stopOrderType === 'stop_loss_order' ? 'take_profit_order' : 'stop_loss_order';
  }

  const conditions = {
    stop_loss_order: {
      buy: (entered: number, current: number) => entered <= current,
      sell: (entered: number, current: number) => entered >= current,
    },
    take_profit_order: {
      buy: (entered: number, current: number) => entered >= current,
      sell: (entered: number, current: number) => entered <= current,
    },
  };

  const isConditionInvalid = conditions[effectiveOrderType]?.[side]?.(
    Number(stopPrice),
    currentPrice
  );
  if (!isConditionInvalid) return null;

  const commonMessage = i18n.t('validations:invalidPriceCondition');
  const priceType = stopOrderTriggersMap[stopTriggerMethod];

  const direction = (() => {
    if (stopOrderType === 'stop_loss_order') {
      return side === 'buy' ? 'above' : 'below';
    }
    return side === 'buy' ? 'below' : 'above';
  })();

  return `${commonMessage} ${i18n.t(`common:${direction}`)} ${i18n.t(
    `trading:current`
  )} ${priceType}`;
}
