import createAction from 'helpers/createAction';

import {
  AUTHENTICATE_SOCKET,
  CHECK_HEARTBEAT,
  CONNECT_SOCKET,
  DISABLE_HEARTBEAT,
  DISCONNECT_SOCKET,
  ENABLE_HEARTBEAT,
  RECONNECT_SOCKET,
  RECONNECT_SOCKET_TAB_REACTIVE,
  SUBSCRIBE_FUNDING,
  SUBSCRIBE_L2_ORDERBOOK,
  SUBSCRIBE_MARK,
  SUBSCRIBE_PUBLIC_CHANNELS,
  SUBSCRIBE_RECENT_TRADE,
  SUBSCRIBE_SPOT,
  SUBSCRIBE_TICKER,
  UNSUBSCRIBE_TICKER,
  SUBSCRIBE_TRADING_NOTIFICATIONS,
  UNSUBSCRIBE_ALL,
  UNSUBSCRIBE_OB_RT,
  UNSUBSCRIBE_PRIVATE,
  // UPDATE_HEARTBEAT_TS,
  SUBSCRIBE_OHLC_CANDEL,
  UNSUBSCRIBE_OHLC_CANDEL,
  SUBSCRIBE_ORDERS,
  SUBSCRIBE_SELECTED_PRODUCT_MARK_DATA,
  SUBSCRIBE_OPTION_CHAIN_MARK,
  UNSUBSCRIBE_SELECTED_PRODUCT_MARK_DATA,
  UNSUBSCRIBE_OPTION_CHAIN_MARK,
  SUBSCRIBE_BEST_BID_BEST_ASK,
  UNSUBSCRIBE_BEST_BID_BEST_ASK,
  SUBSCRIBE_SELECTED_PRODUCT_SPOT,
  UNSUBSCRIBE_SELECTED_PRODUCT_SPOT,
  UNSUBSCRIBE_FUNDING,
  SUBSCRIBE_OPEN_POSITION_MARK,
  UNSUBSCRIBE_MARK,
  SUBSCRIBE_WALLET_SPOT,
  UNSUBSCRIBE_WALLET_SPOT,
  ACTIVATE_REST_MODE,
  SOCKET_CONNECTED,
  DEACTIVATE_REST_MODE,
  CONNECT_SELECTED_PRODUCT_CHANNEL,
  UNSUBSCRIBE_L2_ORDERBOOK,
  SUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  SUBSCRIBE_CONTRACT_ALL_RECENT_TRADES,
  UNSUBSCRIBE_CONTRACT_ALL_RECENT_TRADES,
  SUBSCRIBE_OPEN_POSITION_SPOT,
  UNSUBSCRIBE_OPEN_POSITION_SPOT,
  PUBLIC_SOCKET_CONNECTED,
  DISCONNECT_PUBLIC_SOCKET,
  CONNECT_PUBLIC_SOCKET,
  ENABLE_HEARTBEAT_PUBLIC,
  DISABLE_HEARTBEAT_PUBLIC,
  CONNECT_SELECTED_PRODUCT_CHANNEL_PUBLIC,
  CHECK_HEARTBEAT_PUBLIC,
} from '../actionTypes/socket';

export const authenticateSocket = createAction(AUTHENTICATE_SOCKET);
export const connectSocket = createAction(CONNECT_SOCKET);
export const socketConnected = createAction(SOCKET_CONNECTED);
export const disconnectSocket = createAction(DISCONNECT_SOCKET);
export const reconnectSocket = createAction(RECONNECT_SOCKET);
export const activateRestMode = createAction(ACTIVATE_REST_MODE);
export const deactivateRestMode = createAction(DEACTIVATE_REST_MODE);
export const connectSelectedProductChannels = createAction(
  CONNECT_SELECTED_PRODUCT_CHANNEL
);

export const reconnectSocketTabReactive = createAction(RECONNECT_SOCKET_TAB_REACTIVE);
// export const updateHeartbeatTimestamp = createAction(UPDATE_HEARTBEAT_TS);
export const enableHeartbeat = createAction(ENABLE_HEARTBEAT);
export const disableHeartbeat = createAction(DISABLE_HEARTBEAT);
export const checkHeartbeat = createAction(CHECK_HEARTBEAT);

export const subscribeMark = createAction(SUBSCRIBE_MARK);
export const subscribeSpot = createAction(SUBSCRIBE_SPOT);
export const subscribeTicker = createAction(SUBSCRIBE_TICKER);
export const unsubscribeTicker = createAction(UNSUBSCRIBE_TICKER);
export const subscribeFunding = createAction(SUBSCRIBE_FUNDING);
export const unsubscribeFunding = createAction(UNSUBSCRIBE_FUNDING);
export const subscribeL2Orderbook = createAction(SUBSCRIBE_L2_ORDERBOOK);
export const unsubscribeL2Orderbook = createAction(UNSUBSCRIBE_L2_ORDERBOOK);
export const subscribeRecentTrade = createAction(SUBSCRIBE_RECENT_TRADE);
export const subscribePublicChannels = createAction(SUBSCRIBE_PUBLIC_CHANNELS);
export const subscribeTradingNotifications = createAction(
  SUBSCRIBE_TRADING_NOTIFICATIONS
);
export const subscribeOHLCCandle = createAction(SUBSCRIBE_OHLC_CANDEL);
export const unsubscribeOHLCCandle = createAction(UNSUBSCRIBE_OHLC_CANDEL);

export const subscribeOrders = createAction(SUBSCRIBE_ORDERS);
export const unsubscribeAll = createAction(UNSUBSCRIBE_ALL);
export const unsubscribeObRt = createAction(UNSUBSCRIBE_OB_RT);
export const unsubscribePrivateChannels = createAction(UNSUBSCRIBE_PRIVATE);

export const subscribeSelectedProductMarkData = createAction(
  SUBSCRIBE_SELECTED_PRODUCT_MARK_DATA
);
export const unsubscribeSelectedProductMarkData = createAction(
  UNSUBSCRIBE_SELECTED_PRODUCT_MARK_DATA
);

export const subscribeSelecteProductSpot = createAction(SUBSCRIBE_SELECTED_PRODUCT_SPOT);
export const unsubscribeSelecteProductSpot = createAction(
  UNSUBSCRIBE_SELECTED_PRODUCT_SPOT
);

export const subscribeWalletSpot = createAction(SUBSCRIBE_WALLET_SPOT);
export const unsubscribeWalletSpot = createAction(UNSUBSCRIBE_WALLET_SPOT);

export const subscribeOptionChainMark = createAction(SUBSCRIBE_OPTION_CHAIN_MARK);

export const unsubscribeOptionChainMark = createAction(UNSUBSCRIBE_OPTION_CHAIN_MARK);

export const subscribeBestBidBestAsk = createAction(SUBSCRIBE_BEST_BID_BEST_ASK);

export const unsubscribeBestBidBestAsk = createAction(UNSUBSCRIBE_BEST_BID_BEST_ASK);

export const subscribeOpenPostionMark = createAction(SUBSCRIBE_OPEN_POSITION_MARK);

export const unsubscribeMark = createAction(UNSUBSCRIBE_MARK);

export const subscribeEasyOptionsOrderbook = createAction(
  SUBSCRIBE_EASY_OPTIONS_ORDERBOOK
);
export const unsubscribeEasyOptionsOrderbook = createAction(
  UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK
);

const subscribeContractAllRecentTrades = createAction(
  SUBSCRIBE_CONTRACT_ALL_RECENT_TRADES
);

const unsubscribeContractAllRecentTrades = createAction(
  UNSUBSCRIBE_CONTRACT_ALL_RECENT_TRADES
);

export const subscribeOpenPositionSpot = createAction(SUBSCRIBE_OPEN_POSITION_SPOT);

export const unsubscribeOpenPositionSpot = createAction(UNSUBSCRIBE_OPEN_POSITION_SPOT);

const connectPublicSocket = () => ({
  type: CONNECT_PUBLIC_SOCKET,
});

const disconnectPublicSocket = (payload: { retry: boolean } = { retry: false }) => ({
  type: DISCONNECT_PUBLIC_SOCKET,
  payload,
});

const publicSocketConnected = (payload: boolean) => ({
  type: PUBLIC_SOCKET_CONNECTED,
  payload,
});

const enableHeartbeatPublic = () => ({
  type: ENABLE_HEARTBEAT_PUBLIC,
});

const disableHeartbeatPublic = () => ({
  type: DISABLE_HEARTBEAT_PUBLIC,
});

const checkHeartbeatPublic = () => ({
  type: CHECK_HEARTBEAT_PUBLIC,
});

const connectSelectedProductChannelPublic = () => ({
  type: CONNECT_SELECTED_PRODUCT_CHANNEL_PUBLIC,
});

export {
  subscribeContractAllRecentTrades,
  unsubscribeContractAllRecentTrades,
  connectPublicSocket,
  disconnectPublicSocket,
  publicSocketConnected,
  enableHeartbeatPublic,
  disableHeartbeatPublic,
  connectSelectedProductChannelPublic,
  checkHeartbeatPublic,
};
