/* eslint-disable import/no-unresolved */
import createAction from 'helpers/createAction';

import {
  CHART_SUBSCRIPTION_PAYLOAD,
  MARK_PRICE_CHART_UPDATE,
  TOGGLE_FULL_SCREEN_MODE,
} from '../actionTypes/tradingView';

export const updateMarkPriceChart = createAction(MARK_PRICE_CHART_UPDATE);
export const addChartSubscriptionPayload = createAction(CHART_SUBSCRIPTION_PAYLOAD);
export const toggleFullScreenMode = createAction(TOGGLE_FULL_SCREEN_MODE);
