const PUBLIC_SOCKET_CHANNELS = {
  L2_ORDERBOOK: 'c_l2ob',
} as const;

const PUBLIC_SOCKET_OUTGOING_MESSAGE_TYPES = {
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
  ENABLE_HEARTBEAT: 'enable_heartbeat',
  DISABLE_HEARTBEAT: 'disable_heartbeat',
} as const;

const PUBLIC_SOCKET_INCOMING_MESSAGE_TYPES = {
  L2_ORDERBOOK: 'c_l2ob',
} as const;

export {
  PUBLIC_SOCKET_CHANNELS,
  PUBLIC_SOCKET_OUTGOING_MESSAGE_TYPES,
  PUBLIC_SOCKET_INCOMING_MESSAGE_TYPES,
};
