import { isAndroid } from 'react-device-detect';

import {
  IS_INDIAN_EXCHANGE,
  IS_RN_WEBVIEW,
  IND_DOMAIN_SWITCH,
  isTestnet,
} from 'constants/constants';

import {
  FEATURE_FLAG_SCAN_TO_LOGIN_SUPPORTED,
  FEATURE_FLAG_TRADE_SUCCESS_CARD_SUPPORTED,
  FEATURE_FLAG_HYPERVERGE_CUSTOM_INPUT_SUPPORTED,
} from './rnApp';

const SIGNAL_TRADING_FEATURE_ENABLED =
  process.env.REACT_APP_FEATURE_FLAG_SIGNAL_TRADING === 'true' && IS_INDIAN_EXCHANGE;

const SIGNUP_CARDS_FLAG = IS_INDIAN_EXCHANGE ? 'india' : 'global';

const SHOW_DELTA_INDIA_LOGIN_LINK = !IS_INDIAN_EXCHANGE;

const TRADE_PREFERENCES_DETO_FOR_COMISSION = !IS_INDIAN_EXCHANGE;

const INVOICES_TAB = IS_INDIAN_EXCHANGE;

const ENABLE_INR_TOGGLE_IN_ACCOUNT_ANALYTICS = IS_INDIAN_EXCHANGE;

const RECOVER_USER_ACCOUNT_FEATURE_ENABLED = IS_INDIAN_EXCHANGE;

const SHOW_DELTA_INDIA_ANALYTICS_REDIRECTION_LINK = !IS_INDIAN_EXCHANGE;

const SHOW_TRADE_SUCCESS_SHARE_CARDS =
  IS_INDIAN_EXCHANGE && FEATURE_FLAG_TRADE_SUCCESS_CARD_SUPPORTED;
const SHOW_DELTA_GLOBAL_LOGIN_LINK_ON_LOGIN_PAGE =
  IS_INDIAN_EXCHANGE && !IS_RN_WEBVIEW && IND_DOMAIN_SWITCH;

const SCALPING_ENABLED = IS_INDIAN_EXCHANGE;

const SHOW_DELTA_GLOBAL_BANK_WITHDRAWAL_PAGE = false;

const ENABLE_TRACK_DEPOSIT = IS_INDIAN_EXCHANGE;

const SCAN_TO_LOGIN_FEATURE_ENABLED =
  process.env.REACT_APP_FEATURE_FLAG_SCAN_TO_LOGIN === 'true';

const SHOW_SCANNER_FOR_LOGIN_VIA_QR =
  SCAN_TO_LOGIN_FEATURE_ENABLED && FEATURE_FLAG_SCAN_TO_LOGIN_SUPPORTED;

const SHOW_QR_SCREEN_FOR_LOGIN_VIA_QR = SCAN_TO_LOGIN_FEATURE_ENABLED && !IS_RN_WEBVIEW;

const SHOW_DELTA_GLOBAL_LOGIN_LINK = !IS_RN_WEBVIEW && IND_DOMAIN_SWITCH && !isTestnet;

const DISABLE_2FA_FACE_AUTH_ENABLED = IS_RN_WEBVIEW
  ? FEATURE_FLAG_HYPERVERGE_CUSTOM_INPUT_SUPPORTED && IS_INDIAN_EXCHANGE
  : IS_INDIAN_EXCHANGE;

const ENABLE_MIXPANEL_SESSION_REPLAY =
  process.env.REACT_APP_FEATURE_FLAG_ENABLE_MIXPANEL_SESSION_REPLAY === 'true' &&
  IS_INDIAN_EXCHANGE;

const SHOW_DOWNLOAD_APP_BANNER = isAndroid && !IS_RN_WEBVIEW && !isTestnet;
const SHOW_FIAT_VALUE_IN_BELL_ALERTS = IS_INDIAN_EXCHANGE;
const IMPORT_KYC_FEATURE_ENABLED = IS_INDIAN_EXCHANGE;

const SHOW_DOWNLOAD_APP_ICON = !IS_RN_WEBVIEW && !isTestnet;

const GRACE_KYC_EXPIRY_AVAILABLE = IS_INDIAN_EXCHANGE;

export {
  SIGNAL_TRADING_FEATURE_ENABLED,
  SHOW_DELTA_INDIA_LOGIN_LINK,
  SIGNUP_CARDS_FLAG,
  TRADE_PREFERENCES_DETO_FOR_COMISSION,
  INVOICES_TAB,
  ENABLE_INR_TOGGLE_IN_ACCOUNT_ANALYTICS,
  RECOVER_USER_ACCOUNT_FEATURE_ENABLED,
  SHOW_DELTA_INDIA_ANALYTICS_REDIRECTION_LINK,
  SHOW_DELTA_GLOBAL_LOGIN_LINK_ON_LOGIN_PAGE,
  SHOW_TRADE_SUCCESS_SHARE_CARDS,
  SCALPING_ENABLED,
  SHOW_DELTA_GLOBAL_BANK_WITHDRAWAL_PAGE,
  ENABLE_TRACK_DEPOSIT,
  SHOW_SCANNER_FOR_LOGIN_VIA_QR,
  SHOW_QR_SCREEN_FOR_LOGIN_VIA_QR,
  SHOW_DELTA_GLOBAL_LOGIN_LINK,
  DISABLE_2FA_FACE_AUTH_ENABLED,
  ENABLE_MIXPANEL_SESSION_REPLAY,
  SHOW_DOWNLOAD_APP_BANNER,
  SHOW_FIAT_VALUE_IN_BELL_ALERTS,
  IMPORT_KYC_FEATURE_ENABLED,
  SHOW_DOWNLOAD_APP_ICON,
  GRACE_KYC_EXPIRY_AVAILABLE,
};
