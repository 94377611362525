import dayjs, { Dayjs, ManipulateType, OpUnitType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration, { Duration } from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);
dayjs.extend(minMax);
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const DATE_TIME_LONG = 'MMM DD YYYY h:mm:ss a';
const IST_TIMEZONE = 'Asia/Kolkata';

export type DateType = Date | Dayjs | string | number;

/**
 * @description Use this function to get a formatted date string
 */
function dateFormat(
  dateToFormat: DateType,
  formatString: string = DATE_TIME_LONG
): string {
  let date = dateToFormat;
  if (!dateToFormat) date = dayjs();
  return dayjs(date).format(formatString);
}

/**
 * @description Use this function to get a current time as a dayjs object
 */
function timeNow(): Dayjs {
  return dayjs();
}

/**
 * @description Use this function to get a current IST time as a dayjs object
 */
function istTimeNow(): Dayjs {
  return dayjs().tz(IST_TIMEZONE);
}

/**
 * @description Parse any Date type and returns a dayjs object
 */
function getDateTime(date?: DateType, tz?: string): Dayjs {
  if (!date) return timeNow();
  if (!tz) return dayjs(date);
  return dayjs(date).tz(tz);
}

/**
 * @description Converts date to UTC standard
 * Note: for timestamp as date it should be in milliseconds
 */
function toUTC(date: DateType, format?: string): Dayjs {
  return dayjs.utc(date, format);
}

/**
 * Converts UTC date to Local
 * Note: for timestamp as date it should be in milliseconds
 */
function toUTCLocal(date: DateType, format?: string) {
  return toUTC(date, format).local();
}

/**
 * @description Use this function to get the timestamp of a date in milliseconds
 */
function getTimestampInMS(date: DateType): number {
  return dayjs(date).valueOf();
}

/**
 * @description Use this function to get the timestamp of a date in seconds
 */
function getUnixTimestamp(date: DateType): number {
  return dayjs(date).unix();
}

/**
 * @description Get end of a date unit. Eg. End of a day or end of month
 */
function endOf(date: DateType, unit: OpUnitType = 'day'): Dayjs {
  return dayjs(date).endOf(unit);
}

/**
 * @description Get start of a date unit. Eg. Start of a day or start of month
 */
function startOf(date: DateType, unit: OpUnitType = 'day'): Dayjs {
  return dayjs(date).startOf(unit);
}

/**
 * @description Subtracts number of time from a date. Eg: Subtracting 1day from 20-12-2022 is 19-12-2022. Returns dayjs object
 */
function subtract(date: Dayjs, num: number, unit: ManipulateType = 'day'): Dayjs {
  return date.subtract(num, unit);
}

/**
 * @description Adds number of time from a date. Eg: Adding 1day from 20-12-2022 is 21-12-2022. Returns dayjs object
 */
function add(date: Dayjs, num: number, unit: ManipulateType = 'day'): Dayjs {
  return date.add(num, unit);
}

/**
 * @description Subtract a date from another. Returns number of time unit
 */
function getDiff(
  date1: DateType,
  date2: DateType,
  unit: OpUnitType = 'day',
  shouldFloatValue: boolean = false
): number {
  const firstDate = dayjs(date1);
  const secondDate = dayjs(date2);
  return firstDate.diff(secondDate, unit, shouldFloatValue);
}

/**
 * @description Get the maximum of two or more dates. Returns dayjs object
 */
function max(...args: DateType[]): Dayjs {
  const dates = args.map(day => dayjs(day));
  return dayjs.max(...dates);
}

/**
 * @description Get the minimum of two or more dates. Returns dayjs object
 */
function min(...args: DateType[]): Dayjs {
  const dates = args.map(day => dayjs(day));
  return dayjs.min(...dates);
}

/**
 * @description Get the clone of a dayjs object
 */
function cloneDate(arg: DateType): Dayjs {
  const originalDate = dayjs(arg);
  return originalDate.clone();
}

/**
 * @description Get the hour value from the date. Returns dayjs object or number
 */
function getHour(date: DateType, hour: number): Dayjs | number {
  const originalDate = getDateTime(date);
  if (hour === null || hour === undefined) return originalDate.hour();
  return originalDate.hour(hour);
}

/**
 * @description Get the minute value from the date. Returns dayjs object or number
 */
function getMinute(date: DateType, minute: number): Dayjs | number {
  const originalDate = getDateTime(date);
  if (minute === null || minute === undefined) return originalDate.minute();
  return originalDate.minute(minute);
}

/**
 * @description Get the second value from the date. Returns dayjs object or number
 */
function getSeconds(date: DateType, seconds: number): Dayjs | number {
  const originalDate = getDateTime(date);
  if (seconds === null || seconds === undefined) return originalDate.second();
  return originalDate.second(seconds);
}

/**
 * @description Checks if one date is after another or not. Returns boolean value
 */
function isDateAfter(
  date1: DateType,
  date2: DateType,
  unit: OpUnitType = 'milliseconds'
): boolean {
  const firstDate = dayjs(date1);
  const secondDate = dayjs(date2);
  return firstDate.isAfter(secondDate, unit);
}

/**
 * @description Parse date with a format
 */
function parseFormat(...args: DateType[]): Dayjs {
  if (!args.length) return timeNow();
  if (args.length === 1) return getDateTime(args[0]);
  return dayjs(...args);
}

/**
 * @description Get the duration object from a number value
 */
function getDuration(time: number): Duration {
  return dayjs.duration(time);
}

/**
 * @description Get the duration object as days from a number value
 */
function getDurationAsDays(time: number): number {
  return dayjs.duration(time).asDays();
}

/**
 * @description Get the duration object as hours from a number value
 */
function getDurationAsHours(time: number): number {
  return dayjs.duration(time).asHours();
}

/**
 * @description Get the duration object as minutes from a number value
 */
function getDurationAsMinutes(time: number): number {
  return dayjs.duration(time).asMinutes();
}

/**
 * @description Get the duration object as seconds from a number value
 */
function getDurationAsSeconds(time: number): number {
  return dayjs.duration(time).asSeconds();
}

/**
 * @description Get native javascript date
 */
function getNativeDate(date: DateType): Date {
  return dayjs(date).toDate();
}

/**
 * @description Get the local value of a date
 */
function toLocal(date: DateType, flag: boolean = false) {
  // @ts-ignore
  return dayjs(date).local(flag);
}

/**
 * @description Get the duration to a date from now. Returns dayjs object
 */
function getFromNow(date: DateType, suffix: boolean = false): DateType {
  return dayjs(date).fromNow(suffix);
}

/**
 * @description Get the calendar time of a date
 */
function getCalendarTime(
  date: DateType,
  referenceDate: DateType,
  options: object = {}
): string {
  return dayjs(date).calendar(referenceDate, options);
}

/**
 * @description Checks if two dates are same or not
 */
function isDateSame(
  date1: DateType,
  date2: DateType,
  unit: OpUnitType = 'milliseconds'
): boolean {
  return dayjs(date1).isSame(dayjs(date2), unit);
}

function getParsableFormat(str: string): string {
  const dateArr = str.match(/.{2}/g) as string[];
  const middleElem = dateArr[1];
  dateArr[1] = dateArr[0];
  dateArr[0] = middleElem;
  return dateArr.join('/');
}

/**
 * @description Get a dayjs object from a Unix Timestamp,
 * Unix Timestamp should be in seconds.
 */
function fromUnixTimestamp(timestamp: number) {
  return dayjs.unix(timestamp);
}

function getUTCOffset(): number {
  return dayjs().utcOffset();
}

/**
 * Format: 10000 (seconds) --> 02h:46m:40s
 * Useful for count downs.
 */
function formatSecondsToHMS(totalSeconds: number) {
  const secondsIn1Hour = 60 * 60;
  let hours: number | string = Math.floor(totalSeconds / secondsIn1Hour);
  let minutes: number | string = Math.floor((totalSeconds - hours * secondsIn1Hour) / 60);
  let seconds: number | string = totalSeconds % 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${
    (hours === '00' ? '' : `${hours}h:`) +
    (hours === '00' && minutes === '00' ? '' : `${minutes}m:`)
  }${seconds}s`;
}

/** @description returns true when test date is in the range */
const isDateWithin = (start: DateType, end: DateType, testDate: DateType) =>
  toUTC(testDate).diff(toUTC(start)) >= 0 && toUTC(testDate).diff(toUTC(end)) <= 0;

const formatSecondsToDHMS = totalSeconds => {
  const secondsIn1Day = 60 * 60 * 24;
  const secondsIn1Hour = 60 * 60;
  const secondsIn1Minute = 60;

  const days = Math.floor(totalSeconds / secondsIn1Day);
  const hours = Math.floor((totalSeconds % secondsIn1Day) / secondsIn1Hour);
  const minutes = Math.floor((totalSeconds % secondsIn1Hour) / secondsIn1Minute);

  return `${days}d:${hours}h:${minutes}m`;
};

const formatTimeInMinsOrHrs = seconds => {
  const timeDuration = dayjs.duration(seconds, 'seconds');

  if (timeDuration.hours() >= 1) {
    return `${timeDuration.hours()}hr${timeDuration.hours() > 1 ? 's' : ''}`;
  }
  return `${timeDuration.minutes()}min${timeDuration.minutes() > 1 ? 's' : ''}`;
};

export {
  dateFormat,
  timeNow,
  istTimeNow,
  toUTC,
  getTimestampInMS,
  getUnixTimestamp,
  endOf,
  startOf,
  getDateTime,
  subtract,
  add,
  getDiff,
  max,
  min,
  cloneDate,
  getHour,
  getMinute,
  getSeconds,
  isDateAfter,
  parseFormat,
  getDuration,
  getDurationAsDays,
  getDurationAsHours,
  getDurationAsMinutes,
  getDurationAsSeconds,
  getNativeDate,
  toLocal,
  getFromNow,
  getCalendarTime,
  isDateSame,
  getParsableFormat,
  fromUnixTimestamp,
  dayjs,
  toUTCLocal,
  getUTCOffset,
  formatSecondsToHMS,
  isDateWithin,
  formatSecondsToDHMS,
  formatTimeInMinsOrHrs,
  IST_TIMEZONE,
};
