/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-shadow */
import { batch } from '@risingstack/react-easy-state';

import { pipe, map } from 'helpers/ramda';
import {
  // calcPages,
  calcPrecision,
  calcPriceMovement,
  convertPriceToFixed,
  mapLimitPricetoPrice,
  // pathToString,
  populatePriceMovement,
  // processWithdrawalState,
  // productIdByPayload,
  // productIdByResult,
  // PRIVATE_SOCKET_ACTIONS,
} from 'reducers/helpers/trade';
import { basketOrderOrdersSelector } from 'selectors/basketOrdersSelectors';
import { productsSelector } from 'selectors/tradeSelectors';
import StoreProxy from 'storeProxy';
import {
  ILastTrade,
  // IOrderbooks,
  IBook,
} from 'types/ITrade';
// Refactor these any to specific types
import type { l2OrderbookMessage, OrderBook } from 'types/publicSocket';

import store from '../store';

// export const getOrderBook = (payload: any) => {
//   store.orderbook = { ...payload, showLoader: false };
// };

export const setRecentTradeLoader = () => {
  batch(() => {
    store.orderbook.showLoader = true;
    // store.orderbook.sell = [];
    // store.orderbook.buy = [];
    store.recentTrades = [];
    store.lastTrade = '-';
    store.orderbook.lastSequenceNumber = -1;
  });
};

const convertLimitPriceToPrice = (data: OrderBook, precision: number) =>
  pipe(map(mapLimitPricetoPrice), convertPriceToFixed(precision))(data);

export const updateEasyOptionsOrderbook = (message: l2OrderbookMessage) => {
  batch(() => {
    const { b: bidBook, a: askBook, i: productId, s: symbol } = message;
    const product = productsSelector(StoreProxy.getState())[productId];
    const tickSize = product.tick_size || 0.5;
    const precision = calcPrecision(tickSize);
    const sellBook = convertLimitPriceToPrice(askBook, precision);
    const buyBook = convertLimitPriceToPrice(bidBook, precision);
    store.easyOptionsOrderbook = {
      // @ts-ignore
      buy: buyBook,
      // @ts-ignore
      sell: sellBook,
      product_id: productId,
      symbol,
    };
  });
};

/**
 * @deprecated
 */
export const updateL2Orderbook = payload => {
  batch(() => {
    const { bids, asks, sequence_no, symbol, action } = payload;
    store.l2Updates.sequence_no = sequence_no;
    store.l2Updates.symbol = symbol;

    if (action === 'snapshot') {
      const askMap = new Map(asks);
      const bidsMap = new Map(bids);

      store.l2Updates.bids = bidsMap;
      store.l2Updates.asks = askMap;
    }

    if (action === 'update') {
      bids.forEach((order: any[]) => {
        const price = order[0];
        const size = order[1];
        if (Number(order[1]) === 0 && store.l2Updates.bids.has(price)) {
          store.l2Updates.bids.delete(price);
        }
        if (Number(size) !== 0) {
          store.l2Updates.bids.set(price, size);
        }
      });
      asks.forEach((order: any[]) => {
        const price = order[0];
        const size = order[1];
        if (Number(size) === 0 && store.l2Updates.asks.has(price)) {
          store.l2Updates.asks.delete(price);
        }
        if (Number(size) !== 0) {
          store.l2Updates.asks.set(price, size);
        }
      });
    }
  });
};

export const updateBasketOrderOrderbook = (message: l2OrderbookMessage) => {
  const { b: buy, a: sell, i: productId, s: symbol } = message;
  const product = productsSelector(StoreProxy.getState())[productId];
  const basketOrders = basketOrderOrdersSelector(StoreProxy.getState());
  // check of product is in basket or not
  if (Object.keys(basketOrders).indexOf(productId.toString()) === -1) {
    return;
  }

  const tickSize = product.tick_size || 0.5;
  const precision = calcPrecision(tickSize);

  // todo: convert it into transducer
  const convertLimitPriceToPrice = (data: OrderBook) =>
    pipe(map(mapLimitPricetoPrice), convertPriceToFixed(precision))(data);

  const sellBook: any = convertLimitPriceToPrice(sell);
  const buyBook: any = convertLimitPriceToPrice(buy);

  batch(() => {
    store.basketOrderOrderbooks.buy = buyBook;
    store.basketOrderOrderbooks.sell = sellBook;
    store.basketOrderOrderbooks.product_id = productId;
    store.basketOrderOrderbooks.symbol = symbol;
  });
};

export const updateRecentTrades = (payload: any, selectedProductSymbol) => {
  if (payload.symbol === selectedProductSymbol) {
    const prevTrade: ILastTrade = store.recentTrades[0];
    const trade = calcPriceMovement(payload, prevTrade);
    const { ...tradeProps } = trade;
    const lastTrade: ILastTrade = {
      ...tradeProps,
      datetime: new Date(payload.timestamp / 1000),
    };
    store.recentTrades = [lastTrade, ...store.recentTrades].slice(0, 50);
    store.lastTrade = lastTrade;
  }
};

export const getRecentTrades = (payload: any, selectedProductSymbol) => {
  if (payload.symbol === selectedProductSymbol) {
    const recentTrades: ILastTrade[] = payload.trades;
    const trades: ILastTrade[] = populatePriceMovement(recentTrades);
    store.recentTrades = trades;
    store.lastTrade = trades[0];
  }
};
