import { combineEpics, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, mergeMap, distinctUntilChanged, filter } from 'rxjs/operators';

import { noOp } from 'actions/other';
import { updateOrderType, updateSideSelection } from 'actions/placeorder';
import { updateUserPreference } from 'actions/user';
import { TAB_CHANGE } from 'actionTypes/appDrawer';
import { UPDATE_ORDER_TYPE } from 'actionTypes/placeorder';
import { TRADE_CONSTANTS } from 'actionTypes/trade';
import { PLACE_ORDER_TYPE, SIDE } from 'constants/enums';
import { isFutures, isOptions } from 'helpers/utils';
import { APP_DRAWER_TAB_ID_MAPPING } from 'reducers/initialstate/appDrawer';
import { userOrderTypePreferenceSelector } from 'selectors/otherSelectors';
import { selectedContractTypeSelector } from 'selectors/tradeSelectors';

const VALID_PLACE_ORDER_TYPES = Object.values(PLACE_ORDER_TYPE);

/**
 * When a product is selected, this epic is triggered to select the default PLACE_ORDER_TYPE and SIDE based on the contract type.
 * For futures and options contracts if PLACE_ORDER_TYPE preference is found then that is used as the default.
 */
const setDefaultPlaceOrderTypeEpic = (action$, state$) =>
  action$.pipe(
    ofType(TRADE_CONSTANTS.PRODUCT_SELECTED),
    map(() => selectedContractTypeSelector(state$.value)),
    distinctUntilChanged(),
    mergeMap(selectedContractType => {
      const isFuturesContract = isFutures(selectedContractType);
      const isOptionsContract = isOptions(selectedContractType);

      const newSide = isOptionsContract ? SIDE.SELL : SIDE.BUY;

      const actions = [updateSideSelection(newSide)];

      const lastOrderTypePreference = userOrderTypePreferenceSelector(state$.value) || {};

      let newOrderType = PLACE_ORDER_TYPE.LIMIT;

      if (isFuturesContract || isOptionsContract) {
        const contractTypeKey = isFuturesContract ? 'futures' : 'options';
        const lastOrderTypeSelection = lastOrderTypePreference[contractTypeKey];
        newOrderType =
          lastOrderTypeSelection &&
          VALID_PLACE_ORDER_TYPES.includes(lastOrderTypeSelection)
            ? lastOrderTypeSelection
            : PLACE_ORDER_TYPE.MARKET;
      }

      actions.push(updateOrderType(newOrderType, { shouldUpdatePreference: false }));

      return from(actions);
    })
  );

/**
 * Updates user's PLACE_ORDER_TYPE selection to user's preferences.
 * Currently we only store the preference for futures and options contracts.
 */
const updateUserOrderTypePreferenceEpic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ORDER_TYPE),
    filter(action => action.options.shouldUpdatePreference),
    map(action => {
      const selectedContractType = selectedContractTypeSelector(state$.value);
      const isFuturesContract = isFutures(selectedContractType);
      const isOptionsContract = isOptions(selectedContractType);

      if (!(isFuturesContract || isOptionsContract)) {
        return noOp();
      }

      const lastOrderTypePreference = userOrderTypePreferenceSelector(state$.value) || {};
      const contractTypeKey = isFuturesContract ? 'futures' : 'options';
      const preferenceChanged =
        lastOrderTypePreference[contractTypeKey] !== action.payload;

      if (preferenceChanged) {
        return updateUserPreference(
          {
            preferences: {
              order_type_preference: {
                ...lastOrderTypePreference,
                [contractTypeKey]: action.payload,
              },
            },
          },
          { disable_notification: true }
        );
      }

      return noOp();
    })
  );

const restoreOrderTypeForTradeTabEpic = (action$, state$) =>
  action$.pipe(
    ofType(TAB_CHANGE),
    filter(({ payload }) => payload === APP_DRAWER_TAB_ID_MAPPING.TRADE),
    map(() => {
      const selectedContractType = selectedContractTypeSelector(state$.value);
      const isFuturesContract = isFutures(selectedContractType);
      const isOptionsContract = isOptions(selectedContractType);
      const lastOrderTypePreferenceMap =
        userOrderTypePreferenceSelector(state$.value) || {};
      const contractTypeKey = isFuturesContract ? 'futures' : 'options';
      const lastOrderTypePreference = lastOrderTypePreferenceMap[contractTypeKey];

      if ((isFuturesContract || isOptionsContract) && lastOrderTypePreference) {
        return updateOrderType(lastOrderTypePreference, {
          shouldUpdatePreference: false,
        });
      }

      return noOp();
    })
  );

const placeOrderTypeEpic = combineEpics(
  setDefaultPlaceOrderTypeEpic,
  updateUserOrderTypePreferenceEpic,
  restoreOrderTypeForTradeTabEpic
);

export default placeOrderTypeEpic;
