import {
  CALCULATE_MARGIN_REQUEST,
  RESET_AFTER_SUBMIT,
  SELECTED_QUANTITY_PERCENTAGE,
  START_MAX_NOTIONAL_INTERVAL_ACTION,
  STOP_CALCULATE_MARGIN,
  STOP_MAX_NOTIONAL_INTERVAL_ACTION,
  TOGGLE_CLOSE_POSITION_CONFIRMATION_MODAL,
  TOGGLE_CONFIRMATION_POPUP,
  TOGGLE_QUANTITY_INPUT_HAS_ERROR,
  UPDATE_DONT_SHOW_CONFIRMATION_CHECKBOX,
  UPDATE_DONT_SHOW_CONFIRMATION_ON_CHART,
  UPDATE_DROP_DOWN,
  UPDATE_LIMIT_PRICE,
  UPDATE_MARGIN_VALUE,
  UPDATE_ORDER_SUBMIT_STATUS,
  UPDATE_ORDER_TYPE,
  UPDATE_QUANTITY_INPUT,
  UPDATE_QUANTITY_INPUT_ERROR_MESSAGE,
  UPDATE_QUANTITY_IN_CONTRACTS,
  UPDATE_REDUCE_ONLY,
  UPDATE_SELECTED_PERCENTAGE,
  UPDATE_SIDE_SELECTION,
  USER_INPUT_LEVERAGE,
} from 'actionTypes/placeorder';
import { PLACE_ORDER_TYPE } from 'constants/enums';
import createAction from 'helpers/createAction';

const updateSideSelection = createAction(UPDATE_SIDE_SELECTION);
const updateOrderType = (
  orderType: PLACE_ORDER_TYPE,
  options: { shouldUpdatePreference: boolean } = { shouldUpdatePreference: true }
) => {
  return {
    type: UPDATE_ORDER_TYPE,
    payload: orderType,
    options,
  };
};
const updateDropDown = createAction(UPDATE_DROP_DOWN);
const updateQuantityInput = createAction(UPDATE_QUANTITY_INPUT);
const updateQuantityInputInConts = createAction(UPDATE_QUANTITY_IN_CONTRACTS);
const updateLimitPrice = createAction(UPDATE_LIMIT_PRICE);
const calculateMarginSuccess = createAction(UPDATE_MARGIN_VALUE);
const requestMargin = createAction(CALCULATE_MARGIN_REQUEST);
const stopCalculateMargin = createAction(STOP_CALCULATE_MARGIN);
const updateReduceOnly = createAction(UPDATE_REDUCE_ONLY);
const toggleClosePositionConfirmationModal = createAction(
  TOGGLE_CLOSE_POSITION_CONFIRMATION_MODAL
);
const resetAfterSubmit = createAction(RESET_AFTER_SUBMIT);
const toggleConfirmationPopup = createAction(TOGGLE_CONFIRMATION_POPUP);
const toggleQuantityInputHasError = createAction(TOGGLE_QUANTITY_INPUT_HAS_ERROR);
const updateQuantityInputErrorMessage = createAction(UPDATE_QUANTITY_INPUT_ERROR_MESSAGE);
const updateDontShowConfirmationCheckbox = createAction(
  UPDATE_DONT_SHOW_CONFIRMATION_CHECKBOX
);
const updateDontShowConfirmationOnChart = createAction(
  UPDATE_DONT_SHOW_CONFIRMATION_ON_CHART
);
const updateSelectedPercentage = createAction(UPDATE_SELECTED_PERCENTAGE);
const updateOrderSubmitStatus = createAction(UPDATE_ORDER_SUBMIT_STATUS);
const startMaxNotionalIntervalAction = createAction(START_MAX_NOTIONAL_INTERVAL_ACTION);
const stopMaxNotionalIntervalAction = createAction(STOP_MAX_NOTIONAL_INTERVAL_ACTION);
const userInputLeverage = createAction(USER_INPUT_LEVERAGE);
const selectedQuantityPercentage = createAction(SELECTED_QUANTITY_PERCENTAGE);

export {
  calculateMarginSuccess,
  requestMargin,
  resetAfterSubmit,
  selectedQuantityPercentage,
  startMaxNotionalIntervalAction,
  stopCalculateMargin,
  stopMaxNotionalIntervalAction,
  toggleClosePositionConfirmationModal,
  toggleConfirmationPopup,
  toggleQuantityInputHasError,
  updateDontShowConfirmationCheckbox,
  updateDontShowConfirmationOnChart,
  updateDropDown,
  updateLimitPrice,
  updateOrderSubmitStatus,
  updateOrderType,
  updateQuantityInput,
  updateQuantityInputErrorMessage,
  updateQuantityInputInConts,
  updateReduceOnly,
  updateSelectedPercentage,
  updateSideSelection,
  userInputLeverage,
};
