import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

import Button from 'UIKit/Button';

export const PopoverBaseStyle = styled.span`
  cursor: pointer;
  padding: 0 5px;

  .popover-base {
    width: ${props => props.width || '12px'};
    height: ${props => props.height || '12px'};
  }
`;

export const StyledPopover = withStyles(theme => ({
  tooltip: {
    padding: 0,
    backgroundColor: theme.primaryBackground,
  },
  tooltipPlacementTop: {
    marginBottom: 10,
  },
  tooltipPlacementBottom: {
    marginTop: 10,
  },
}))(Tooltip);

export const StyledPopoverWindow = styled.div`
  background-color: var(--tooltipInfoBoxColor);
  opacity: 1;
  line-height: 1.68;
  color: ${props => props.theme.primaryText};
  font-size: 12.1px;
  border-radius: 3px;
  // max-width: 300px;
  // min-width: 80px;
  width: ${props => (props.shouldDisablePortal ? 'max-content' : 'inherit')};
  font-family: var(--fontLight);
  box-shadow: 0px 9px 18px rgb(0 0 0 / 15%);
`;

export const PopoverHead = styled.div`
  padding: ${props => props.headerStyle.padding || '10px 5px'};
  position: relative;

  & .header {
    font-size: 14px;
    line-height: 1.68;
    color: var(--main-text-primary);
    margin-bottom: 5px;
  }

  & .close-icon-btn {
    position: absolute;
    right: 10px;
    top: 6px;
    border: none;
    display: grid;
    &.deto-popup-btn {
      top: 7px;
    }
    &.withdrawal-popup-btn {
      top: 4px;
    }
  }

  & .close-icon {
    width: 8px;
    cursor: pointer;
  }

  & .close-icon-spacer {
    margin-bottom: 5px;
  }

  & .note {
    color: var(--main-text-primary);
    font-size: 12.1px;
    line-height: 1.68;
    margin-top: 5px;
    font-family: var(--fontRegular);
  }

  & .body-wrapper {
    display: flex;
    /* width: 97%; */
  }

  & .body {
    padding-top: ${props => props.addExtraTopMargin && '7px'};
  }

  & .info-icon {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
`;

export const PopoverFooter = styled.div`
  border-top: ${props =>
    !props.fullWidth && `1px solid ${props.theme.primaryBackground}`};
  padding: ${props => !props.fullWidth && '5px 14px 10px 14px'};
  text-align: ${props => !props.fullWidth && props.btnAlignment};
`;

export const FooterButton = styled(Button)`
  background-color: ${props => props.theme.secondaryCTA};
  color: ${props =>
    props.fullWidth ? props.theme.primaryTheme : props.theme.secondaryText};
  border-top: 1px solid
    ${props =>
      props.fullWidth ? props.theme.primaryBackground : props.theme.primaryTheme};
  width: ${props => (props.fullWidth ? '100%' : 'inherit')};
  box-sizing: border-box;
  border-radius: 0;
  font-size: 12.1px;
  line-height: 17px;
  height: ${props => (props.fullWidth ? '30px' : '20px')};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-radius: ${props => !props.fullWidth && '0'};
  cursor: pointer;
  padding: ${props => (props.fullWidth ? '5px 14px 10px 14px' : '0 10px')};

  &:hover {
    background-color: ${props => props.theme.primaryThemeHover};
    color: var(--primaryCtaText);
    border-top-color: ${props => props.theme.primaryThemeHover};

    & .external-link-icon {
      color: ${props => props.theme.primaryCtaText};
    }
  }

  & .external-link-icon {
    width: 13px;
    margin-left: 5px;
    position: relative;
    top: -1px;
  }
`;

export const PopoverBaseCTAWrapper = styled.span`
  display: flex;

  .base-wrapper {
    position: relative;
    width: 40px;
    height: 38px;
    display: block;
    text-align: center;
    &.buy {
      padding: 0;
      background-color: ${props => props.theme.positive};
    }
    &.sell {
      padding: 0;
      background-color: ${props => props.theme.negative};
    }
    &.verify-button {
      padding: 0;
      background-color: ${props => props.theme.primaryTheme};
      position: absolute;
      right: 0;
      top: 15px;
      svg {
        margin-top: 12px;
        g g {
          fill: var(--main-text-primary);
        }
      }
      @media screen and (max-width: 1023px) {
        top: 16px;
      }
    }
  }

  .base {
    width: 100%;
    height: 100%;
    display: block;

    & svg {
      width: 17px;
      height: 17px;
      margin-top: 10px;
    }
  }
`;
