import BOTS from 'actionTypes/bots';

import { authAction } from './user';

export function getTradingStrategies() {
  return authAction({
    types: BOTS.STRATEGIES,
    promise: ({ request }) => request.get('/bots/strategies'),
  });
}

export function deployStrategy(strategyId: string) {
  return authAction({
    types: BOTS.DEPLOY,
    promise: ({ request }) => request.get(`/bots/strategy/${strategyId}/deploy`),
  });
}

export function stopStrategy(strategyId: string) {
  return authAction({
    types: BOTS.STOP,
    payload: strategyId,
    promise: ({ request }) => request.post(`/bots/strategy/${strategyId}/stop`),
  });
}

export function getActiveStrategies() {
  return authAction({
    types: BOTS.ACTIVE_STRATEGIES,
    promise: ({ request }) => request.get(`/bots/containers`),
  });
}
