/* eslint-disable camelcase */

import type { API_STATUS, PLACE_ORDER_TYPE } from 'constants/enums';

export interface IUserKernel {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  sha256EmailHexString: string;
  userName?: string;
  password?: string;
  token?: string;
  country?: string;
  qrUrl?: string;
  googleSecret?: string;
  seenIntro?: boolean;
  kyc_status?: string;
  withdrawal_blocked_till?: string | null;
  withdrawalPendingCount?: number;
  kyc_vendor?: string;
  idfy_link?: string;
  nick_name?: string;
  kyc_certificates?: {};
  loading?: boolean;
  deposit_count?: null;
  preferences?: Preferences;
  referral_code?: string;
  accountActivity?: any[];
  activeSessions?: any[];
  profile?: Profile;
  isMfaEnabled?: boolean;
  enableBots?: boolean;
  is_kyc_done?: boolean;
  is_kyc_provisioned?: boolean;
  expires_at?: number;
  availableLimit?: string;
  availableLifeTimeLimit?: string;
  totalLimit?: string;
  totalLifeTimeLimit?: string;
  phone_verification_status?: 'unverified' | 'verified';
  proof_of_address_status?: PROOF_STATUS;
  proof_of_identity_status?: PROOF_STATUS;
  phone_number?: string;
  date_of_birth?: string;
  name?: string;
  account_name?: string;
  net_equity?: number;
  is_sub_account?: boolean;
  sub_accounts?: IUserKernel[];
  is_login_enabled?: boolean;
  is_withdrawal_enabled?: boolean;
  is_password_set?: boolean;
  is_mfa_enabled?: boolean;
  is_kyc_refresh_required?: boolean;
  show_kyc_refresh_popup?: boolean;
  show_reduce_only_popup?: boolean;
  refresh_kyc_offer?: string;
  kyc_expiry_date?: string;
  kyc_rejection_reason?: string;
  margin_mode?: string;
  pf_index_symbol?: string | null;
  tracking_info: TrackingInfo | null;
  max_sub_accounts_limit?: number;
  kyc_verified_on: string | null;
  phone_verified_on: Date;
  phone_verification_token: string;
  force_change_password?: boolean;
  force_change_mfa?: boolean;
  showPasswordChangePopup?: boolean;
  show2faResetPopup?: boolean;
  kycShareConsentPopupConfig?: {
    show: boolean;
    showWelcomeScreen?: boolean;
  };
  mfa_updated_at?: Date;
  login_count?: number;
  permissions: null | UserPermissions;
  sub_account_permissions: null | {
    is_login_enabled?: boolean;
    is_withdrawal_enabled?: boolean;
  };
  phishing_code: string;
  pre_2fa_token: string;
  isBankAccountAdded: boolean;
  trackierMobileId?: string;
  region?: string;
  occupation?: string;
  income?: string;
  tradeSucessShareCardDataLoading: boolean;
  tradeSucessShareCardData: TradeSucessShareCardData;
  userGlobalKycData: UserGlobalKycData | null;
}

export interface TradeSucessShareCardData {
  avgAccountEquity: string;
  numberOfTrades: number;
  realizedPnl: string;
  returnOnEquity: string;
  winRate: number;
}

export interface TrackingInfo {
  appsflyer_id?: string;
  device_os_type?: string;
  ga_cid?: string;
  kyc_consent?: string[];
  vendor?: string;
  sub_account_config?: SubAccountConfig;
  marketing_offer_value?: string;
  utm_params: { [key: string]: string };
  app_review?: Record<string, string>;
  duplicate_kyc_email?: string;
  if_ftd_gtm_tracked?: boolean;
  is_first_trade_gtm_tracked?: boolean;
  is_14D_fees_gtm_tracked?: boolean;
  duplicate_account_deleted?: boolean;
}

interface SubAccountConfig {
  cross?: number;
  isolated?: number;
  portfolio?: { [key: string]: number };
}

export enum PROOF_STATUS {
  APPROVED = 'approved',
  PENDING = 'pending',
  WAITING_FOR_APPROVAL = 'waiting_for_approval',
  IN_REVIEW = 'in_review',
  RECAPTURE_PENDING = 'recapture_pending',
  REJECTED = 'rejected',
  INITIATED = 'initiated',
}

export default interface IUser extends IUserKernel {
  sub_accounts?: IUserKernel[];
  main_account?: IUserKernel;
}

// export interface KycCertificates {}

export interface UserPermissions {
  deposit?: 'disabled';
  withdrawal?: 'disabled';
  spot?: 'disabled' | 'reduce-only';
  trading_derivatives?: 'disabled';
  robo_withdrawal?: 'disabled';
  /**
   * (is_bank_withdrawal_allowed: false) AND (fiat_withdrawal: 'disabled') both represent the same thing
   * and are currently used interchangibly on backend. is_bank_withdrawal_allowed will become deprecated in future.
   */
  is_bank_withdrawal_allowed?: boolean;
  fiat_withdrawal?: 'disabled';
  is_alpyne_onramp_enable?: boolean;
  is_onmeta_onramp_enable?: boolean;
  sub_account_crypto_transfer?: 'disabled';
  crypto_deposit?: 'disabled';
  fiat_deposit?: 'disabled';
  de_internal_flow?: boolean;
  delete_bank?: 'enabled' | 'disabled';
  compliance_comment?: string;
  is_chatsupport_enabled?: boolean;
}

type OrderTypePreference = {
  options?: PLACE_ORDER_TYPE;
  futures?: PLACE_ORDER_TYPE;
};

export interface Preferences {
  alwaysShowOnMobile?: boolean;
  blinkingEnabled?: boolean;
  confirmation_messages?: boolean;
  fatFingerProtection?: boolean;
  favorites?: number[];
  limitOrder?: boolean;
  marketOrder?: boolean;
  positionForSocialShare?: boolean;
  positionSizeForSocialShare?: boolean;
  preferred_quantity_currency?: PreferredQuantityCurrency;
  quantity_dropdown_preference?: QuantityDropdownPreference;
  order_type_preference?: OrderTypePreference;
  safe_session?: boolean;
  showOnlyFavsOnTicker?: boolean;
  showTicker?: boolean;
  stopLimitOrder?: boolean;
  stopMarketOrder?: boolean;
  takeProfitLimitOrder?: boolean;
  takeProfitMarketOrder?: boolean;
  tradeDetailsForScoialShare?: boolean;
  tradeOptionsTableSelectedCols?: TradeOptionsTableSelectedCols;
  tradeToOrderBookedPriceFill?: boolean;
  trailingStopOrder?: boolean;
  trollBoxEnabled?: boolean;
  default_auto_topup?: boolean;
  showCloseAllPosition?: boolean;
  orderPlacedFromChartsSelector?: boolean;
  showOrderOnChart?: boolean;
  showMarketOrdersForOptionsInBracket?: boolean;
}

export interface InPageNotifications {
  orderSubmitted?: boolean;
  orderCancelled?: boolean;
  orderFilled?: boolean;
  orderAmended?: boolean;
  stopTriggered?: boolean;
  liquidation?: boolean;
  autoDeleverage?: boolean;
  deposit?: boolean;
  withdrawalSent?: boolean;
}

export interface PreferredQuantityCurrency {
  btc_settled_quantity_currency?: null;
  usdt_settled_quantity_currency?: string;
}

export interface QuantityDropdownPreference {
  inverse?: string | null;
  vanilla?: {
    usdt_settled?: string | null;
    non_usdt_settled?: string | null;
  };
}

export interface TradeOptionsTableSelectedCols {
  Ask?: boolean;
  AskQty?: boolean;
  Bid?: boolean;
  BidQty?: boolean;
  Delta?: boolean;
  MarkPrice?: boolean;
  OI?: boolean;
}

export interface Profile {
  country?: string;
  deposit_info?: DepositInfo[];
  deto_invested_amount?: string;
  favourite_contracts?: any[];
  first_7_days_trading_volume?: string;
  first_trades_data?: {
    first_futures_trade_time: string | null;
    first_options_long_trade_time: string | null;
    first_options_short_trade_time: string | null;
    first_trade_time: string | null;
  };
  is_app_login?: boolean;
  last_30_days_trading_volume?: string;
  last_7_days_trading_volume?: string;
  last_login_date?: Date;
  net_deposit_for_offers?: NetDepositForOffers;
  offer_claimed?: OfferClaimed[];
  referral_bonus?: string;
  registration_date?: string;
  robo_trading_equity?: any[];
  trading_volume?: string;
  volume_for_offers?: VolumeForOffers;
  login_count: number | null;
  isNewUser?: boolean;
  user_role: 'normal' | 'corporate';
  signup_referral_code?: string | null;
}

export interface NetDepositForOffers {
  deposit_bonus?: DepositBonus;
  new_welcome_bonus?: DepositBonus;
  deposit_mega_bonus?: DepositBonus;
  monthly_millionaire?: DepositBonus;
  weekly_delight?: DepositBonus;
}

export type Banners = Record<string, boolean>;

export interface DepositBonus {
  offer_id?: number;
  offer_type?: string;
  required_deposit_in_usdt?: string;
}
export interface DepositInfo {
  amount: string;
  asset_symbol: string;
  created_at: string;
  network?: string;
}

export interface OfferClaimed {
  offer_id?: number;
  offer_type?: string;
}

export interface VolumeForOffers {
  monthly_millionaire?: MonthlyMillionaire;
  weekly_delight?: MonthlyMillionaire;
}

export interface UserGlobalKycData {
  is_kyc_done: boolean;
  apiStatus: API_STATUS;
}

export interface MonthlyMillionaire {
  offer_id?: number;
  offer_type?: string;
  required_trading_volume_usdt?: string;
}
export enum LoggedUserType {
  MAIN_ACCOUNT_LOGIN = 0,
  SUB_ACCOUNT_LOGIN = 1,
  SWITCHED_SUB_ACCOUNT = 2,
}
