import { ungzip } from 'pako';
import { combineEpics, ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, mergeMap, share, takeUntil } from 'rxjs/operators';

import {
  SUBSCRIBE_BASKET_ORDER_ORDERBOOK,
  UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK,
} from 'actionTypes/basketorders';
import { L2OrderbookActionTypes } from 'actionTypes/l2Orderbook';
import {
  SUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  SUBSCRIBE_L2_ORDERBOOK,
  // UNSUBSCRIBE_ALL,
  UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK,
  UNSUBSCRIBE_L2_ORDERBOOK,
  // UNSUBSCRIBE_OB_RT,
} from 'actionTypes/socket';
import { isEmpty } from 'helpers/utils';
// import { publicSocketActiveSelector } from 'selectors/socketSelectors';
import {
  updateBasketOrderOrderbook,
  updateEasyOptionsOrderbook,
} from 'variableStore/actions';

import { getComputedOrderbookFromSocket } from '../reduxInWorker/computeOrderbook';
import { publicWsSubject } from './publicSocket';
import { logToSentry, payloadGenerator } from './socket';

const stringToBase64Buffer = input => {
  // Decode Base64 string to binary data
  const binaryString = atob(input);

  // Create a Uint8Array buffer from the binary string
  const buffer = new Uint8Array(binaryString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binaryString.length; i++) {
    buffer[i] = binaryString.charCodeAt(i);
  }

  return buffer;
};

const decompress = input => {
  const decompressedString = ungzip(stringToBase64Buffer(input), { to: 'string' });
  return JSON.parse(decompressedString);
};

const l2OrderbookChannel = symbol => {
  return publicWsSubject
    .multiplex(
      () => payloadGenerator('subscribe', 'c_l2ob', [symbol]),
      () => payloadGenerator('unsubscribe', 'c_l2ob', [symbol]),
      // () => {
      //   // activeSubscriptions.delete(symbol);
      //   return ;
      // },
      message => message.type === 'c_l2ob'
    )
    .pipe(
      share(), // Share the subscription
      catchError(err => {
        console.error('coming in Error in l2OrderbookChannel:', err);
        return EMPTY;
      })
    );
};

const subscribeL2OrderbookEpic = (action$, state$) => {
  return action$.pipe(
    ofType(SUBSCRIBE_L2_ORDERBOOK),
    mergeMap(action => {
      const symbol = action.payload;
      // Check if already subscribed
      // if (activeSubscriptions.has(symbol)) {
      //   return EMPTY;
      // }

      // // Mark as subscribed
      // activeSubscriptions.add(symbol);

      return l2OrderbookChannel(symbol).pipe(
        map(message => ({ message, symbol })),
        takeUntil(action$.ofType(UNSUBSCRIBE_L2_ORDERBOOK)),
        catchError(err => {
          console.error('subscribeL2OrderbookEpic', err);
          return EMPTY;
        })
      );
    }),
    map(({ message, symbol }: any) => {
      const data = decompress(message?.d);

      if (data.s === symbol) {
        return getComputedOrderbookFromSocket(data, state$);
      }

      return undefined;
    }),
    filter(Boolean),
    map(payload => ({ type: L2OrderbookActionTypes.SNAPSHOT, payload })),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );
};

const subscribeBasketOrderOrderbookEpic = action$ => {
  return action$.pipe(
    ofType(SUBSCRIBE_BASKET_ORDER_ORDERBOOK),
    mergeMap(action => {
      const symbol = action.payload;
      return l2OrderbookChannel(action.payload).pipe(
        map(message => ({ message, symbol })),
        takeUntil(action$.ofType(UNSUBSCRIBE_BASKET_ORDER_ORDERBOOK)),
        catchError(err => {
          logToSentry(err);
          return EMPTY;
        })
      );
    }),
    map(({ message, symbol }: any) => {
      const data = decompress(message?.d);
      if (data.s === symbol) {
        return updateBasketOrderOrderbook(data);
      }

      return undefined;
    }),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );
};

const subscribeEasyOptionsL2OrderbookEpic = action$ => {
  return action$.pipe(
    ofType(SUBSCRIBE_EASY_OPTIONS_ORDERBOOK),
    mergeMap(action => {
      const symbol = action.payload;
      return l2OrderbookChannel(action.payload).pipe(
        map(message => ({ message, symbol })),
        takeUntil(action$.ofType(UNSUBSCRIBE_EASY_OPTIONS_ORDERBOOK)),
        catchError(err => {
          logToSentry(err);
          return EMPTY;
        })
      );
    }),
    map(({ message, symbol }: any) => {
      const data = decompress(message?.d);

      if (!isEmpty(data.a)) return { message: data, symbol };

      return undefined;
    }),
    filter(Boolean),
    map(({ message, symbol }: any) => {
      if (message.s === symbol) return updateEasyOptionsOrderbook(message);

      return undefined;
    }),
    filter(Boolean),
    catchError(err => {
      logToSentry(err);
      return EMPTY;
    })
  );
};

const l2OrderbookEpic = combineEpics(
  subscribeL2OrderbookEpic,
  subscribeBasketOrderOrderbookEpic,
  subscribeEasyOptionsL2OrderbookEpic
);

export { l2OrderbookEpic };
